import { useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import toast from "react-hot-toast";
import { useQuery, useMutation } from "@apollo/client";
import { ENROLMENT_SWITCH_CLASSES } from "../../utils/queries";
import { UPDATE_ENROLMENT } from "../../utils/mutations";

export const ChangeEnrolmentForm = (props) => {
  const { id, refetch } = props;

  const [message, setMessage] = useState("");
  const [classes, setClasses] = useState([]);

  const { control, register, handleSubmit, reset } = useForm({
    mode: "onChange",
  });
  const { isValid } = useFormState({ control });

  useQuery(ENROLMENT_SWITCH_CLASSES, {
    variables: { enrolmentId: id },
    onCompleted: (data) => {
      if (data.classes?.length > 0) {
        setClasses(data.classes);
      }
    },
    onError: (error) => {
      console.error(error);
      setMessage("Unable to fetch applicable classes!");
      toast.error("Unable to fetch applicable classes!");
    },
  });

  const [updateEnrolmentClass, { updating }] = useMutation(UPDATE_ENROLMENT, {
    onCompleted: () => {
      setMessage("Enrolment updated successfully!");
      toast.success("Enrolment updated successfully!");
      reset();
      refetch();
    },
    onError: (error) => {
      setMessage("Unable to update enrolment!");
      toast.error("Unable to update enrolment!");
      console.error(error);
    },
  });

  const onSubmit = (data) => {
    const { change } = data;

    const input = {
      id,
      changeClass: change.enrolmentClass,
    };

    updateEnrolmentClass({ variables: { input } });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        {/* select class */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Class</span>
          </label>
          <select
            name="class"
            defaultValue="Select"
            className="select select-bordered w-full bg-white"
            {...register("change.enrolmentClass", {
              validate: (value) => value !== "Select",
              required: true,
            })}
            required
          >
            <option value="Select">Select</option>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.name} ({cls.day} {cls.from} - {cls.to})
              </option>
            ))}
          </select>
        </div>
        <div className="flex gap-2 items-center pt-4">
          {/* submit */}
          <div className="space-x-4">
            <button
              type="submit"
              disabled={!isValid}
              className={updating ? "btn loading" : "btn"}
            >
              Update Enrolment Class
            </button>
          </div>
          <label
            htmlFor="change-enrolment-modal"
            className="btn btn-ghost btn-outline"
            onClick={() => {
              setMessage("");
              reset();
            }}
          >
            Cancel
          </label>
        </div>
      </form>
      {/* cancel */}
      <div className="pt-2">
        {!!message && (
          <p className={`font-medium text-base mb-6 text-center mt-4`}>
            {message}
          </p>
        )}
      </div>
    </>
  );
};
