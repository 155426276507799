import { useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { STUDENTS_LIST } from "../utils/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { LoadingLayout } from "../layout/Loading";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import { UpdateStudentModal } from "../library/modal/UpdateStudent";
import { UpdateStudentForm } from "../library/form/UpdateStudent";
import { StudentDrawer } from "../library/drawer/Student";
import { ChatBubbleLeftEllipsisIcon as ChatAltIcon } from "@heroicons/react/20/solid";
import { formatMobile } from "../utils/helpers";
import { useDebounce } from "@uidotdev/usehooks";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export default function Students() {
  const { isAdmin, isManager } = useContext(AuthContext);

  return (
    <UserLayout title={`Students`}>
      {/* head section */}
      <SectionHead heading="Students List"></SectionHead>
      {/* content section */}
      <section>
        {/* table */}
        <Table isAdmin={isAdmin} isManager={isManager} />
      </section>
    </UserLayout>
  );
}

function Table(props) {
  const { isAdmin, isManager } = props;

  const [name, setName] = useState("");
  const [students, setStudents] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updateStudent, setUpdateStudent] = useState(false);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(STUDENTS_LIST, {
    onCompleted: (data) => {
      setStudents(data.list?.students);
      setTotal(data.list?.total || 0);
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to load users");
    },
    variables: {
      search: !!debouncedName ? debouncedName : null,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getStudentsToDownload, { data: download, loading: downloading }] =
    useLazyQuery(STUDENTS_LIST, {
      onCompleted: (res) => {
        if (res.list?.students.length > 0) {
          const formatted = res.list.students.map((value, i) => ({
            "No.": i + 1,
            Name: value.name,
            Age: value.age,
            DoB: value.dob,
            Gender: value.gender,
            Email: value.user?.email || "",
            Phone: value.user?.phone ? formatMobile(value.user.phone) : "",
            Street: value.user?.street || "",
            Suburb: value.user?.suburb || "",
            Postcode: value.user?.postcode || "",
            Notes: value.notes,
          }));

          setData(formatted);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Unable to get students list for download!");
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getStudentsToDownload({
      variables: {
        search: !!debouncedName ? debouncedName : null,
        page: 0,
        limit: total,
      },
    });
  };

  const handleOpen = (id) => {
    setOpenDrawer(true);
    setSelected(id);
  };
  const handleClose = () => {
    setOpenDrawer(false);
    setSelected(null);
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <>
      <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
        {/* table header */}
        <div className="flex w-full px-4 py-4 border-b-2">
          <div className="flex flex-row justify-between items-center w-full gap-4">
            {/* filters */}
            <div className="basis-1/2 flex flex-row gap-4">
              {/* name search */}
              <div className="w-full">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Name</span>
                </label>
                <input
                  type="text"
                  placeholder="Search by name"
                  className="input input-bordered w-full bg-white"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row gap-2">
              {(isAdmin || isManager) && (
                <>
                  {/* download csv */}
                  {download &&
                  download?.list?.students.length === data.length ? (
                    <CSVLink
                      className="btn btn-ghost"
                      data={data}
                      filename={`students.csv`}
                      onClick={(e) => {
                        setData([]);
                      }}
                    >
                      Download CSV
                    </CSVLink>
                  ) : (
                    <button
                      className={clsx(
                        "btn btn-ghost",
                        downloading && "loading"
                      )}
                      onClick={getDownloadData}
                    >
                      Generate CSV
                    </button>
                  )}
                </>
              )}
              {/* reset button */}
              <button
                className="btn btn-ghost"
                onClick={() => {
                  setName("");
                  setData([]);
                  refetch();
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        {/* table content */}
        {!loading ? (
          <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
            {/* head */}
            <thead className="text-left">
              <tr>
                <th></th>
                <th>Name</th>
                <th>DoB</th>
                <th>Notes</th>
                <th></th>
              </tr>
            </thead>
            {/* body */}
            {students?.length > 0 ? (
              <tbody className="divide-y-2">
                {students?.map((value, index) => (
                  <tr key={value.id}>
                    <th>{page * limit + index + 1}</th>
                    <td className="flex flex-row gap-4 items-center">
                      {value.picture ? (
                        <img
                          src={`${BUCKET_URL}/${value.picture}`}
                          className="w-14 h-14 rounded-full"
                          alt={`${value.name}`}
                        />
                      ) : (
                        <img
                          src={`${BUCKET_URL}/pictures/placeholder.jpg`}
                          className="w-14 h-14 rounded-full"
                          alt={`${value.name}`}
                        />
                      )}
                      <div>
                        <p className="font-bold">{value.name}</p>
                        <p>
                          {value.gender} - {value.age} yrs
                        </p>
                      </div>
                      {/* comments count badge */}
                      <div className="bg-gray-100 rounded-full px-2">
                        <p className="text-gray-600 text-sm flex items-center gap-1">
                          <ChatAltIcon className="h-4 w-4" />{" "}
                          <span className="font-semibold font-mono">
                            {value.commentsCount ? value.commentsCount : 0}
                          </span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <span className="text-base">{value.dob}</span>
                    </td>
                    <td>
                      <span className="line-clamp-2">{value.notes}</span>
                    </td>
                    <td className="tracking-wider font-mono">
                      <span></span>
                    </td>
                    <td>
                      <button
                        className="btn btn-xs btn-ghost"
                        onClick={() => handleOpen(value.id)}
                      >
                        View Profile
                      </button>
                      <label
                        htmlFor="student-modal-update"
                        className="btn btn-xs btn-ghost"
                        onClick={() => setUpdateStudent(value.id)}
                      >
                        Update Info
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <div className="flex flex-col items-center justify-center py-8">
                      <p className="text-gray-400 text-lg">No students found</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        ) : (
          <>
            {/* loading skeleton */}
            <LoadingLayout type="list" />
          </>
        )}
        {/* table footer */}
        <div className="flex w-full px-4 py-4 border-t-2">
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
                <span className="font-bold">
                  {page * limit + students?.length}
                </span>{" "}
                of <span className="font-bold">{total}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={page === 0 || maxPage <= 1}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={page === maxPage - 1 || maxPage <= 1}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      {openDrawer && <StudentDrawer close={handleClose} studentId={selected} />}
      <UpdateStudentModal
        title="Update student information"
        content="This information will be used for student enrolment to the class"
        btn="btn btn-sm btn-outline hidden"
        label={"Update"}
      >
        {updateStudent && <UpdateStudentForm student={updateStudent} />}
      </UpdateStudentModal>
    </>
  );
}
