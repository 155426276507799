import { convertUnixTimestamp } from "../../utils/helpers";

export const ScheduleList = (props) => {
  const { schedule } = props;

  return (
    <section className="mt-4">
      <div className="label">
        <span className="label-text font-semibold">Schedule</span>
      </div>
      <div className="bg-white rounded-xl p-4 divide-y">
        {schedule?.length > 0 ? (
          schedule.map((item, index) => (
            <div
              key={`schedule-${index}`}
              className="flex flex-col md:flex-row md:items-center justify-between gap-4 p-2"
            >
              <div className="flex items-center gap-4 w-full">
                <div className="flex flex-col md:flex-row gap-2 flex-grow justify-between">
                  <div>
                    <h3 className="text-lg font-medium">
                      Requested Slot {index + 1}
                    </h3>
                  </div>
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-gray-500">
                      {convertUnixTimestamp(item?.from)} -{" "}
                      {convertUnixTimestamp(item?.to)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Schedule not found</p>
        )}
      </div>
    </section>
  );
};
