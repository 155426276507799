import { useState } from "react";
import clsx from "clsx";
import toast from "react-hot-toast";
import { useQuery, useMutation } from "@apollo/client";
import { TRANSACTION_INFO } from "../../utils/queries";
import { REFUND_ENTITY } from "../../utils/mutations";
import {
  formatter,
  paymentTagColor,
  convertUnixTimestamp,
} from "../../utils/helpers";

const titleValues = {
  Enrolment: "Class Enrolment Transaction",
  Pass: "Class Pass Transaction",
  Order: "Products Order Transaction",
  Training: "Private Training Transaction",
  Performance: "Event Performance Transaction",
  Hire: "Hall Hire Transaction",
  Admission: "Workshop Admission Transaction",
};

export const TransactionSection = (props) => {
  const {
    id,
    depositRefundAllowed = false,
    paymentRefundAllowed = false,
  } = props;

  const [payment, setPayment] = useState(null);

  const { refetch } = useQuery(TRANSACTION_INFO, {
    variables: { entityId: id },
    onCompleted: (data) => {
      if (data.transaction) {
        const props = {};

        const type = data.transaction.type.toLowerCase();
        const name = data?.transaction[type].name ?? null;
        const number = data?.transaction[type].number
          ? `#${data.transaction?.number}`
          : null;

        props.id = data.transaction.id;
        props.title = titleValues[data.transaction.type] ?? "Transaction";
        props.description = name ?? number ?? "-";
        props.method = data.transaction.method;
        props.amount = data.transaction.amount;
        props.discount = data.transaction.discount;
        props.refunded = data.transaction.refunded;
        props.redeemed = data.transaction.redeemed;
        props.deposit = data.transaction.deposit;
        props.status = data.transaction.status;
        props.datetime = convertUnixTimestamp(data.transaction.created);

        setPayment(props);
      }
    },
    onError: (error) => {
      console.error("Error loading transaction", error);
      toast.error("Unable to load transaction details!");
    },
    fetchPolicy: "network-only",
  });

  const [refundEntity] = useMutation(REFUND_ENTITY, {
    onCompleted: () => {
      toast.success("Refund successful!");
      refetch({ entityId: id });
    },
    onError: (error) => {
      console.error("Refund failed!", error);
      toast.error("Unable to process refund!");
    },
  });

  const handleRefund = (category) => {
    if (category === "DEPOSIT" || category === "TOTAL") {
      window.confirm("Are you sure you want to refund this transaction?") &&
        refundEntity({
          variables: {
            transactionId: payment.id,
            category,
          },
        });
    }
  };

  return (
    <section>
      {payment && (
        <div className="mt-4">
          <div className="label">
            <span className="label-text font-semibold">Transaction</span>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between md:items-center px-6 py-4 gap-4 bg-white rounded-xl shadow-sm">
            <div className="">
              <h2 className="text-xl font-semibold">{payment.title ?? "-"}</h2>
              <p className="space-x-2">
                <span>
                  <strong>Amount:</strong>{" "}
                  {formatter.format(payment.amount / 100)}{" "}
                  {payment.amount > 0 && <small>incl. of GST</small>}
                </span>
              </p>
              {payment.discount > 0 && (
                <p className="space-x-2 space-y-2 text-xs">
                  <span>
                    Includes discount of{" "}
                    <strong>{formatter.format(payment.discount / 100)}</strong>
                  </span>
                </p>
              )}
              {payment.redeemed > 0 && (
                <p className="space-x-2 space-y-2 text-xs">
                  <span>
                    Voucher redeemed worth{" "}
                    <strong>{formatter.format(payment.redeemed / 100)}</strong>
                  </span>
                </p>
              )}

              {payment.deposit?.amount > 0 && (
                <p className="space-x-2 space-y-2 text-xs">
                  <span>
                    Includes deposit of{" "}
                    <strong>
                      {formatter.format(payment.deposit.amount / 100)}
                    </strong>
                  </span>
                </p>
              )}

              {payment.refunded > 0 && (
                <p className="space-x-2 space-y-2 text-sm">
                  <span className="text-red-700">
                    Amount refunded{" "}
                    <strong>{formatter.format(payment.refunded / 100)}</strong>
                  </span>
                  {!!payment.deposit.returned && (
                    <span className="text-green-700">(deposit returned)</span>
                  )}
                </p>
              )}
              <small className="text-gray-500">{payment.datetime}</small>
            </div>
            <div className="flex flex-row items-center gap-2">
              <div className="flex gap-2">
                <span
                  className={clsx(
                    "text-xs py-1 px-2 rounded-md text-white",
                    paymentTagColor(payment.method)
                  )}
                >
                  {payment.method}
                </span>
                {depositRefundAllowed &&
                  payment.status === "SUCCESSFUL" &&
                  payment.deposit.amount > 0 &&
                  payment.deposit.returned === false && (
                    <button
                      className={clsx("btn btn-xs btn-outline btn-warning")}
                      onClick={() => handleRefund("DEPOSIT")}
                    >
                      Refund Deposit
                    </button>
                  )}
                {paymentRefundAllowed &&
                  payment.status === "SUCCESSFUL" &&
                  payment.refunded < payment.amount && (
                    <button
                      className={clsx("btn btn-xs btn-outline btn-error")}
                      onClick={() => handleRefund("TOTAL")}
                    >
                      Cancel & Refund
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
