import { useContext, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import toast from "react-hot-toast";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import { AuthContext } from "../context/authContext";
import { LIST_VOUCHERS } from "../utils/queries";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import {
  Tomorrow,
  OneMonthAgo,
  formatter,
  statusTagColor,
  convertUnixTimestamp,
} from "../utils/helpers";
import { LoadingLayout } from "../layout/Loading";

export default function Vouchers() {
  const { isAdmin, isManager } = useContext(AuthContext);

  return (
    <UserLayout title={`Vouchers`}>
      {/* head section */}
      <SectionHead heading="Vouchers List"></SectionHead>
      {/* content section */}
      <section>
        {/* table */}
        <Table isAdmin={isAdmin} isManager={isManager} />
      </section>
    </UserLayout>
  );
}

function Table(props) {
  const { isAdmin } = props;

  const [type, setType] = useState(undefined);
  const [from, setFrom] = useState(OneMonthAgo);
  const [to, setTo] = useState(Tomorrow);
  const [vouchers, setVouchers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const getTitle = (type, value) => {
    switch (type) {
      case "Enrolment":
        return value.enrolment?.name || "-";
      case "Pass":
        return value.pass?.name || "-";
      case "Admission":
        return value.admission?.name || "-";
      default:
        return "-";
    }
  };

  const { loading, refetch } = useQuery(LIST_VOUCHERS, {
    onCompleted: (data) => {
      setVouchers(data.list.vouchers);
      setTotal(data.list.total ?? 0);
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to load vouchers");
    },
    variables: {
      type: !!type ? type : null,
      from: from,
      to: to,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 30000,
  });

  const [getVouchersToDownload, { loading: downloading, data: download }] =
    useLazyQuery(LIST_VOUCHERS, {
      onCompleted: (res) => {
        if (res.list?.vouchers?.length > 0) {
          const formatted = res.list?.vouchers?.map((value, i) => ({
            "No.": i + 1,
            Title: getTitle(value.type, value),
            Name: value.student.name || "-",
            Email: value.user.email || "-",
            Category: value.category,
            Code: value.code,
            Status: value.status,
            Ref: value.ref,
            Amount: formatter.format(value.amount / 100),
            Created: convertUnixTimestamp(value.created),
          }));

          setData(formatted);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to download timesheets");
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getVouchersToDownload({
      variables: {
        type: !!type ? type : null,
        from: from,
        to: to,
        page: 0,
        limit: total,
      },
    });
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <>
      <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
        {/* table header */}
        <div className="flex w-full px-4 py-4 border-b-2">
          <div className="flex flex-row justify-between items-center w-full gap-4">
            {/* filters */}
            <div className="basis-3/4 flex flex-row gap-4">
              {/* teacher */}
              <div className="w-1/3">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Type</span>
                </label>
                <select
                  htmlFor="type"
                  className="select select-bordered w-full bg-white"
                  onChange={(e) => {
                    setType(e.target.value);
                    refetch();
                  }}
                >
                  <option value="">All Types</option>
                  <option value="enrolment">Enrolment</option>
                  <option value="pass">Pass</option>
                  {/* <option value="admission">Admission</option> */}
                </select>
              </div>
              {/* date from */}
              <div className="w-1/3">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Date From</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered w-full bg-white"
                  onChange={(e) => {
                    setFrom(e.target.value);
                    refetch();
                  }}
                  value={from}
                  max={to}
                />
              </div>
              {/* date to */}
              <div className="w-1/3">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Date To</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered w-full bg-white"
                  onChange={(e) => {
                    setTo(e.target.value);
                    refetch();
                  }}
                  value={to}
                  min={from}
                />
              </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row gap-2">
              {isAdmin && (
                <>
                  {/* download csv */}
                  {download &&
                  download?.list?.vouchers?.length === data.length ? (
                    <CSVLink
                      className="btn btn-ghost"
                      data={data}
                      filename={`vouchers.csv`}
                      onClick={() => {
                        setData([]);
                      }}
                    >
                      Download CSV
                    </CSVLink>
                  ) : (
                    <button
                      className={clsx(
                        "btn btn-ghost",
                        downloading && "loading"
                      )}
                      onClick={getDownloadData}
                    >
                      Generate CSV
                    </button>
                  )}
                </>
              )}
              {/* reset button */}
              <button
                className="btn btn-ghost"
                onClick={() => {
                  setType(undefined);
                  setFrom(OneMonthAgo);
                  setTo(Tomorrow);
                  refetch();
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        {/* table content */}
        {!loading ? (
          <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
            {/* head */}
            <thead className="text-left">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Category</th>
                <th>Code</th>
                <th>Title</th>
                {isAdmin && <th>Amount</th>}
                <th>Status</th>
              </tr>
            </thead>
            {/* body */}
            {vouchers?.length > 0 ? (
              <tbody className="divide-y-2">
                {vouchers?.map((value, index) => (
                  <tr key={value.id}>
                    <th>{page * limit + index + 1}</th>
                    <td className="flex flex-row gap-4 items-center">
                      <div>
                        <p className="font-bold">{value.student.name}</p>
                        <p>{value?.user?.email}</p>
                        <small className="font-medium text-sm">
                          {convertUnixTimestamp(value?.created)}
                        </small>
                      </div>
                    </td>
                    <td>
                      <span
                        className={clsx(
                          "uppercase tracking-wider py-1 px-2 rounded-md text-xs font-bold"
                        )}
                      >
                        {value.category}
                      </span>
                    </td>
                    <td>
                      <span
                        className={clsx(
                          "tracking-widest py-1 px-2 rounded-md text-xm font-bold"
                        )}
                      >
                        {value.code}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium">
                        {value.enrolment?.name || "-"}
                      </span>
                    </td>
                    {isAdmin && (
                      <td className="tracking-wider font-mono">
                        <span>{formatter.format(value.amount / 100)}</span>
                      </td>
                    )}
                    <td>
                      <span
                        className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                          value.status
                        )}`}
                      >
                        {value.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <div className="flex flex-col items-center justify-center py-8">
                      <p className="text-gray-400 text-lg">No vouchers found</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        ) : (
          <>
            {/* loading skeleton */}
            <LoadingLayout type="list" />
          </>
        )}
        {/* table footer */}
        <div className="flex w-full px-4 py-4 border-t-2">
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
                <span className="font-bold">
                  {page * limit + vouchers?.length}
                </span>{" "}
                of <span className="font-bold">{total}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={page === 0 || maxPage <= 1}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={page === maxPage - 1 || maxPage <= 1}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
