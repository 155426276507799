import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from "../../utils/mutations";
import { USER_INFO, USERS_LIST } from "../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import clsx from "clsx";
import { Today } from "../../utils/helpers";

export const CustomerUserForm = (props) => {
  const { id } = props;

  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const [prefill] = useLazyQuery(USER_INFO, {
    variables: { userId: id },
    onCompleted: (data) => {
      if (data.user) {
        setValue("customer.email", data.user.email);
        setValue("customer.phone", data.user.phone);
        setValue("customer.street", data.user.street);
        setValue("customer.suburb", data.user.suburb);
        setValue("customer.postcode", data.user.postcode);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to fetch user details");
    },
    fetchPolicy: "network-only",
  });
  const [createCustomer, { loading: creating }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: (data) => {
      if (data.customer) {
        toast.success("Customer created successfully");
        navigate(-1);
      }
    },
    onError: (err) => {
      console.error(err);
      toast.error("Failed to create user");
    },
    refetchQueries: [
      {
        query: USERS_LIST,
        variables: { role: ["CUSTOMER"] },
        fetchPolicy: "network-only",
      },
    ],
  });
  const [updateCustomer, { loading: updating }] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (data) => {
      if (data.updated) {
        toast.success("Customer updated successfully");
        navigate(-1);
      }
    },
    onError: (err) => {
      console.error(err);
      toast.error("Failed to update user");
    },
    refetchQueries: [
      { query: USER_INFO, variables: { userId: id } },
      {
        query: USERS_LIST,
        variables: { role: ["CUSTOMER"] },
        fetchPolicy: "network-only",
      },
    ],
  });

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      dob,
      gender,
      email,
      phone,
      street,
      suburb,
      postcode,
    } = data.customer;
    if (id) {
      const input = {
        id,
        email,
        phone,
        street,
        suburb,
        postcode,
      };

      updateCustomer({ variables: { input } });
    } else {
      const input = {
        firstName,
        lastName,
        dob,
        gender,
        email,
        phone,
        street,
        suburb,
        postcode,
      };

      createCustomer({ variables: { input } });
    }
  };

  useEffect(() => {
    if (id) prefill();
  }, [id, prefill]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!id && (
          <>
            <div className="flex space-x-3 w-full max-w-md justify-between">
              {/* first name */}
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">First Name</span>
                </label>
                <input
                  type="text"
                  placeholder="First name"
                  className={clsx(
                    "input input-bordered w-full max-w-md bg-white",
                    errors?.customer?.firstName && "input-error"
                  )}
                  {...register("customer.firstName", {
                    required: "First name is required",
                  })}
                  required
                />
              </div>
              {/* last name */}
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">Last Name</span>
                </label>
                <input
                  type="text"
                  placeholder="Last name"
                  className={clsx(
                    "input input-bordered w-full max-w-md bg-white",
                    errors?.customer?.lastName && "input-error"
                  )}
                  {...register("customer.lastName", {
                    required: "Last name is required",
                  })}
                  required
                />
              </div>
            </div>

            <div>
              {/* dob */}
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">
                    Date of Birth
                  </span>
                </label>
                <input
                  type="date"
                  max={Today}
                  className={clsx(
                    "input input-bordered w-full max-w-md bg-white",
                    errors?.customer?.dob && "input-error"
                  )}
                  {...register("customer.dob", {
                    required: "Date of birth is required",
                  })}
                  required
                />
              </div>
            </div>
            <div>
              {/* gender */}
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">Gender</span>
                </label>
                <select
                  defaultValue="Select"
                  className={clsx(
                    "select select-bordered w-full max-w-md bg-white",
                    errors?.customer?.gender && "input-error"
                  )}
                  {...register("customer.gender", {
                    required: "Gender is required",
                  })}
                  required
                >
                  <option disabled>Select</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
              </div>
            </div>
          </>
        )}
        <div>
          {/* email */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Email</span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="User email"
              className={clsx(
                "input input-bordered w-full max-w-md bg-white",
                errors?.customer?.email && "input-error"
              )}
              {...register("customer.email", {
                required: "Email is required",
              })}
              required
            />
          </div>
        </div>
        <div>
          {/* phone */}
          <label className="label">
            <span className="label-text font-semibold">Phone</span>
          </label>
          <input
            type="text"
            placeholder="0400000000"
            className={clsx(
              "input input-bordered w-full max-w-md bg-white",
              errors?.customer?.phone && "input-error"
            )}
            {...register("customer.phone", {
              required: "Phone number is required",
              pattern: {
                // should be 10 digits and start with 04
                value: /^04\d{8}$/,
                message: "Invalid phone number",
              },
            })}
            required
          />
        </div>
        <div>
          {/* street */}
          <label className="label">
            <span className="label-text font-semibold">Street</span>
          </label>
          <input
            type="text"
            placeholder="Street address"
            className={clsx(
              "input input-bordered w-full max-w-md bg-white",
              errors?.customer?.street && "input-error"
            )}
            {...register("customer.street", {
              required: "Street address is required",
            })}
          />
        </div>
        <div className="flex space-x-3 w-full max-w-md justify-between">
          {/* suburb */}
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Suburb</span>
            </label>
            <input
              type="text"
              placeholder="Suburb"
              className={clsx(
                "input input-bordered w-full max-w-md bg-white",
                errors?.customer?.suburb && "input-error"
              )}
              {...register("customer.suburb", {
                required: "Suburb is required",
              })}
            />
          </div>
          {/* postcode */}
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Postcode</span>
            </label>
            <input
              type="text"
              placeholder="Postcode"
              className={clsx(
                "input input-bordered w-full max-w-md bg-white",
                errors?.customer?.postcode && "input-error"
              )}
              {...register("customer.postcode", {
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: "Invalid postcode",
                },
              })}
            />
          </div>
        </div>
        {/* submit */}
        <div className="pt-4">
          <button
            type="submit"
            className={clsx("btn", (creating || updating) && "loading")}
            disabled={!isValid || creating || updating}
          >
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
