import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import AuthService from "./utils/AuthService";
import { AuthProvider } from "./context/authContext";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";

// create http link
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_SERVER,
});
// set auth token if present
const authLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const options = {
  addTypename: true,
  typePolicies: {
    "Pass.classes": {
      merge(existing, incoming) {
        return incoming;
      },
    },
    "Enrolment.class": {
      merge(existing, incoming) {
        return incoming;
      },
    },
    EntityPayment: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    Session: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    Query: {
      analyticsThislast: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      applicableSessions: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
};
// create apollo client using auth and http link
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(options),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Toaster />
    <AuthProvider>
      <ApolloProvider client={client}>
        <Router>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Router>
      </ApolloProvider>
    </AuthProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
