import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery } from "@apollo/client";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { TeachersTable } from "../library/table/Teachers";
import { TeachersCalendar } from "../library/calendar/Teachers";
import { TeacherDetails } from "../library/details/Teacher";
import { WorksheetTable } from "../library/table/Worksheet";
import { TEACHER_INFO } from "../utils/queries";

export default function Teachers(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    if (param === "calendar") return <Calendar />;
    return <Details id={param} isAdmin={isAdmin} isManager={isManager} />;
  } else return <List />;
}

function List() {
  return (
    <UserLayout title="Teachers">
      <section>
        <SectionHead heading="Teachers">
          <Link to={`/teachers/calendar`} className="btn btn-outline">
            View Calendar
          </Link>
          <Link to={`/create/teacher`} className="btn">
            Add teacher
          </Link>
        </SectionHead>
      </section>
      <div className="pt-8 space-y-8">
        <TeachersTable />
      </div>
    </UserLayout>
  );
}

function Calendar() {
  return (
    <UserLayout title="Teachers">
      <section>
        <SectionHead heading="Teachers">
          <Link to={`/teachers`} className="btn btn-outline">
            View List
          </Link>
          <Link to={`/create/teacher`} className="btn">
            Add teacher
          </Link>
        </SectionHead>
      </section>
      <div className="pt-8 space-y-8">
        <TeachersCalendar />
      </div>
    </UserLayout>
  );
}

function Details(props) {
  const { id, isAdmin, isManager } = props;

  const navigate = useNavigate();

  const { data: info } = useQuery(TEACHER_INFO, {
    variables: { teacherId: id },
    onError: (err) => {
      console.error("Unable to fetch teacher details", err);
      toast.error("Unable to fetch teacher details");
    },
  });

  const handleUpdate = () => {
    navigate(`/edit/teacher/${id}`);
  };

  return (
    <UserLayout title="Teacher Details">
      <section>
        <SectionHead heading="Teacher Details">
          <button className="btn btn-outline" onClick={handleUpdate}>
            Update
          </button>
        </SectionHead>
      </section>
      {info?.teacher && <TeacherDetails details={info.teacher} />}
      {(isAdmin || isManager) && info?.teacher && (
        <WorksheetTable id={info?.teacher.id} />
      )}
    </UserLayout>
  );
}
