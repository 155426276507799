import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import Login from "./page/Login";
import Reset from "./page/Reset";
import Error from "./page/Error";
import Dashboard from "./page/Dashboard";
import Schedule from "./page/Schedule";
import Configuration from "./page/Configuration";
import Create from "./page/Create";
import Edit from "./page/Edit";
import Class from "./page/Class";
import Workshop from "./page/Workshop";
import Session from "./page/Session";
import Profile from "./page/Profile";
import Teachers from "./page/Teachers";
import Analytics from "./page/Analytics";
import Report from "./page/Report";
import Pass from "./page/Pass";
import Enrolment from "./page/Enrolment";
import Orders from "./page/Orders";
import Training from "./page/Trainings";
import Performance from "./page/Performances";
import Hire from "./page/Hires";
import Admission from "./page/Admission";
import Users from "./page/Users";
import Students from "./page/Students";
import Vouchers from "./page/Vouchers";
import Transactions from "./page/Transactions";
import Timesheets from "./page/Timesheets";

function App() {
  const { isAdmin, isManager } = useContext(AuthContext);

  return (
    <Routes>
      {/* open routes */}
      <Route path="*" element={<Error />} />
      {/* public routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <PublicRoute>
            <Reset />
          </PublicRoute>
        }
      />
      {/* protected routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/students"
        element={
          <ProtectedRoute>
            <Students />
          </ProtectedRoute>
        }
      />
      <Route
        path="/vouchers"
        element={
          <ProtectedRoute>
            <Vouchers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/transactions"
        element={
          <ProtectedRoute>
            <Transactions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/timesheets"
        element={
          <ProtectedRoute>
            <Timesheets />
          </ProtectedRoute>
        }
      />
      <Route
        path="/schedule/:param?"
        element={
          <ProtectedRoute>
            <Schedule />
          </ProtectedRoute>
        }
      />
      <Route
        path="/teachers/:param?"
        element={
          <ProtectedRoute>
            <Teachers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pass/:param?"
        element={
          <ProtectedRoute>
            <Pass />
          </ProtectedRoute>
        }
      />
      <Route
        path="/enrolment/:param?"
        element={
          <ProtectedRoute>
            <Enrolment />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admission/:param?"
        element={
          <ProtectedRoute>
            <Admission />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders/:param?"
        element={
          <ProtectedRoute>
            <Orders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/training/:param?"
        element={
          <ProtectedRoute>
            <Training />
          </ProtectedRoute>
        }
      />
      <Route
        path="/performance/:param?"
        element={
          <ProtectedRoute>
            <Performance />
          </ProtectedRoute>
        }
      />
      <Route
        path="/hire/:param?"
        element={
          <ProtectedRoute>
            <Hire />
          </ProtectedRoute>
        }
      />
      {(isAdmin || isManager) && (
        <Route
          path="/analytics"
          element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
        />
      )}
      {(isAdmin || isManager) && (
        <Route
          path="/report/:type"
          element={
            <ProtectedRoute>
              <Report />
            </ProtectedRoute>
          }
        />
      )}
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/config/:type?"
        element={
          <ProtectedRoute>
            <Configuration />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create/:type"
        element={
          <ProtectedRoute>
            <Create />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit/:type/:id"
        element={
          <ProtectedRoute>
            <Edit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/class/:id"
        element={
          <ProtectedRoute>
            <Class />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workshop/:id"
        element={
          <ProtectedRoute>
            <Workshop />
          </ProtectedRoute>
        }
      />
      <Route
        path="/session/:id"
        element={
          <ProtectedRoute>
            <Session />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;

function ProtectedRoute(props) {
  const { user } = useContext(AuthContext);
  // admin user but not customer
  return (
    <>{user && user.data.admin ? props.children : <Navigate to="/login" />}</>
  );
}

function PublicRoute(props) {
  const { user } = useContext(AuthContext);
  // not logged in
  return <>{!user ? props.children : <Navigate to="/" />}</>;
}
