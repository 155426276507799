import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_CLASS,
  UPDATE_WORKSHOP,
  UPDATE_SESSION,
} from "../../utils/mutations";
import {
  LIST_HALLS,
  SESSIONS_LIST,
  SESSION_DETAILS,
} from "../../utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export const AssignForm = (props) => {
  const { type, id, location } = props;

  const [isComplete, setIsComplete] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onTouched",
  });

  const { data: hallsData } = useQuery(LIST_HALLS, {
    variables: { location },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get halls!");
    },
  });

  const [updateClass, { loading: cl }] = useMutation(UPDATE_CLASS, {
    onCompleted: (data) => {
      if (data.class) {
        setIsComplete(true);
        reset();
        toast.success("Hall assigned successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to update class!");
    },
    refetchQueries: [{ query: SESSIONS_LIST, variables: { classId: id } }],
  });

  const [updateWorkshop, { loading: wl }] = useMutation(UPDATE_WORKSHOP, {
    onCompleted: (data) => {
      if (data.workshop) {
        setIsComplete(true);
        reset();
        toast.success("Hall assigned successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to update workshop!");
    },
    refetchQueries: [{ query: SESSIONS_LIST, variables: { workshopId: id } }],
  });

  const [updateSession, { loading: sl }] = useMutation(UPDATE_SESSION, {
    onCompleted: (data) => {
      if (data.updated) {
        setIsComplete(true);
        reset();
        toast.success("Hall assigned successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to update session!");
    },
    refetchQueries: [{ query: SESSION_DETAILS, variables: { sessionId: id } }],
  });

  const onSubmit = (data) => {
    const { assign } = data;

    switch (type) {
      case "class":
        updateClass({
          variables: {
            input: {
              id,
              hall: assign.hall,
            },
          },
        });
        break;
      case "workshop":
        updateWorkshop({
          variables: {
            input: {
              id,
              hall: assign.hall,
            },
          },
        });
        break;
      case "session":
        updateSession({
          variables: {
            input: {
              id,
              hallId: assign.hall,
            },
          },
        });
        break;
      default:
        reset();
        break;
    }
  };

  return (
    <>
      {!isComplete && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* for hall */}
          {hallsData?.halls && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">Hall</span>
              </label>
              <select
                defaultValue="Select"
                className="select select-bordered w-full max-w-md bg-white"
                {...register("assign.hall", { required: true })}
                required
              >
                <option disabled>Select</option>
                {hallsData?.halls.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {/* submit */}
          <div className="pt-4 space-x-2">
            <button
              type="submit"
              className={clsx("btn", (cl || wl || sl) && "loading")}
              disabled={!isValid}
            >
              Assign
            </button>
          </div>
        </form>
      )}
      {isComplete && (
        <>
          <p className="font-bold text-xl mb-6">Hall assigned successfully!</p>
          <label
            htmlFor="allocate-modal"
            className="btn btn-outline"
            onClick={() => {
              reset();
              setIsComplete(false);
            }}
          >
            Close
          </label>
        </>
      )}
    </>
  );
};
