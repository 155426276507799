import { useState } from "react";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_HALLS } from "../../utils/queries";
import { formatter } from "../../utils/helpers";

export const HallSelector = (props) => {
  const {
    label = "Select a hall",
    location,
    selection,
    setSelection,
    disable = false,
    showCapacity = true,
    showPricing = true,
  } = props;

  const [halls, setHalls] = useState([]);

  useQuery(LIST_HALLS, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (data) => {
      if (data.halls) {
        setHalls(data.halls);
      }
    },
    variables: {
      location: location ? location.id : null,
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className="border-b-2 pb-8">
      {halls && halls.length > 0 ? (
        <div className="w-full">
          <label className="label">
            <span className="label-text font-semibold">{label}</span>
          </label>
          <select
            className="select select-bordered w-full bg-white"
            value={selection ? selection.id : ""}
            onChange={(e) =>
              setSelection(halls.find((hall) => hall.id === e.target.value))
            }
            disabled={disable}
          >
            <option value="" disabled>
              Select a hall
            </option>
            {halls.map((hall) => (
              <option key={hall.id} value={hall.id}>
                {hall.name} ({showCapacity && `Capacity: ${hall.capacity}`})
                {showPricing && (
                  <> | Cost: ${formatter.format((hall.price * 2) / 100)} / hr</>
                )}
                {showPricing && (
                  <> | {`Bond: ${formatter.format(hall.deposit / 100)}`}</>
                )}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="w-full">
          <label className="label">
            <span className="label-text font-semibold">{label}</span>
          </label>
          <select className="select select-bordered w-full bg-white" disabled>
            <option value="">No halls available</option>
          </select>
        </div>
      )}
    </div>
  );
};
