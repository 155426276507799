import { useContext, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import toast from "react-hot-toast";
import clsx from "clsx";
import moment from "moment";
import { CSVLink } from "react-csv";
import { AuthContext } from "../context/authContext";
import { LIST_TIMESHEETS, LIST_TEACHERS } from "../utils/queries";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { TimesheetEntryModal } from "../library/modal/TimesheetEntry";
import { TimesheetForm } from "../library/form/Timesheet";
import {
  Tomorrow,
  OneMonthAgo,
  formatter,
  statusTagColor,
} from "../utils/helpers";
import { LoadingLayout } from "../layout/Loading";

export default function Timesheets() {
  const { isAdmin, isManager } = useContext(AuthContext);

  return (
    <UserLayout title={`Timesheets`}>
      {/* head section */}
      <SectionHead heading="Timesheets List">
        <TimesheetEntryModal
          for="timesheet-create-modal"
          title="Create Timesheet Entry"
          label="Create Entry"
          content="This will create a new timesheet entry"
          btn="btn btn-outline"
        >
          <TimesheetForm />
        </TimesheetEntryModal>
      </SectionHead>
      {/* content section */}
      <section>
        {/* table */}
        <Table isAdmin={isAdmin} isManager={isManager} />
      </section>
    </UserLayout>
  );
}

function Table(props) {
  const { isAdmin, isManager } = props;

  const [teacher, setTeacher] = useState(null);
  const [from, setFrom] = useState(OneMonthAgo);
  const [to, setTo] = useState(Tomorrow);
  const [timesheets, setTimesheets] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);
  const [updateEntry, setUpdateEntry] = useState(null);

  const { data: list } = useQuery(LIST_TEACHERS, {
    onError: (error) => {
      console.error(error);
      toast.error("Failed to load teachers");
    },
  });

  const { loading, refetch } = useQuery(LIST_TIMESHEETS, {
    onCompleted: (data) => {
      setTimesheets(data.list.timesheets);
      setTotal(data.list.total ?? 0);
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to load timesheets");
    },
    variables: {
      teacherId: teacher ? teacher : undefined,
      from: from,
      to: to,
    },
    fetchPolicy: "network-only",
    pollInterval: 30000,
  });

  const [getTimesheetsToDownload, { loading: downloading, data: download }] =
    useLazyQuery(LIST_TIMESHEETS, {
      onCompleted: (data) => {
        if (data.list?.timesheets.length > 0) {
          const formatted = data.list.timesheets.map((value, i) => ({
            "No.": i + 1,
            Name: value.teacher?.name ?? "-",
            Start: getDateTime(value.start),
            End: value.end ? getDateTime(value.end) : "-",
            Duration: value.end ? getDuration(value.start, value.end) : "-",
            Total: value.end
              ? getTotal(value.teacher.payRate, value.duration)
              : "-",
            Status: value.end ? "COMPLETE" : "ONGOING",
          }));

          setData(formatted);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to download timesheets");
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getTimesheetsToDownload({
      variables: {
        teacherId: teacher ? teacher : undefined,
        from: from,
        to: to,
        page: 0,
        limit: total,
      },
    });
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  const getDateTime = (datetime) => {
    // need to format the datetime to a readable format
    return moment(datetime).format("DD-MM-YYYY hh:mm A");
  };

  const getDuration = (start, end) => {
    // get the difference between the start and end time in hours and minutes
    const duration = moment.duration(moment(end).diff(moment(start)));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  };

  const getTotal = (rate, duration) => {
    // rate in cents per hour and duration in minutes
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const total = (hours + minutes / 60) * rate;

    // return the total in dollars
    return formatter.format(total / 100);
  };

  const handleUpdate = (id) => {
    setUpdateEntry(id);
  };

  return (
    <>
      <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
        {/* table header */}
        <div className="flex w-full px-4 py-4 border-b-2">
          <div className="flex flex-row justify-between items-center w-full gap-4">
            {/* filters */}
            <div className="basis-3/4 flex flex-row gap-4">
              {/* teacher */}
              <div className="w-1/3">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Teacher</span>
                </label>
                <select
                  htmlFor="teacher"
                  className="select select-bordered w-full bg-white"
                  onChange={(e) => {
                    setTeacher(e.target.value);
                    refetch();
                  }}
                >
                  <option value="">All Teachers</option>
                  {list?.teachers.map((teacher) => (
                    <option key={teacher.id} value={teacher.id}>
                      {teacher.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* date from */}
              <div className="w-1/3">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Date From</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered w-full bg-white"
                  onChange={(e) => {
                    setFrom(e.target.value);
                    refetch();
                  }}
                  value={from}
                  max={to}
                />
              </div>
              {/* date to */}
              <div className="w-1/3">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Date To</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered w-full bg-white"
                  onChange={(e) => {
                    setTo(e.target.value);
                    refetch();
                  }}
                  value={to}
                  min={from}
                />
              </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row gap-2">
              {(isAdmin || isManager) && (
                <>
                  {/* download csv */}
                  {download &&
                  download?.list?.timesheets.length === data.length ? (
                    <CSVLink
                      className="btn btn-ghost"
                      data={data}
                      filename={`timesheets.csv`}
                      onClick={() => {
                        setData([]);
                      }}
                    >
                      Download CSV
                    </CSVLink>
                  ) : (
                    <button
                      className={clsx(
                        "btn btn-ghost",
                        downloading && "loading"
                      )}
                      onClick={getDownloadData}
                    >
                      Generate CSV
                    </button>
                  )}
                </>
              )}
              {/* reset button */}
              <button
                className="btn btn-ghost"
                onClick={() => {
                  setTeacher(null);
                  setFrom(OneMonthAgo);
                  setTo(Tomorrow);
                  setData([]);
                  refetch();
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        {/* table content */}
        {!loading ? (
          <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
            {/* head */}
            <thead className="text-left">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Start</th>
                <th>End</th>
                <th>Duration</th>
                {isAdmin && <th>Total</th>}
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            {/* body */}
            {timesheets?.length > 0 ? (
              <tbody className="divide-y-2">
                {timesheets?.map((value, index) => (
                  <tr key={value.id}>
                    <th>{page * limit + index + 1}</th>
                    <td className="flex flex-row gap-4 items-center">
                      <div>
                        <p className="font-bold">{value.teacher?.name}</p>
                      </div>
                    </td>
                    <td>
                      <span className="font-medium capitalize tracking-wider">
                        {getDateTime(value.start) ?? "-"}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium capitalize tracking-wider">
                        {value.end ? getDateTime(value.end) : "-"}
                      </span>
                    </td>
                    {isAdmin && (
                      <td>
                        <span className="font-bold capitalize tracking-wide font-mono whitespace-nowrap">
                          {value.end
                            ? getDuration(value.start, value.end)
                            : "-"}
                        </span>
                      </td>
                    )}
                    <td className="tracking-wider font-mono">
                      <span>
                        {value.end
                          ? getTotal(value.teacher.payRate, value.duration)
                          : "-"}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                          value.end ? "COMPLETE" : "ONGOING"
                        )}`}
                      >
                        {value.end ? "COMPLETE" : "ONGOING"}
                      </span>
                    </td>
                    <td>
                      <label
                        htmlFor="timesheet-update-modal"
                        className={clsx(
                          "btn btn-xs btn-ghost",
                          !(isAdmin || isManager) && "hidden"
                        )}
                        onClick={() => handleUpdate(value.id)}
                      >
                        Update
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <div className="flex flex-col items-center justify-center py-8">
                      <p className="text-gray-400 text-lg">
                        No timesheets found
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        ) : (
          <>
            {/* loading skeleton */}
            <LoadingLayout type="list" />
          </>
        )}
        {/* table footer */}
        <div className="flex w-full px-4 py-4 border-t-2">
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
                <span className="font-bold">
                  {page * limit + timesheets?.length}
                </span>{" "}
                of <span className="font-bold">{total}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={page === 0 || maxPage <= 1}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={page === maxPage - 1 || maxPage <= 1}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <TimesheetEntryModal
        for="timesheet-update-modal"
        title="Update Timesheet Entry"
        label="Update Entry"
        content="This will update the existing timesheet entry"
        btn="btn btn-ghost hidden"
      >
        {updateEntry ? (
          <TimesheetForm id={updateEntry} clear={() => setUpdateEntry(null)} />
        ) : (
          <div className="flex flex-col">
            <label htmlFor="timesheet-update-modal" className="btn">
              Close
            </label>
          </div>
        )}
      </TimesheetEntryModal>
    </>
  );
}
