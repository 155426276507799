export const SectionHead = (props) => {
  return (
    <div className="flex border-b-2 py-2 flex-col gap-4 sm:flex-row sm:justify-between items-center w-full">
      <h1 className="text-4xl font-semibold w-full sm:w-auto">
        {props.heading}
      </h1>
      <div className="flex gap-2 flex-col sm:flex-row w-full sm:w-auto md:justify-end">
        {props.children}
      </div>
    </div>
  );
};
