import { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { GET_HALL_AVAILABILITY_SLOTS } from "../../utils/queries";
import { toAmPm } from "../../utils/helpers";

const durations = {
  training: {
    30: "30 mins.",
    60: "1 hr.",
    90: "1 hr. 30 mins.",
    120: "2 hrs.",
    150: "2 hrs. 30 mins.",
    180: "3 hrs.",
  },
  hire: {
    60: "1 hr.",
    120: "2 hrs.",
    180: "3 hrs.",
    240: "4 hrs.",
    300: "5 hrs.",
    360: "6 hrs.",
    480: "8 hrs.",
    540: "9 hrs.",
    600: "10 hrs.",
    660: "11 hrs.",
    720: "12 hrs.",
  },
};

export const ScheduleSelector = (props) => {
  const { hall, setSelection, module = "hire" } = props;

  const {
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "slots",
  });

  const duration = useWatch({ name: "schedule.duration", control });
  const slots = useWatch({ name: "slots", control });

  const validateDate = (date, index) => {
    if (index > 0 && new Date(date) <= new Date(slots[index - 1].date)) {
      return "Date must be greater than the previous date";
    }
    for (let i = 0; i < slots.length; i++) {
      if (i !== index && slots[i].date === date) {
        return "Dates must be unique";
      }
    }
    return true;
  };

  const twoMonthsFromNow = new Date(
    new Date().setMonth(new Date().getMonth() + 2)
  )
    .toISOString()
    .split("T")[0];
  const addOneDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate.toISOString().split("T")[0];
  };
  const subtractOneDay = (date) => {
    if (!date) return twoMonthsFromNow;
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    return newDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (slots && slots.length > 0) {
      // filter out empty slots
      const filteredSlots = slots.filter(
        (slot) => slot.date && slot.time.from && slot.time.to
      );
      const schedule = filteredSlots.map((slot) => ({
        from: slot.time.from,
        to: slot.time.to,
      }));
      setSelection(schedule);
    } else setSelection([]);
  }, [slots, setSelection]);

  return (
    <div className="border-b-2 pb-8">
      {/* duration */}
      <div>
        <label className="label">
          <span className="label-text font-semibold">Select Duration</span>
        </label>
        <select
          className="select select-bordered w-full bg-white"
          {...register("schedule.duration", {
            required: true,
            valueAsNumber: true,
          })}
        >
          <option value="">Select duration</option>
          {Object.entries(durations[module]).map(([key, value]) => (
            <option key={key} value={parseInt(key)}>
              {value}
            </option>
          ))}
        </select>
      </div>
      {/* slots */}
      {!!duration &&
        fields?.length > 0 &&
        fields.map((field, index) => {
          const minDate = index > 0 ? addOneDay(slots[index - 1]?.date) : null;
          const maxDate =
            index < fields.length - 1
              ? subtractOneDay(slots[index + 1]?.date)
              : twoMonthsFromNow;

          const currentSlot = slots ? slots[index] : { date: "", time: "" };
          const isCurrentSlotComplete = currentSlot?.date && currentSlot?.time;

          return (
            <div
              key={field.id}
              className="flex flex-col md:flex-row gap-2 pt-2"
            >
              <div className="md:grow flex flex-col sm:flex-row gap-2">
                {/* date */}
                <div className="sm:basis-1/2">
                  <input
                    type="date"
                    className={`input input-bordered w-full bg-white ${
                      errors?.slots?.[index]?.date ? "input-error" : ""
                    }`}
                    min={minDate}
                    max={maxDate}
                    {...register(`slots.${index}.date`, {
                      required: true,
                      validate: (value) => validateDate(value, index),
                    })}
                  />
                </div>
                {/* time */}
                <div className="sm:basis-1/2">
                  <TimeSlot
                    key={field.id}
                    className={`select select-bordered w-full bg-white ${
                      errors?.slots?.[index]?.time ? "select-error" : ""
                    }`}
                    {...{
                      control,
                      index,
                      field,
                      className: "select select-bordered w-full bg-white",
                      hall,
                      duration,
                      disabled: false,
                    }}
                  />
                </div>
              </div>
              {/* action */}
              <div className="md:basis-1/5 flex gap-2 items-center">
                <button
                  type="button"
                  className="btn btn-sm btn-ghost"
                  onClick={() => remove(index)}
                >
                  Remove
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-ghost"
                  onClick={() => append({ date: "", time: "" })}
                  disabled={!isCurrentSlotComplete}
                >
                  Add
                </button>
              </div>
            </div>
          );
        })}
      {!!duration && (
        <div className="w-full flex justify-center pt-2">
          {fields?.length === 0 ? (
            <button
              type="button"
              className="btn btn-sm btn-outline"
              onClick={() => append({ date: "", time: "" })}
            >
              Add Slot
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-ghost"
              onClick={() => remove()}
            >
              Clear Slots
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const TimeSlot = ({ control, index, className, hall, duration, disabled }) => {
  const value = useWatch({ name: "slots", control });

  const [selectedDate, setSelectedDate] = useState(null);

  const [getOptions, { data, loading }] = useLazyQuery(
    GET_HALL_AVAILABILITY_SLOTS,
    {
      onError: (error) => {
        console.error("Error", error);
        toast.error("Error fetching time slots");
      },
    }
  );

  useEffect(() => {
    if (value?.[index]?.date) setSelectedDate(value[index].date);
    else setSelectedDate(null);
  }, [value, index]);

  useEffect(() => {
    if (!!selectedDate) {
      getOptions({
        variables: {
          hallId: hall.id,
          date: selectedDate,
          duration: duration,
        },
      });
    }
  }, [selectedDate, index, hall, duration, getOptions]);

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <select
            className={className}
            disabled={disabled || !selectedDate || loading}
            rules={{ required: true }}
            value={JSON.stringify(value)}
            onChange={(e) => onChange(JSON.parse(e.target.value))}
          >
            <option
              value={{
                from: null,
                to: null,
              }}
            >
              Select a time slot
            </option>
            {data?.slots.map((slot) => (
              <option
                key={slot.id}
                value={JSON.stringify({ from: slot.from, to: slot.to })}
              >
                {toAmPm(slot.start)} - {toAmPm(slot.end)}
              </option>
            ))}
          </select>
        );
      }}
      name={`slots.${index}.time`}
      control={control}
    />
  );
};
