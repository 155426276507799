import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_ROSTER } from "../../utils/queries";
import { statusTagColor, formatDateTimeRange } from "../../utils/helpers";

export const RostersList = (props) => {
  const { enrolmentId, admissionId, passId } = props;

  const { data: rosterData } = useQuery(LIST_ROSTER, {
    variables: { enrolmentId, admissionId, passId },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get roster list!");
    },
    fetchPolicy: "network-only",
  });

  if (rosterData?.roster?.length === 0) {
    return (
      <div className="text-center text-gray-500 text-lg font-bold mt-4">
        No roster entries found
      </div>
    );
  }

  return (
    <section className="mt-4">
      <div className="label">
        <span className="label-text font-semibold">Rosters</span>
      </div>
      <div className="bg-white rounded-xl p-4 divide-y">
        {rosterData?.roster?.length > 0 ? (
          rosterData?.roster.map((item) => (
            <div
              key={item.id}
              className="flex flex-col md:flex-row md:items-center justify-between gap-4 p-2"
            >
              <div className="flex items-center gap-4 w-full">
                <div className="flex flex-col md:flex-row gap-2 flex-grow justify-between">
                  <div>
                    {!passId ? (
                      <h3 className="text-lg font-medium">
                        {item.session?.name ?? "-"}
                      </h3>
                    ) : (
                      <h3 className="text-lg font-medium">
                        {item.session?.class?.name ??
                          item.session?.workshop?.name ??
                          "-"}{" "}
                        - {item.session?.name ?? "-"}
                      </h3>
                    )}
                    <p className="text-sm text-gray-500">
                      {formatDateTimeRange(
                        item.session?.start,
                        item.session?.end
                      )}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    {item.session?.status && (
                      <p
                        className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                          item.session.status
                        )}`}
                      >
                        {item.session.status}
                      </p>
                    )}
                    <p className="text-sm text-gray-900 font-medium">
                      {item.attended ? "Attended" : "Not Attended"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Rosters not found</p>
        )}
      </div>
    </section>
  );
};
