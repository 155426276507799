import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Link, useParams } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { ClassDetails } from "../library/details/Class";
import { SessionsTable } from "../library/table/Sessions";
import { EnrolmentsTable } from "../library/table/Enrolments";
import { Tab } from "@headlessui/react";
import { MARK_FULL, MARK_AVAILABLE } from "../utils/mutations";
import { CLASS_INFO } from "../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { AssignModal } from "../library/modal/Assign";
import { AllocateModal } from "../library/modal/Allocate";
import { AllocateForm } from "../library/form/Allocate";
import { NotificationModal } from "../library/modal/Notification";
import { NotificationForm } from "../library/form/Notification";
import { UpdateModal } from "../library/modal/Update";
import { ClassScheduleForm } from "../library/form/ClassSchedule";
import { AssignForm } from "../library/form/Assign";
import { CSVLink } from "react-csv";
import { Now } from "../utils/helpers";
import { CommentsList } from "../library/list/Comments";

function Class(props) {
  const { id } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  const [isFull, setIsFull] = useState(false);
  const [isAllocated, setIsAllocated] = useState(false);
  const [location, setLocation] = useState(null);
  const [formatted, setFormatted] = useState([]);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [enrolmentCount, setEnrolmentCount] = useState(0);

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [markAsFull, { data }] = useMutation(MARK_FULL, {
    onCompleted: () => notifySuccess(),
    onError: () => notifyError(),
    refetchQueries: [{ query: CLASS_INFO, variables: { classId: id } }],
  });

  const [markAsAvailable, { data: data2 }] = useMutation(MARK_AVAILABLE, {
    onError: () => notifyError(),
    refetchQueries: [{ query: CLASS_INFO, variables: { classId: id } }],
  });

  const action = (status) => {
    switch (status) {
      case "FULL":
        if (
          window.confirm(
            `Are you sure you want to stop taking enrolments for this class?`
          )
        ) {
          markAsFull({ variables: { classId: id } });
        }
        break;
      case "AVAILABLE":
        if (
          window.confirm(
            `Are you sure you want to make this class available for enrolments?`
          )
        ) {
          markAsAvailable({ variables: { classId: id } });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (data || data2) notifySuccess();
  }, [data, data2]);

  useEffect(() => {
    if (title && title !== "") {
      setSlug(title.toLowerCase().replace(/\s/g, "_"));
    }
  }, [title]);

  return (
    <UserLayout title="Class Info">
      <section>
        <SectionHead heading="Class Info">
          {!isFull && (
            <button className="btn btn-outline" onClick={() => action("FULL")}>
              Mark as Full
            </button>
          )}
          {isFull && (
            <button
              className="btn btn-outline"
              onClick={() => action("AVAILABLE")}
            >
              Mark as Available
            </button>
          )}
          {!isFull && enrolmentCount === 0 && (
            <UpdateModal
              title="Update class schedule"
              content="Update class day and time for all sessions of this class, this operation will overwrite any existing session schedule for all of its sessions"
              label="Update Schedule"
              btn="btn btn-outline"
            >
              <ClassScheduleForm type="class" id={id} title={title} />
            </UpdateModal>
          )}
          {location && (
            <AssignModal
              title="Set class hall"
              content="Set class hall for all sessions of this class, this operation will overwrite any existing session hall set for all of its sessions"
              label="Set Hall"
              btn="btn btn-outline"
            >
              <AssignForm type="class" id={id} location={location} />
            </AssignModal>
          )}
          {!isAllocated && (
            <AllocateModal
              title="Select team"
              content="Assign teacher to all sessions for this class sessions, this operation will overwrite any existing session teacher and assistant for all of its sessions"
              label="Select Team"
              btn="btn btn-outline"
            >
              <AllocateForm type="class" id={id} />
            </AllocateModal>
          )}
          <Link to={`/edit/class/${id}`} className="btn">
            Edit
          </Link>
        </SectionHead>
        {/* <ClassInfo /> */}
        <div className="pt-8 space-y-8">
          <Tab.Group>
            <div className="flex flex-col lg:flex-row justify-between gap-6">
              <Tab.List className="order-2 lg:order-1 flex space-x-1 rounded-xl w-full max-w-md bg-base-300 p-1">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                    )
                  }
                >
                  Details
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                    )
                  }
                >
                  Sessions
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                    )
                  }
                >
                  Enrolments
                </Tab>
              </Tab.List>
              <div className="order-1 lg:order-2 flex flex-col md:flex-row gap-4">
                {(isAdmin || isManager) && formatted.length > 0 && (
                  <CSVLink
                    data={formatted}
                    target="_blank"
                    filename={`${slug}-students-${Now}.csv`}
                    className="btn btn-ghost"
                  >
                    Export Enrolments
                  </CSVLink>
                )}
                {enrolmentCount > 0 && (
                  <NotificationModal
                    title="Send a Class Notification"
                    content="Send a notification to all students in this class (includes all students who are enrolled as of date)"
                    btn="btn btn-outline"
                    label="Send Notification"
                  >
                    <NotificationForm type="class" id={id} />
                  </NotificationModal>
                )}
              </div>
            </div>
            <Tab.Panels>
              <Tab.Panel>
                <ClassDetails
                  classId={id}
                  option={setIsFull}
                  allocation={setIsAllocated}
                  setTitle={setTitle}
                  setCount={setEnrolmentCount}
                  setLocation={setLocation}
                />
              </Tab.Panel>
              <Tab.Panel>
                <SessionsTable classId={id} />
              </Tab.Panel>
              <Tab.Panel>
                <EnrolmentsTable
                  classId={id}
                  csv={setFormatted}
                  showDollarValues={isAdmin}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
      <section>
        <div className="px-2 py-6">
          <h3 className="font-medium mb-4 label-text">Comments</h3>
          <CommentsList type="Class" id={id} commenting={true} />
        </div>
      </section>
    </UserLayout>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default Class;
