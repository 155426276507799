import { UPDATE_ROSTER } from "../../utils/mutations";
import { LIST_ROSTER, SESSION_DETAILS } from "../../utils/queries";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const RosterTable = (props) => {
  const { sessionId } = props;

  const { data: rosterData, refetch } = useQuery(LIST_ROSTER, {
    variables: { sessionId },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get roster list!");
    },
  });

  const [updateRosterEntry] = useMutation(UPDATE_ROSTER, {
    onCompleted: () => {
      toast.success("Updated successfully!");
      refetch();
    },
    onError: () => {
      toast.error("Unable to update roster entry!");
    },
    refetchQueries: [{ query: SESSION_DETAILS, variables: { sessionId } }],
  });

  const action = (id, bool) => {
    updateRosterEntry({ variables: { input: { id, attended: bool } } });
  };

  if (rosterData?.roster.length === 0) {
    return (
      <div className="text-center text-gray-500 text-lg font-bold">
        No students in the roster
      </div>
    );
  }

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Student</th>
              <th>Attended</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {rosterData?.roster &&
              rosterData?.roster.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">{value.student?.name}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${
                        value.attended ? `bg-success` : `bg-error`
                      }`}
                    >
                      {value.attended ? "YES" : "NO"}
                    </span>
                  </td>
                  <td className="space-x-4">
                    <button
                      className={`btn btn-xs ${
                        value.attended ? "btn-disabled" : "btn-ghost"
                      }`}
                      onClick={() => action(value.id, true)}
                    >
                      Present
                    </button>
                    <button
                      className={`btn btn-xs ${
                        !value.attended ? "btn-disabled" : "btn-ghost"
                      }`}
                      onClick={() => action(value.id, false)}
                    >
                      Absent
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
