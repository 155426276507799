export const TimesheetEntryModal = (props) => {
  return (
    <>
      <label
        htmlFor={props.for}
        className={props.btn ? props.btn : "btn modal-button"}
      >
        {props.label}
      </label>

      <input
        type="checkbox"
        id={props.for}
        className="modal-toggle"
        checked={props.checked}
        onToggle={props.onToggle}
      />
      <label className="modal modal-bottom sm:modal-middle" htmlFor={props.for}>
        <label className="modal-box relative" htmlFor={props.for}>
          <h3 className="font-bold text-lg">{props.title}</h3>
          <p className="py-4">{props.content}</p>
          <div>{props.children}</div>
        </label>
      </label>
    </>
  );
};
