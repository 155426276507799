import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { ClassForm } from "../library/form/Class";
import { TermForm } from "../library/form/Term";
import { ProgramForm } from "../library/form/Program";
import { GroupForm } from "../library/form/Group";
import { LocationForm } from "../library/form/Location";
import { TeacherForm } from "../library/form/Teacher";
import { TemplateForm } from "../library/form/Template";
import { AdminUserForm } from "../library/form/AdminUser";
import { HallForm } from "../library/form/Hall";
import { ProductForm } from "../library/form/Product";
import { WorkshopForm } from "../library/form/Workshop";
import { CustomerUserForm } from "../library/form/CustomerUser";
import {
  REMOVE_TERM,
  REMOVE_CLASS,
  REMOVE_USER,
  REMOVE_TEACHER,
  REMOVE_TEMPLATE,
  REMOVE_HALL,
  REMOVE_PRODUCT,
  REMOVE_WORKSHOP,
  RESET_USER_PASSWORD,
} from "../utils/mutations";
import {
  LIST_TERMS,
  LIST_ADMIN_USERS,
  LIST_TEACHERS,
  LIST_TEMPLATES,
  LIST_HALLS,
  LIST_PRODUCTS,
  LIST_WORKSHOPS,
} from "../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import clsx from "clsx";

function Edit() {
  const { type, id } = useParams();

  const { isAdmin } = useContext(AuthContext);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [canDelete, setCanDelete] = useState(true);

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Removed successfully!");

  const [removeTerm, { loading: termLoading }] = useMutation(REMOVE_TERM, {
    variables: { termId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/config/terms`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refetchQueries: [{ query: LIST_TERMS }],
  });

  const [removeClass, { loading: classLoading }] = useMutation(REMOVE_CLASS, {
    variables: { classId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/schedule/`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
  });

  const [removeAdminUser, { loading: userLoading }] = useMutation(REMOVE_USER, {
    variables: { userId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/config/users`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refectQueries: [{ query: LIST_ADMIN_USERS }],
  });

  const [removeTeacher, { loading: teacherLoading }] = useMutation(
    REMOVE_TEACHER,
    {
      variables: { teacherId: id },
      onCompleted: () => {
        setLoader(false);
        notifySuccess();
        navigate(`/teachers`);
      },
      onError: () => {
        setLoader(false);
        notifyError();
      },
      refectQueries: [{ query: LIST_TEACHERS }],
    }
  );

  const [removeTemplate, { loading: templateLoading }] = useMutation(
    REMOVE_TEMPLATE,
    {
      variables: { templateId: id },
      onCompleted: () => {
        setLoader(false);
        notifySuccess();
        navigate(`/config/templates`);
      },
      onError: () => {
        setLoader(false);
        notifyError();
      },
      refectQueries: [{ query: LIST_TEMPLATES }],
    }
  );

  const [removeHall, { loading: hallLoading }] = useMutation(REMOVE_HALL, {
    variables: { hallId: id },
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/config/halls`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refectQueries: [{ query: LIST_HALLS }],
  });

  const [removeProduct, { loading: productLoading }] = useMutation(
    REMOVE_PRODUCT,
    {
      variables: { productId: id },
      onCompleted: () => {
        setLoader(false);
        notifySuccess();
        navigate(`/config/products`);
      },
      onError: () => {
        setLoader(false);
        notifyError();
      },
      refectQueries: [{ query: LIST_PRODUCTS }],
    }
  );

  const [removeWorkshop, { loading: workshopLoading }] = useMutation(
    REMOVE_WORKSHOP,
    {
      variables: { workshopId: id },
      onCompleted: () => {
        setLoader(false);
        notifySuccess();
        navigate(`/schedule/workshops`);
      },
      onError: () => {
        setLoader(false);
        notifyError();
      },
      refectQueries: [{ query: LIST_WORKSHOPS }],
    }
  );

  const [resetPassword, { loading: reseting }] = useMutation(
    RESET_USER_PASSWORD,
    {
      onCompleted: (data) => {
        if (data.reset) toast.success("Password reset successfully");
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to reset password");
      },
    }
  );

  const basedOnType = (param) => {
    switch (param) {
      case "class":
        return <ClassForm id={id} allowDelete={setCanDelete} />;
      case "term":
        return <TermForm id={id} allowDelete={setCanDelete} />;
      case "program":
        return <ProgramForm id={id} allowDelete={setCanDelete} />;
      case "group":
        return <GroupForm id={id} allowDelete={setCanDelete} />;
      case "location":
        return <LocationForm id={id} allowDelete={setCanDelete} />;
      case "teacher":
        return <TeacherForm id={id} allowDelete={setCanDelete} />;
      case "template":
        return <TemplateForm id={id} allowDelete={setCanDelete} />;
      case "admin":
        if (!isAdmin) return <Navigate to="/" />;
        return <AdminUserForm id={id} allowDelete={setCanDelete} />;
      case "hall":
        return <HallForm id={id} allowDelete={setCanDelete} />;
      case "product":
        return <ProductForm id={id} allowDelete={setCanDelete} />;
      case "workshop":
        return <WorkshopForm id={id} allowDelete={setCanDelete} />;
      case "customer":
        return <CustomerUserForm id={id} allowDelete={setCanDelete} />;
      default:
        return <Navigate to={-1} />;
    }
  };

  const invokeDelete = () => {
    if (
      window.confirm(`Are you sure you want to delete the this information?`)
    ) {
      switch (type) {
        case "term":
          removeTerm();
          break;
        case "class":
          removeClass();
          break;
        case "admin":
          removeAdminUser();
          break;
        case "teacher":
          removeTeacher();
          break;
        case "template":
          removeTemplate();
          break;
        case "hall":
          removeHall();
          break;
        case "product":
          removeProduct();
          break;
        case "workshop":
          removeWorkshop();
          break;
        default:
          window.alert("Not a valid type!");
      }
    }
  };

  const invokeReset = (id) => {
    if (
      window.confirm(
        `Are you sure you want to reset the password for this ${type}?`
      )
    ) {
      resetPassword({ variables: { input: { id } } });
    }
  };

  useEffect(() => {
    if (
      termLoading ||
      classLoading ||
      userLoading ||
      teacherLoading ||
      templateLoading ||
      hallLoading ||
      productLoading ||
      workshopLoading
    )
      setLoader(true);
  }, [
    termLoading,
    classLoading,
    userLoading,
    teacherLoading,
    templateLoading,
    hallLoading,
    productLoading,
    workshopLoading,
  ]);

  useEffect(() => {
    if (
      type === "program" ||
      type === "group" ||
      type === "location" ||
      type === "customer"
    ) {
      setCanDelete(false);
    }
  }, [type, canDelete]);

  return (
    <UserLayout title={`Edit ${type}`}>
      <section>
        <SectionHead heading={`Edit ${type}`}>
          {(type === "customer" || type === "admin") && (
            <button
              className={clsx(
                "btn btn-outline btn-secondary",
                reseting && "loading"
              )}
              onClick={() => invokeReset(id)}
              disabled={reseting}
            >
              Reset Password
            </button>
          )}
          {canDelete && (
            <button
              className={
                loader
                  ? "btn btn-outline btn-error loading"
                  : "btn btn-outline btn-error"
              }
              onClick={() => invokeDelete()}
            >
              Delete
            </button>
          )}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
        {basedOnType(type)}
      </section>
    </UserLayout>
  );
}

export default Edit;
