import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { LoadingLayout } from "../layout/Loading";
import {
  ADMIN_SCHEDULE_FILTER,
  CLASSES_LIST,
  WORKSHOPS_LIST,
} from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useDebounce } from "@uidotdev/usehooks";
import {
  toAmPm,
  toDDMMYYYY,
  formatter,
  statusTagColor,
} from "../utils/helpers";
import { ChatBubbleLeftEllipsisIcon as ChatAltIcon } from "@heroicons/react/20/solid";

function Schedule(props) {
  const { param } = useParams();

  const [name, setName] = useState("");

  const debouncedName = useDebounce(name, 1000);

  const navigate = useNavigate();

  const modules = [
    {
      key: "classes-0",
      slug: "classes",
      name: "Classes",
      new: "/create/class",
      component: ShowClasses,
    },
    {
      key: "workshops-1",
      slug: "workshops",
      name: "Workshops",
      new: "/create/workshop",
      component: ShowWorkshops,
    },
  ];

  const getActiveTab = () => {
    const index = modules.findIndex((module) => module.slug === param);
    const val = index > -1 ? index : 0;

    return val;
  };

  const handleChange = (i) => {
    navigate(`/schedule/${modules[i].slug}`);
  };

  return (
    <UserLayout title="Schedule">
      <section>
        <SectionHead heading="Schedule">
          <input
            type="search"
            placeholder="Search"
            className="input w-full input-bordered bg-white"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Link to={modules[getActiveTab()].new} className="btn">
            Create New
          </Link>
        </SectionHead>
        <div className="mt-4">
          <Tab.Group selectedIndex={getActiveTab()} onChange={handleChange}>
            <Tab.List className="flex space-x-1 rounded-xl bg-base-300 p-1">
              {modules.map((module) => (
                <Tab
                  key={`${module.key}-Tab`}
                  className={({ selected }) =>
                    clsx(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                    )
                  }
                >
                  {module.name}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              {modules.map((module) => (
                <Tab.Panel key={`${module.key}-Panel`}>
                  <module.component
                    debouncedName={debouncedName}
                    setName={setName}
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
    </UserLayout>
  );
}

function ShowClasses(props) {
  const { debouncedName, setName } = props;

  const [classes, setClasses] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(20);
  const [maxPage, setMaxPage] = useState(0);
  const [term, setTerm] = useState("");
  const [program, setProgram] = useState("");
  const [group, setGroup] = useState("");
  const [location, setLocation] = useState("");

  const navigate = useNavigate();

  const { loading: filterLoading, data: filterData } = useQuery(
    ADMIN_SCHEDULE_FILTER,
    {
      onError: (error) => {
        console.error(error);
        toast.error("Error fetching filters");
      },
    }
  );

  const { loading, refetch } = useQuery(CLASSES_LIST, {
    onCompleted: (data) => {
      if (data.list) {
        setClasses(data.list.classes);
        setTotal(data.list.total);
        setMaxPage(Math.ceil(data.list.total / limit));
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error fetching classes");
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      termsId: !!term ? [term] : null,
      programsId: !!program ? [program] : null,
      groupsId: !!group ? [group] : null,
      locationsId: !!location ? [location] : null,
      status: ["AVAILABLE", "ONGOING", "COMPLETE", "FULL"],
      page,
      limit,
    },
    fetchPolicy: "network-only",
  });

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  useEffect(() => {
    if (filterLoading || loading) toast.loading("Loading...");
    else toast.dismiss();
  }, [filterLoading, loading]);

  return (
    <div>
      <div className="flex flex-col gap-4 py-2">
        {/* table */}
        <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
          {/* table header */}
          <div className="flex w-full px-4 py-4 border-b-2">
            <div className="flex flex-row justify-between items-center w-full gap-4">
              {/* filters */}
              {filterData?.filters && (
                <div className="w-full flex flex-row gap-2">
                  {/* term filter */}
                  <div className="">
                    <label className="label sr-only">Term</label>
                    <select
                      className="select select-bordered bg-white"
                      onChange={(e) => {
                        setTerm(e.target.value);
                        refetch();
                      }}
                      value={term}
                    >
                      <option value="">Select term</option>
                      {filterData.filters.terms.map((term) => (
                        <option key={term.id} value={term.id}>
                          {term.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* program filter */}
                  <div className="">
                    <label className="label sr-only">Program</label>
                    <select
                      className="select select-bordered bg-white"
                      onChange={(e) => {
                        setProgram(e.target.value);
                        refetch();
                      }}
                      value={program}
                    >
                      <option value="">Select program</option>
                      {filterData.filters.programs.map((program) => (
                        <option key={program.id} value={program.id}>
                          {program.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* group filter */}
                  <div className="">
                    <label className="label sr-only">Group</label>
                    <select
                      className="select select-bordered bg-white"
                      onChange={(e) => {
                        setGroup(e.target.value);
                        refetch();
                      }}
                      value={group}
                    >
                      <option value="">Select group</option>
                      {filterData.filters.groups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* location filter */}
                  <div className="">
                    <label className="label sr-only">Location</label>
                    <select
                      className="select select-bordered bg-white"
                      onChange={(e) => {
                        setLocation(e.target.value);
                        refetch();
                      }}
                      value={location}
                    >
                      <option value="">Select location</option>
                      {filterData.filters.locations.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {/* button */}
              <div className="flex flex-row gap-2">
                {/* reset button */}
                <button
                  className="btn btn-ghost"
                  onClick={() => {
                    setName("");
                    setTerm("");
                    setProgram("");
                    setGroup("");
                    setLocation("");
                    refetch();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {/* table content */}
          {!loading ? (
            <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
              {/* head */}
              <thead className="text-left">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Sessions</th>
                  <th>Students</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {/* body */}
              {classes.length > 0 ? (
                <tbody className="divide-y-2">
                  {classes.map((value, index) => {
                    return (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex gap-2 items-center">
                          <div className="flex flex-col">
                            <p className="font-bold">{value.name ?? "-"}</p>
                            <p className="text-gray-600 tracking-wider text-sm">
                              <span>{value.day ?? "?"}</span> |{" "}
                              <span>
                                {value.from ? toAmPm(value.from) : "?"}
                              </span>{" "}
                              - <span>{value.to ? toAmPm(value.to) : "?"}</span>
                            </p>
                            {value.term?.name && (
                              <small className="text-xs font-semibold">
                                {value.term.name}
                              </small>
                            )}
                          </div>
                          {/* comments count badge */}
                          <div className="bg-gray-50 rounded-full px-2">
                            <p className="text-gray-600 text-sm flex items-center gap-1">
                              <ChatAltIcon className="h-4 w-4" />{" "}
                              <span className="font-semibold font-mono">
                                {value.commentsCount ? value.commentsCount : 0}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>
                          {value.price ? (
                            <span className="font-mono font-medium">
                              {formatter.format(value.price / 100)}
                              <small className="tracking-tighter text-xs">
                                /sess.
                              </small>
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td className="text-center">
                          <span className="font-bold">
                            {value.sessionsAvailable ?? 0}
                          </span>{" "}
                          <span>/ {value.sessionsTotal ?? 0}</span>
                        </td>
                        <td className="text-center">
                          <span className="font-bold">
                            {value.enrolmentCount ?? 0}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/class/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <div className="flex flex-col items-center justify-center py-8">
                        <p className="text-gray-400 text-lg">
                          No classes found
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <>
              {/* loading skeleton */}
              <LoadingLayout type="list" />
            </>
          )}

          {/* table footer */}
          <div className="flex w-full px-4 py-4 border-t-2">
            <div className="flex flex-row justify-between items-center w-full">
              <div>
                <p>
                  Showing <span className="font-bold">{page * limit + 1}</span>{" "}
                  -{" "}
                  <span className="font-bold">
                    {page * limit + classes.length}
                  </span>{" "}
                  of <span className="font-bold">{total}</span> results
                </p>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline btn-sm"
                  disabled={page === 0 || maxPage <= 1}
                  onClick={() => prevPage()}
                >
                  Prev
                </button>
                <button
                  className="btn btn-outline btn-sm"
                  disabled={page === maxPage - 1 || maxPage <= 1}
                  onClick={() => nextPage()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <small className="block text-center italic text-gray-500">
        <strong>Note:</strong> if no term is selected, only classes from{" "}
        <strong>upcoming</strong> or <strong>current</strong> term will be
        listed (if present).
      </small>
    </div>
  );
}

function ShowWorkshops(props) {
  const { debouncedName, setName } = props;

  const [workshops, setWorkshops] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(20);
  const [maxPage, setMaxPage] = useState(0);
  const [program, setProgram] = useState("");
  const [group, setGroup] = useState("");
  const [location, setLocation] = useState("");

  const navigate = useNavigate();

  const { loading: filterLoading, data: filterData } = useQuery(
    ADMIN_SCHEDULE_FILTER,
    {
      onError: (error) => {
        console.error(error);
        toast.error("Error fetching filters");
      },
    }
  );

  const { loading, refetch } = useQuery(WORKSHOPS_LIST, {
    onCompleted: (data) => {
      if (data.list) {
        setWorkshops(data.list.workshops);
        setTotal(data.list.total);
        setMaxPage(Math.ceil(data.list.total / limit));
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error fetching classes");
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      groupIds: !!group ? [group] : null,
      programId: !!program ? program : null,
      locationId: !!location ? location : null,
      status: [
        "PENDING",
        "AVAILABLE",
        "ONGOING",
        "COMPLETE",
        "FULL",
        "CANCELLED",
      ],
      page,
      limit,
    },
    fetchPolicy: "network-only",
  });

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  useEffect(() => {
    if (filterLoading || loading) toast.loading("Loading...");
    else toast.dismiss();
  }, [filterLoading, loading]);

  return (
    <div>
      <div className="flex flex-col gap-4 py-2">
        {/* table */}
        <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
          {/* table header */}
          <div className="flex w-full px-4 py-4 border-b-2">
            <div className="flex flex-row justify-between items-center w-full gap-4">
              {/* filters */}
              {filterData?.filters && (
                <div className="w-full flex flex-row gap-2">
                  {/* group filter */}
                  <div className="">
                    <label className="label sr-only">Group</label>
                    <select
                      className="select select-bordered bg-white"
                      onChange={(e) => {
                        setGroup(e.target.value);
                        refetch();
                      }}
                      value={group}
                    >
                      <option value="">Select group</option>
                      {filterData.filters.groups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* program filter */}
                  <div className="">
                    <label className="label sr-only">Program</label>
                    <select
                      className="select select-bordered bg-white"
                      onChange={(e) => {
                        setProgram(e.target.value);
                        refetch();
                      }}
                      value={program}
                    >
                      <option value="">Select program</option>
                      {filterData.filters.programs.map((program) => (
                        <option key={program.id} value={program.id}>
                          {program.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* location filter */}
                  <div className="">
                    <label className="label sr-only">Location</label>
                    <select
                      className="select select-bordered bg-white"
                      onChange={(e) => {
                        setLocation(e.target.value);
                        refetch();
                      }}
                      value={location}
                    >
                      <option value="">Select location</option>
                      {filterData.filters.locations.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {/* button */}
              <div className="flex flex-row gap-2">
                {/* reset button */}
                <button
                  className="btn btn-ghost"
                  onClick={() => {
                    setName("");
                    setProgram("");
                    setGroup("");
                    setLocation("");
                    refetch();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {/* table content */}
          {!loading ? (
            <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
              {/* head */}
              <thead className="text-left">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Sessions</th>
                  <th>Students</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {/* body */}
              {workshops.length > 0 ? (
                <tbody className="divide-y-2">
                  {workshops.map((value, index) => {
                    return (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex gap-2 items-center">
                          <div className="flex flex-col">
                            <p className="font-bold">{value.name ?? "-"}</p>
                            <p className="text-gray-600 tracking-wider text-sm">
                              <span>
                                {value.from ? toDDMMYYYY(value.from) : "?"}{" "}
                              </span>
                              {value.to && value.to !== value.from && (
                                <span>- {toDDMMYYYY(value.to)}</span>
                              )}
                            </p>
                            {value.location?.name && (
                              <small className="text-xs font-semibold">
                                {value.location.name}
                              </small>
                            )}
                          </div>
                          {/* comments count badge */}
                          <div className="bg-gray-50 rounded-full px-2">
                            <p className="text-gray-600 text-sm flex items-center gap-1">
                              <ChatAltIcon className="h-4 w-4" />{" "}
                              <span className="font-semibold font-mono">
                                {value.commentsCount ? value.commentsCount : 0}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>
                          {value.price ? (
                            <span className="font-mono font-medium">
                              {formatter.format(value.price / 100)}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td className="text-center">
                          <span className="font-bold">
                            {value.sessionsAvailable ?? 0}
                          </span>{" "}
                          <span>/ {value.sessionsTotal ?? 0}</span>
                        </td>
                        <td className="text-center">
                          <span className="font-bold">
                            {value.admissionCount ?? 0}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/workshop/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <div className="flex flex-col items-center justify-center py-8">
                        <p className="text-gray-400 text-lg">
                          No classes found
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <>
              {/* loading skeleton */}
              <LoadingLayout type="list" />
            </>
          )}

          {/* table footer */}
          <div className="flex w-full px-4 py-4 border-t-2">
            <div className="flex flex-row justify-between items-center w-full">
              <div>
                <p>
                  Showing <span className="font-bold">{page * limit + 1}</span>{" "}
                  -{" "}
                  <span className="font-bold">
                    {page * limit + workshops.length}
                  </span>{" "}
                  of <span className="font-bold">{total}</span> results
                </p>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline btn-sm"
                  disabled={page === 0 || maxPage <= 1}
                  onClick={() => prevPage()}
                >
                  Prev
                </button>
                <button
                  className="btn btn-outline btn-sm"
                  disabled={page === maxPage - 1 || maxPage <= 1}
                  onClick={() => nextPage()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
