import { statusTagColor, formatDateTimeRange } from "../../utils/helpers";

export const SessionsList = (props) => {
  const { sessions } = props;

  return (
    <section className="mt-4">
      <div className="label">
        <span className="label-text font-semibold">Sessions</span>
      </div>
      <div className="bg-white rounded-xl p-4 divide-y">
        {sessions?.length > 0 ? (
          sessions.map((item) => (
            <div
              key={item.id}
              className="flex flex-col md:flex-row md:items-center justify-between gap-4 p-2"
            >
              <div className="flex items-center gap-4 w-full">
                <div className="flex flex-col md:flex-row gap-2 flex-grow justify-between">
                  <div>
                    <h3 className="text-lg font-medium">{item.name ?? "-"}</h3>
                    <p className="text-sm text-gray-500">
                      {formatDateTimeRange(item.start, item.end)}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <p
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        item.status
                      )}`}
                    >
                      {item.status}
                    </p>
                    {item.conflict && (
                      <p className="text-sm text-gray-900 font-medium">!!!</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Sessions not found</p>
        )}
      </div>
    </section>
  );
};
