import { useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  WORKSHOP_INFO,
  SESSIONS_LIST,
  ADMISSIONS_LIST,
  WORKSHOPS_LIST,
} from "../utils/queries";
import { REMOVE_SESSION, UPDATE_WORKSHOP } from "../utils/mutations";
import toast from "react-hot-toast";
import clsx from "clsx";
import { Tab } from "@headlessui/react";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { CommentsList } from "../library/list/Comments";
import { formatter, toDDMMYYYY, statusTagColor } from "../utils/helpers";
import { LoadingLayout } from "../layout/Loading";
import {
  ChatBubbleLeftEllipsisIcon as ChatAltIcon,
  ExclamationTriangleIcon as ExclamationIcon,
} from "@heroicons/react/20/solid";
import { AllocateModal } from "../library/modal/Allocate";
import { AddSessionModal } from "../library/modal/AddSession";
import { AssignModal } from "../library/modal/Assign";
import { AllocateForm } from "../library/form/Allocate";
import { AssignForm } from "../library/form/Assign";
import { SessionForm } from "../library/form/Session";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export default function Workshop(props) {
  const { id } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  const [hasStarted, setHasStarted] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [hasAdmissions, setHasAdmissions] = useState(false);
  const [hasSessions, setHasSessions] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [canCancel, setCanCancel] = useState(false);
  const [location, setLocation] = useState(null);

  const modules = [
    {
      key: "details-section",
      name: "Details",
      component: WorkshopDetails,
    },
    {
      key: "sessions-section",
      name: "Sessions",
      component: WorkshopSessions,
    },
    {
      key: "admissions-section",
      name: "Admissions",
      component: WorkshopAdmissions,
    },
  ];

  const [updateWorkshop] = useMutation(UPDATE_WORKSHOP, {
    onCompleted: (data) => {
      toast.success("Workshop updated successfully");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to update workshop");
    },
    refetchQueries: [
      { query: WORKSHOP_INFO, variables: { workshopId: id } },
      { query: WORKSHOPS_LIST },
    ],
  });

  const action = (str) => {
    switch (str) {
      case "FULL":
        if (
          window.confirm("Are you sure you want to mark this workshop as FULL?")
        ) {
          updateWorkshop({
            variables: { input: { id, status: "FULL" } },
          });
        }
        break;
      case "AVAILABLE":
        if (
          window.confirm(
            "Are you sure you want to mark this workshop as AVAILABLE?"
          )
        ) {
          updateWorkshop({
            variables: { input: { id, status: "AVAILABLE" } },
          });
        }
        break;
      case "CANCELLED":
        if (window.confirm("Are you sure you want to cancel this workshop?")) {
          updateWorkshop({
            variables: { input: { id, status: "CANCELLED" } },
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <UserLayout title="Workshop Info">
      <div className="flex flex-col gap-8">
        <section>
          <SectionHead heading={`Workshop Info`}>
            {hasAdmissions && !isFull && !isPending && (
              <button
                className="btn btn-outline"
                onClick={() => action("FULL")}
              >
                Mark as Full
              </button>
            )}
            {(isFull || isPending) && hasSessions && (
              <button
                className="btn btn-outline"
                onClick={() => action("AVAILABLE")}
              >
                Mark as Available
              </button>
            )}
            {canCancel && (
              <button
                className="btn btn-outline btn-error"
                onClick={() => action("CANCELLED")}
              >
                Cancel
              </button>
            )}
            {location && (
              <AssignModal
                title="Select hall"
                content="Assign a hall to all sessions for this workshop sessions, this operation will overwrite any existing selection for all of its sessions"
                label="Select Hall"
                btn="btn btn-outline"
              >
                <AssignForm type="workshop" id={id} location={location} />
              </AssignModal>
            )}
            <AllocateModal
              title="Select team"
              content="Assign teacher to all sessions for this workshop sessions, this operation will overwrite any existing session teacher and assistant for all of its sessions"
              label="Select Team"
              btn="btn btn-outline"
            >
              <AllocateForm type="workshop" id={id} />
            </AllocateModal>
            <Link to={`/edit/workshop/${id}`} className="btn">
              Edit
            </Link>
          </SectionHead>
        </section>
        <section>
          {/* workshop info */}
          <div className="space-y-8">
            <Tab.Group>
              <Tab.List className="order-2 lg:order-1 flex space-x-1 rounded-xl w-full max-w-md bg-base-300 p-1">
                {modules.map((module) => (
                  <Tab
                    key={`${module.key}-Tab`}
                    className={({ selected }) =>
                      clsx(
                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral focus:outline-none focus:ring-2",
                        selected
                          ? "bg-white shadow"
                          : "text-neutral hover:bg-white/[0.12] hover:text-neutral-focus"
                      )
                    }
                  >
                    {module.name}
                  </Tab>
                ))}
              </Tab.List>

              <Tab.Panels>
                {modules.map((module) => (
                  <Tab.Panel key={`${module.key}-Panel`}>
                    <module.component
                      id={id}
                      isAdmin={isAdmin}
                      isManager={isManager}
                      hasStarted={hasStarted}
                      setStarted={setHasStarted}
                      hasEnded={hasEnded}
                      setEnded={setHasEnded}
                      hasSessions={hasSessions}
                      setSessions={setHasSessions}
                      hasAdmissions={hasAdmissions}
                      setAdmissions={setHasAdmissions}
                      isPending={isPending}
                      setPending={setIsPending}
                      isFull={isFull}
                      setFull={setIsFull}
                      canCancel={canCancel}
                      setCancel={setCanCancel}
                      setLocation={setLocation}
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </section>
        <section>
          <div className="px-2">
            <h3 className="font-medium mb-4 label-text">Comments</h3>
            <CommentsList type="Workshop" id={id} commenting={true} />
          </div>
        </section>
      </div>
    </UserLayout>
  );
}

function WorkshopDetails(props) {
  const {
    id,
    setStarted,
    setEnded,
    setSessions,
    setCancel,
    setPending,
    setFull,
    setLocation,
  } = props;

  const { data, loading } = useQuery(WORKSHOP_INFO, {
    onCompleted: (data) => {
      // set hasStarted based on current date vs workshop from
      if (new Date(data?.workshop?.from) < new Date()) {
        setStarted(true);
      }
      // set hasEnded based on current date vs workshop to
      if (data?.workshop?.to && new Date(data?.workshop?.to) < new Date()) {
        setEnded(true);
      }
      // set hasSessions based on current session count
      if (data?.workshop?.sessionsTotal > 0) {
        setSessions(true);
      }
      // set isPending based on status
      if (data?.workshop?.status === "PENDING") {
        setPending(true);
      } else {
        setPending(false);
      }
      // set isFull based on current roster count vs workshop capacity
      if (data?.workshop?.status === "FULL") {
        setFull(true);
      } else {
        setFull(false);
      }
      // set hasAdmissions based on current admissions count
      if (
        data?.workshop?.admissionCount === 0 &&
        data?.workshop?.status !== "CANCELLED"
      ) {
        setCancel(true);
      } else setCancel(false);
      // set location
      setLocation(data?.workshop?.location?.id);
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get workshop information");
    },
    variables: { workshopId: id },
    fetchPolicy: "network-only",
  });

  if (loading) return <LoadingLayout />;

  if (!data?.workshop) return <p>Workshop not found</p>;

  return (
    <section className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-6 px-8 bg-white rounded-lg shadow-md">
      <div>
        <dt className="p-1 pb-0">
          <span className="label-text font-semibold">Name</span>
        </dt>
        <dd className="p-1">
          <span className="mt-1 text-md">{data?.workshop?.name ?? "-"}</span>
        </dd>
      </div>
      {data?.workshop?.price && (
        <div>
          <dt className="p-1 pb-0">
            <span className="label-text font-semibold">Price</span>
          </dt>
          <dd className="p-1">
            <span className="mt-1 text-md">
              <span className="font-mono">
                {formatter.format(data.workshop.price / 100)}
              </span>{" "}
              per session
            </span>
          </dd>
        </div>
      )}
      {data?.workshop?.description && (
        <div className="md:col-span-2">
          <dt className="p-1 pb-0">
            <span className="label-text font-semibold">Description</span>
          </dt>
          <dd className="p-1">
            <span className="mt-1 text-md">{data?.workshop?.description}</span>
          </dd>
        </div>
      )}
      {data?.workshop?.from && (
        <div>
          <dt className="p-1 pb-0">
            <span className="label-text font-semibold">Dates</span>
          </dt>
          <dd className="p-1">
            <span className="mt-1 text-md">
              <span>
                {data?.workshop?.from ? toDDMMYYYY(data?.workshop?.from) : "?"}{" "}
              </span>
              {data?.workshop?.to &&
                data?.workshop?.to !== data?.workshop?.from && (
                  <span>- {toDDMMYYYY(data?.workshop?.to)}</span>
                )}
            </span>
          </dd>
        </div>
      )}
      {data?.workshop?.groups && data?.workshop?.groups.length > 0 && (
        <div>
          <dt className="p-1 pb-0">
            <span className="label-text font-semibold">Groups</span>
          </dt>
          <dd className="p-1">
            <span className="mt-1 text-md">
              {data?.workshop?.groups.map((group) => group.name).join(", ")}
            </span>
          </dd>
        </div>
      )}
      {data?.workshop?.location && (
        <div>
          <dt className="p-1 pb-0">
            <span className="label-text font-semibold">Location</span>
          </dt>
          <dd className="p-1">
            <span className="mt-1 text-md">
              {data?.workshop?.location?.name ?? "?"}
            </span>
          </dd>
        </div>
      )}
      {data?.workshop?.hall && (
        <div>
          <dt className="p-1 pb-0">
            <span className="label-text font-semibold">Hall</span>
          </dt>
          <dd className="p-1">
            <span className="mt-1 text-md">
              {data?.workshop?.hall?.name ?? "?"}
            </span>
          </dd>
        </div>
      )}
      <div>
        <dt className="p-1 pb-0">
          <span className="label-text font-semibold">Status</span>
        </dt>
        <dd className="p-1">
          <span
            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
              data.workshop.status
            )}`}
          >
            {data.workshop.status}
          </span>
        </dd>
      </div>
      <div>
        <dt className="p-1 pb-0">
          <span className="label-text font-semibold">Delayed entry?</span>
        </dt>
        <dd className="p-1">
          <span className="mt-1 text-md">
            {data?.workshop?.delayedEntry ? "Allowed" : "Not allowed"}
          </span>
        </dd>
      </div>
    </section>
  );
}

function WorkshopSessions(props) {
  const { id: workshopId, hasStarted, hasEnded, hasAdmissions } = props;

  const { loading, data, refetch } = useQuery(SESSIONS_LIST, {
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get workshop sessions");
    },
    variables: { workshopId },
  });

  const [deleteSession] = useMutation(REMOVE_SESSION, {
    onCompleted: (data) => {
      if (data.removed) {
        toast.success("Session deleted!");
        refetch();
      }
    },
    onError: () => {
      toast.error("Unable to delete session");
    },
  });

  const handleRemove = (id) => {
    if (window.confirm(`Are you sure you want to delete this session?`)) {
      deleteSession({ variables: { sessionId: id } });
    }
  };

  if (loading) return <LoadingLayout type="list" />;

  if (data?.list?.total === 0)
    return (
      <section className="flex flex-col gap-4 justify-center items-center">
        <div>
          <p>No sessions found</p>
        </div>
        {/* action */}
        {!hasEnded && (
          <AddSessionModal
            title="Add Session"
            content="Add a new session to this workshop"
            btn="btn btn-dark"
            label="Add Session"
          >
            <SessionForm
              payload={{ type: "workshop", id: workshopId }}
              refetch={refetch}
            />
          </AddSessionModal>
        )}
      </section>
    );

  return (
    <section>
      {/* list */}
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Date</th>
              <th>Hall</th>
              <th>Teacher</th>
              <th className="text-center">Students</th>
              <th>Status</th>
            </tr>
          </thead>
          {/* body */}
          <tbody className="divide-y-2">
            {data &&
              data.list?.sessions &&
              data.list?.sessions?.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="flex flex-row items-center gap-4">
                    <div>
                      <p className="font-bold">
                        {new Date(value.date).toLocaleDateString("en-AU", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </p>
                      <small>{value.time}</small>
                    </div>
                    {/* comments count badge */}
                    <div className="bg-gray-100 rounded-full px-2">
                      <p className="text-gray-600 text-sm flex items-center gap-1">
                        <ChatAltIcon className="h-4 w-4" />{" "}
                        <span className="font-semibold font-mono">
                          {value.commentsCount ? value.commentsCount : 0}
                        </span>
                      </p>
                    </div>
                  </td>
                  <td
                    className={clsx(
                      value.conflict && "text-error animate-pulse"
                    )}
                  >
                    {value.hall?.name ?? "-"}
                  </td>
                  <td>
                    <div className="flex flex-row gap-2 justify-center items-center">
                      {value.teacher?.name ?? "-"}
                      {value.status === "COMPLETE" &&
                        value.teacher.name &&
                        value.assessmentsCount === 0 && (
                          <ExclamationIcon className="h-5 w-5 text-warning" />
                        )}
                    </div>
                  </td>
                  <td className="text-center">{value.rosterCount}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <Link
                        to={`/session/${value.id}`}
                        className="btn btn-xs btn-ghost"
                      >
                        details
                      </Link>
                      {value.rosterCount === 0 && (
                        <button
                          className="btn btn-xs btn-outline btn-error"
                          onClick={() => handleRemove(value.id)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* action */}
      <div className="flex w-full justify-center pt-4">
        {!hasStarted && !hasAdmissions && (
          <AddSessionModal
            title="Add Session"
            content="Add a new session to this workshop"
            btn="btn btn-dark btn-outline"
            label="Add Session"
          >
            <SessionForm
              payload={{ type: "workshop", id: workshopId }}
              refetch={refetch}
            />
          </AddSessionModal>
        )}
      </div>
    </section>
  );
}

function WorkshopAdmissions(props) {
  const { id, isAdmin, setAdmissions, setCancel } = props;

  const { data } = useQuery(ADMISSIONS_LIST, {
    variables: {
      workshopId: id,
      status: ["DRAFT", "APPROVED", "COMPLETED"],
    },
    pollInterval: 60000,
    onCompleted: (data) => {
      if (data.list?.admissions?.length > 0) {
        setAdmissions(true);
        setCancel(false);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get workshop admissions");
    },
  });

  const calcAttendace = (attended, total) => {
    if (total === 0) return 0;
    return (attended / total) * 100;
  };

  return (
    <section>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Name</th>
              <th>From</th>
              <th>Method</th>
              {isAdmin && <th>Paid</th>}
              <th>Attendance</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.list?.admissions &&
              data.list?.admissions.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="flex flex-row gap-4 items-center">
                    {value.student?.picture ? (
                      <img
                        src={`${BUCKET_URL}/${value.student?.picture}`}
                        className="w-14 h-14 rounded-full"
                        alt={`${value.student?.name}`}
                      />
                    ) : (
                      <img
                        src={`${BUCKET_URL}/pictures/placeholder.jpg`}
                        className="w-14 h-14 rounded-full"
                        alt={`${value.student?.name}`}
                      />
                    )}
                    <div>
                      <p className="font-bold">{value.student?.name}</p>
                      <p>
                        {value.user?.email} / {value.user?.phone}
                      </p>
                    </div>
                  </td>
                  <td>{value.from}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  {isAdmin && (
                    <td className="font-mono">
                      {formatter.format(value.total / 100)}
                    </td>
                  )}
                  <td>
                    {calcAttendace(
                      value.rosterCountAttended,
                      value.rosterCount
                    )}
                    %
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
