import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CREATE_TEACHER, UPDATE_TEACHER } from "../../utils/mutations";
import { useMutation, useLazyQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { TEACHER_INFO, LIST_TEACHERS } from "../../utils/queries";

export const TeacherForm = (props) => {
  const { id } = props;

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm({ mode: "onChange" });

  // date from dd/mm/yyyy to yyyy-mm-dd
  const formatDate = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  const [prefill, { loading: prefilling }] = useLazyQuery(TEACHER_INFO, {
    variables: { teacherId: id },
    onCompleted: (data) => {
      if (data.teacher) {
        const { teacher } = data;
        setValue("teacher.firstName", teacher.firstName);
        setValue("teacher.lastName", teacher.lastName);
        setValue("teacher.dob", formatDate(teacher.dob));
        setValue("teacher.gender", teacher.gender);
        setValue("teacher.email", teacher.user?.email || "");
        setValue("teacher.phone", teacher.user?.phone || "");
        setValue("teacher.street", teacher.user?.street || "");
        setValue("teacher.suburb", teacher.user?.suburb || "");
        setValue("teacher.postcode", teacher.user?.postcode || "");
        setValue("teacher.payRate", teacher.payRate / 100);
        setValue("teacher.status", teacher.status);
      }
    },
    onError: (err) => {
      console.error(err);
      toast.error("Unable to fetch teacher details");
    },
  });

  const [createTeacher, { loading: creating }] = useMutation(CREATE_TEACHER, {
    onCompleted: () => {
      toast.success("Teacher created successfully");
      navigate("/teachers");
    },
    onError: (err) => {
      console.error(err);
      toast.error("Unable to create teacher");
    },
    refetchQueries: [{ query: LIST_TEACHERS }],
  });

  const [updateTeacher, { loading: updating }] = useMutation(UPDATE_TEACHER, {
    onCompleted: () => {
      toast.success("Teacher updated successfully");
      navigate(-1);
    },
    onError: (err) => {
      console.error(err);
      toast.error("Unable to update teacher");
    },
    refetchQueries: [{ query: TEACHER_INFO, variables: { teacherId: id } }],
  });

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      dob,
      gender,
      email,
      phone,
      street,
      suburb,
      postcode,
      payRate,
      status,
    } = data.teacher;

    if (id) {
      const input = {
        id,
        firstName,
        lastName,
        dob,
        gender,
        email: email.toLowerCase(),
        payRate: payRate ? parseInt(payRate * 100) : 0,
        status,
      };

      updateTeacher({ variables: { input } });
    } else {
      const input = {
        firstName,
        lastName,
        dob,
        gender,
        email: email.toLowerCase(),
        phone,
        street,
        suburb,
        postcode,
        payRate: payRate ? parseInt(payRate * 100) : 0,
      };

      createTeacher({ variables: { input } });
    }
  };

  useEffect(() => {
    if (id) prefill();
  }, [id, prefill]);

  useEffect(() => {
    if (prefilling || creating || updating) setLoader(true);
    else setLoader(false);
  }, [prefilling, creating, updating]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between space-x-3 max-w-md">
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">First name</span>
            </label>
            <input
              type="text"
              placeholder="John"
              className="input input-bordered w-full bg-white"
              {...register("teacher.firstName")}
              required
            />
          </div>
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Last name</span>
            </label>
            <input
              type="text"
              placeholder="Doe"
              className="input input-bordered w-full bg-white"
              {...register("teacher.lastName")}
              required
            />
          </div>
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Date of birth</span>
          </label>
          <input
            type="date"
            placeholder="Type here"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("teacher.dob")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Gender</span>
          </label>
          <select
            defaultValue="Select"
            className="select select-bordered w-full max-w-md bg-white"
            {...register("teacher.gender")}
            required
          >
            <option disabled>Select</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </select>
        </div>
        {!id && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Phone</span>
            </label>
            <input
              type="tel"
              placeholder="0400 000 000"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("teacher.phone")}
              required
            />
          </div>
        )}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Email address</span>
          </label>
          <input
            type="email"
            placeholder="john.doe@email.com"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("teacher.email")}
            required
          />
        </div>
        {!id && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Street</span>
            </label>
            <input
              type="text"
              placeholder="Street address"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("teacher.street")}
            />
          </div>
        )}
        {!id && (
          <div className="flex justify-between space-x-3 max-w-md">
            <div className="w-full">
              <label className="label">
                <span className="label-text font-semibold">Suburb</span>
              </label>
              <input
                type="text"
                placeholder="Suburb"
                className="input input-bordered w-full bg-white"
                {...register("teacher.suburb")}
              />
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text font-semibold">Postcode</span>
              </label>
              <input
                type="text"
                placeholder="Postcode"
                className="input input-bordered w-full bg-white"
                {...register("teacher.postcode")}
              />
            </div>
          </div>
        )}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Pay rate ($)</span>
          </label>
          <input
            type="number"
            placeholder="0.00"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("teacher.payRate")}
          />
        </div>
        {id && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="status"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("teacher.status")}
              required
            >
              <option disabled>Select</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>
        )}
        <div className="pt-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
