import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { APPLICABLE_WORKSHOPS } from "../../utils/queries";
import { formatter, toDDMMYYYY } from "../../utils/helpers";

export const WorkshopSelector = (props) => {
  const { student, selection, setSelection } = props;

  const [workshops, setWorkshops] = useState([]);
  const [options, setOptions] = useState({
    locations: [],
    groups: [],
  });
  const [filter, setFilter] = useState({
    location: "All",
    group: "All",
  });
  const [results, setResults] = useState([]);

  useQuery(APPLICABLE_WORKSHOPS, {
    variables: {
      studentId: student.id,
    },
    onCompleted: (data) => {
      if (data.workshops.length > 0) {
        setWorkshops(data.workshops);
        const allGroups = data.workshops.map((w) => w.groups).flat();
        setOptions({
          locations: [...new Set(data.workshops.map((w) => w.location))],
          groups: [...new Set(allGroups)],
        });
      } else setWorkshops([]);
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get applicable workshops!");
    },
    fetchPolicy: "network-only",
  });

  // on filter change update filtered classes
  useEffect(() => {
    // filter classes based on filter
    let filtered = workshops;
    // if location is selected, filter by location
    if (filter.location && filter.location !== "All") {
      filtered = filtered.filter((w) => w.location.id === filter.location);
    }
    // if group is selected, filter by group
    if (filter.group && filter.group !== "All") {
      filtered = filtered.filter((w) => {
        const groups = w.groups.map((g) => g.id);
        return groups.includes(filter.group);
      });
    }
    // update filtered classes
    setResults(filtered);
  }, [filter, workshops]);

  return (
    <div className="relative border-b-2 pb-10 mb-4 pt-2">
      {workshops.length > 0 ? (
        <div>
          {/* dropdown filters for options */}
          <div className="w-full flex flex-col">
            <div className="flex flex-col sm:flex-row gap-2">
              {/* select group */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">
                    Filter by group
                  </span>
                </label>
                <select
                  className="select select-bordered w-full bg-white"
                  value={filter.group}
                  onChange={(e) =>
                    setFilter({ ...filter, group: e.target.value })
                  }
                >
                  <option value="All">All</option>
                  {options.groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* select location */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">
                    Filter by location
                  </span>
                </label>
                <select
                  className="select select-bordered w-full bg-white"
                  value={filter.location}
                  onChange={(e) =>
                    setFilter({ ...filter, location: e.target.value })
                  }
                >
                  {/* all */}
                  <option value="All">All</option>
                  {options.locations?.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* radio group for workshops results */}
          <RadioGroup value={selection} onChange={setSelection}>
            {results.length > 0 && (
              <RadioGroup.Label className="label">
                <span className="label-text font-semibold">Select a class</span>
              </RadioGroup.Label>
            )}
            <div className="flex flex-col gap-2 w-full">
              {results.length > 0 ? (
                results.map((workshopInfo) => (
                  <RadioGroup.Option
                    key={workshopInfo.id}
                    value={workshopInfo}
                    className={({ active, checked }) =>
                      clsx(
                        active && "ring-2 ring-offset-2 ring-primary",
                        checked
                          ? "bg-base-200 text-primary ring-1 ring-offset-1 ring-primary"
                          : "bg-white text-gray-900",
                        "relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center gap-4">
                            <div className="text-sm">
                              <RadioGroup.Label
                                as="p"
                                className={`font-medium  ${
                                  checked ? "text-primary" : "text-gray-900"
                                }`}
                              >
                                {workshopInfo.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={`inline ${
                                  checked ? "text-gray-900" : "text-gray-500"
                                }`}
                              >
                                {workshopInfo?.location?.name && (
                                  <span>{workshopInfo.location.name} | </span>
                                )}
                                <span>
                                  {workshopInfo?.from
                                    ? toDDMMYYYY(workshopInfo?.from)
                                    : "?"}{" "}
                                </span>
                                {workshopInfo?.to &&
                                  workshopInfo?.to !== workshopInfo?.from && (
                                    <span>
                                      - {toDDMMYYYY(workshopInfo?.to)}
                                    </span>
                                  )}
                                <span>
                                  | {workshopInfo.sessionsAvailable} sessions{" "}
                                  remaining{" "}
                                </span>
                                <span>
                                  | {formatter.format(workshopInfo.price / 100)}{" "}
                                  per session{" "}
                                </span>
                              </RadioGroup.Description>
                            </div>
                          </div>
                          <div className="shrink-0 fill-secondary">
                            {checked && <CheckCircleIcon className="h-5 w-5" />}
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))
              ) : (
                <div className="flex flex-row gap-2 justify-center w-full pt-6">
                  <p className="text-gray-500">No workshops found.</p>
                </div>
              )}
            </div>
          </RadioGroup>
        </div>
      ) : (
        <div className="flex justify-center p-6">
          <p>No workshops available for the student</p>
        </div>
      )}
    </div>
  );
};
