import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_LOCATION, UPDATE_LOCATION } from "../../utils/mutations";
import { LOCATION_INFO, LIST_LOCATIONS } from "../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const LocationForm = (props) => {
  const { id } = props;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState(null);
  const { register, setValue, handleSubmit } = useForm();

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [prefill, { data: prefillData, error: prefillError }] = useLazyQuery(
    LOCATION_INFO,
    { variables: { locationId: id } }
  );
  const [createLocation, { loading, data, error }] = useMutation(
    CREATE_LOCATION,
    {
      refetchQueries: [{ query: LIST_LOCATIONS }],
    }
  );
  const [
    updateLocation,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useMutation(UPDATE_LOCATION, {
    refetchQueries: [{ query: LOCATION_INFO, variables: { locationId: id } }],
  });

  const onSubmit = (data) => {
    const {
      name,
      line1,
      line2,
      suburb,
      state,
      postcode,
      email,
      lat,
      lng,
      active,
    } = data.location;
    if (id) {
      updateLocation({
        variables: {
          input: {
            id,
            name,
            line1,
            line2,
            suburb,
            state,
            postcode,
            email,
            latitude: lat,
            longitude: lng,
            active: active === "true" ? true : false,
          },
        },
      });
    } else {
      createLocation({
        variables: {
          input: {
            name,
            line1,
            line2,
            suburb,
            state,
            postcode,
            email,
            latitude: lat,
            longitude: lng,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      prefill();
    }
  }, [id, prefill]);

  useEffect(() => {
    if (prefillData) {
      setInfo({
        name: prefillData.location.name,
        line1: prefillData.location.line1,
        line2: prefillData.location.line2,
        suburb: prefillData.location.suburb,
        state: prefillData.location.state,
        postcode: prefillData.location.postcode,
        email: prefillData.location.email,
        lat: prefillData.location.coords.lat,
        lng: prefillData.location.coords.lng,
        active: prefillData.location.active ? "true" : "false",
      });
    }
  }, [prefillData]);

  useEffect(() => {
    if (prefillError) notifyError();
  }, [prefillError]);

  useEffect(() => {
    if (info) {
      setValue("location", info);
    }
  }, [info, setValue]);

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      navigate(`/config/locations`);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  useEffect(() => {
    if (updateLoading) setLoader(true);
  }, [updateLoading]);

  useEffect(() => {
    if (updateData) {
      setLoader(false);
      notifySuccess();
    }
  }, [updateData]);

  useEffect(() => {
    if (updateError) {
      setLoader(false);
      notifyError();
    }
  }, [updateError]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Name</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Studio name"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("location.name")}
              required
            />
          </div>
        </div>
        {/* address line1 */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Address line 1</span>
            </label>
            <input
              type="text"
              name="line1"
              placeholder="Street address"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("location.line1")}
              required
            />
          </div>
        </div>
        {/* address line2 */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Address line 2</span>
            </label>
            <input
              type="text"
              name="line2"
              placeholder="Suite, apt, etc."
              className="input input-bordered w-full max-w-md bg-white"
              {...register("location.line2")}
            />
          </div>
        </div>
        {/* suburb */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Suburb</span>
            </label>
            <input
              type="text"
              name="suburb"
              placeholder="Suburb"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("location.suburb")}
              required
            />
          </div>
        </div>
        {/* state */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">State</span>
            </label>
            <input
              type="text"
              name="state"
              placeholder="State"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("location.state")}
              required
            />
          </div>
        </div>
        {/* postcode */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Postcode</span>
            </label>
            <input
              type="text"
              name="postcode"
              placeholder="Postcode"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("location.postcode")}
              required
            />
          </div>
        </div>
        {/* email */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Email</span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("location.email")}
              required
            />
          </div>
        </div>
        <div className="flex gap-2 max-w-md">
          {/* lat */}
          <div className="basis-1/2">
            <label className="label">
              <span className="label-text font-semibold">Latitude</span>
            </label>
            <input
              type="text"
              name="lat"
              placeholder="Latitude"
              className="input input-bordered w-full bg-white"
              {...register("location.lat")}
              required
            />
          </div>
          {/* lng */}
          <div className="basis-1/2">
            <label className="label">
              <span className="label-text font-semibold">Longitude</span>
            </label>
            <input
              type="text"
              name="lng"
              placeholder="Longitude"
              className="input input-bordered w-full bg-white"
              {...register("location.lng")}
              required
            />
          </div>
        </div>
        {/* active */}
        {id && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="preassessment"
              defaultValue="Select"
              className="select select-bordered w-full max-w-md bg-white"
              {...register("location.active")}
              required
            >
              <option disabled>Select</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        )}
        {/* submit */}
        <div className="pt-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
