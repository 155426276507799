import { useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { OverallStats } from "../library/stats/Overall";
import { TermStats } from "../library/stats/Term";
import { TodayTable } from "../library/table/Today";
import { DASHBOARD_PARAMS } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";

function Dashboard() {
  const { isAdmin, isManager } = useContext(AuthContext);

  const { loading, data } = useQuery(DASHBOARD_PARAMS, {
    pollInterval: 30000,
    onError: (err) => {
      console.error(err);
      toast.error("Unable to load dashboard data!");
    },
  });

  useEffect(() => {
    if (loading) toast.loading("Loading...");
    else toast.dismiss();
  }, [loading]);

  return (
    <UserLayout title="Dashboard">
      <section>
        <SectionHead heading="Dashboard"></SectionHead>
        {data && (
          <div className="space-y-4">
            {(isAdmin || isManager) && (
              <OverallStats
                customers={data.dashboard.customers}
                students={data.dashboard.students}
                terms={data.dashboard.terms}
                programs={data.dashboard.programs}
                locations={data.dashboard.locations}
              />
            )}
            <TermStats
              term={data.dashboard.term}
              classes={data.dashboard.classes}
              sessions={data.dashboard.sessions}
              enrolments={data.dashboard.enrolments}
              attendance={data.dashboard.attendance}
              revenue={data.dashboard.revenue}
            />
            <TodayTable list={data.dashboard.list} />
          </div>
        )}
      </section>
    </UserLayout>
  );
}

export default Dashboard;
