import { LIST_ADMIN_USERS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const AdminUsersTable = (props) => {
  const notifyError = () => toast.error("Something went wrong!");

  const { data, error } = useQuery(LIST_ADMIN_USERS, {
    fetchPolicy: "cache-and-network",
    pollInterval: 120000,
  });

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Email</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.users &&
              data.users.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="font-bold">{value.email}</td>
                  <td>{upperFirst(value.role.toLowerCase())}</td>
                  <td>
                    <Link
                      to={`/edit/admin/${value.id}`}
                      className={`btn btn-xs btn-ghost`}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function upperFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}
