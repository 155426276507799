import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { AuthContext } from "../context/authContext";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { CustomerSelector } from "../library/selector/Customer";
import { StudentSelector } from "../library/selector/Student";
import { WorkshopSelector } from "../library/selector/Workshop";
import {
  APPLICABLE_SESSIONS,
  WORKSHOPS_LIST,
  APPLICABLE_WORKSHOPS,
  ADMISSIONS_LIST,
  ADMISSION_INFO,
} from "../utils/queries";
import { DRAFT_ENTITY, CANCEL_ENTITY } from "../utils/mutations";
import {
  formatMobile,
  formatter,
  paymentTagColor,
  statusTagColor,
} from "../utils/helpers";
import { useDebounce } from "@uidotdev/usehooks";
import { CSVLink } from "react-csv";
import { LoadingLayout } from "../layout/Loading";
import { OverviewSection } from "../library/section/Overview";
import { RostersList } from "../library/list/Rosters";
import { TransactionSection } from "../library/section/Transaction";

export default function Admission(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    if (param === "draft") return <AdmissionDraft />;
    return (
      <AdmissionDetails id={param} isAdmin={isAdmin} isManager={isManager} />
    );
  } else {
    return <AdmissionsList isAdmin={isAdmin} isManager={isManager} />;
  }
}

function AdmissionDraft(props) {
  const [customer, setCustomer] = useState(null);
  const [student, setStudent] = useState(null);
  const [workshopInfo, setWorkshopInfo] = useState(null);

  const navigate = useNavigate();

  const getGroupIds = (groups) => groups.map((group) => group.id);

  useEffect(() => {
    if (!customer) {
      setStudent(null);
    }
  }, [customer]);

  return (
    <UserLayout title={`Workshop Admission`}>
      <SectionHead heading="Draft Admission">
        <button
          className="btn btn-outline"
          onClick={() => navigate("/admission")}
        >
          List Admissions
        </button>
      </SectionHead>
      <section className="flex flex-col gap-4 py-2">
        {/* customer */}
        <CustomerSelector selection={customer} setSelection={setCustomer} />
        {/* student */}
        {customer && (
          <StudentSelector
            customerId={customer.id}
            selection={student}
            setSelection={setStudent}
            type="admission"
          />
        )}
        {/* workshop */}
        {student && (
          <WorkshopSelector
            groupsId={getGroupIds(student.groups)}
            customer={customer}
            student={student}
            selection={workshopInfo}
            setSelection={setWorkshopInfo}
            type="admission"
          />
        )}
        {/* form */}
        {customer && student && workshopInfo && (
          <AdmissionForm
            customer={customer}
            student={student}
            workshopInfo={workshopInfo}
            clear={() => {
              setCustomer(null);
              setStudent(null);
              setWorkshopInfo(null);
            }}
          />
        )}
      </section>
    </UserLayout>
  );
}

function AdmissionForm(props) {
  const { customer, student, workshopInfo, clear } = props;

  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState(null);
  const [sessions, setSessions] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const { refetch } = useQuery(APPLICABLE_SESSIONS, {
    variables: {
      workshopId: workshopInfo.id,
      studentId: student.id,
      status: ["COMPLETE", "ONGOING", "UPCOMING"],
    },
    onCompleted: (data) => {
      setSessions(data.sessions);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        toast.success("Admission added successfully!");
        refetch();
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    refetchQueries: [
      {
        query: WORKSHOPS_LIST,
        variables: {
          status: ["AWAITING", "APPROVED", "CANCELLED", "REFUNDED"],
          page: 0,
          limit: 10,
        },
      },
      {
        query: APPLICABLE_WORKSHOPS,
        variables: { studentId: student.id },
      },
    ],
  });

  const onSubmit = (data) => {
    const { discount, method, ref } = data.admission;

    const input = {
      type: "ADMISSION",
      admission: {
        userId: customer.id,
        studentId: student.id,
        workshopId: workshopInfo.id,
        from,
        total,
        method,
        ref,
        discount: parseInt(discount * 100),
      },
    };

    draftEntity({ variables: { ...input } });
  };

  // watch input value for discount
  const session = watch("admission.session");
  const discount = watch("admission.discount");
  const showReference = watch("admission.method") === "MANUAL";

  useEffect(() => {
    if (session && session !== "") {
      const getIndex = sessions.findIndex((s) => s.id === session);
      const selectedSession = sessions[getIndex];
      setFrom(selectedSession ? selectedSession?.date : null);
      // totalSessions = rest of the sessions + current session
      const totalSessions = sessions.length - getIndex;
      let amount = workshopInfo.price * totalSessions;
      if (discount) {
        amount -= discount * 100;
      }
      setTotal(amount < 0 ? 0 : amount);
    }
  }, [session, discount, sessions, workshopInfo]);

  return (
    <div className="relative w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-4"
      >
        {sessions && sessions.length > 0 && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Select Session</span>
            </label>
            <select
              className="select select-bordered bg-white w-full"
              {...register("admission.session", {
                required: true,
                validate: (value) => {
                  // if "" then not valid
                  if (!value) return false;
                  // if value is in sessions then valid
                  return !!sessions.find((session) => session.id === value);
                },
              })}
              defaultValue={""}
            >
              <option value="" disabled>
                Select from session
              </option>
              {sessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.name} (
                  {new Date(session.date).toLocaleDateString("en-AU")})
                </option>
              ))}
            </select>
          </div>
        )}
        {/* payment info */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row gap-4">
            {/* discount */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">
                  Discount Amount ($)
                </span>
              </label>
              <input
                type="number"
                name="discount"
                placeholder="Discount"
                className="input input-bordered w-full bg-white"
                {...register("admission.discount")}
                defaultValue={0}
                min={0}
                step={1}
              />
            </div>
            {/* method (select: MANUAL or STRIPE) */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Payment Method</span>
              </label>
              <select
                className="select select-bordered w-full bg-white"
                {...register("admission.method", {
                  required: true,
                  validate: (value) => {
                    return !!value;
                  },
                })}
                defaultValue={""}
              >
                <option value="">Select a payment method</option>
                <option value="MANUAL">
                  Manual / Bank Transfer / EFTPOS / Other
                </option>
                <option value="STRIPE" disabled={total <= 0}>
                  Online (customer will see this under pending and pay online)
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {/* ref (input) */}
            {showReference && (
              <div>
                <label className="label">
                  <span className="label-text font-semibold">
                    Payment Reference
                  </span>
                </label>
                <input
                  type="text"
                  name="ref"
                  placeholder="Reference Number"
                  className="input input-bordered w-full bg-white"
                  {...register("admission.ref", {
                    required: true,
                  })}
                />
              </div>
            )}
          </div>
        </div>
        {/* submit */}
        {sessions && sessions.length > 0 && (
          <button
            type="submit"
            className={clsx("btn btn-primary", loading && "loading")}
            disabled={!isValid || loading}
          >
            Add Admission {formatter.format(total / 100)}
          </button>
        )}
      </form>
    </div>
  );
}

function AdmissionsList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [name, setName] = useState("");
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [admissions, setAdmissions] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(ADMISSIONS_LIST, {
    onCompleted: (data) => {
      setAdmissions(data.list.admissions);
      setTotal(data.list.total);
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get admissions list!");
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      from: !!from ? from : null,
      to: !!to ? to : null,
      status: ["DRAFT", "APPROVED", "COMPLETED", "REFUNDED"],
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getAdmissionsToDownload, { data: download, loading: downloading }] =
    useLazyQuery(ADMISSIONS_LIST, {
      onCompleted: (res) => {
        if (res.list.admissions.length > 0) {
          const formatted = res.list.admissions.map((admission, i) => ({
            "No.": i + 1,
            Name: admission.name,
            Workshop: admission.workshop?.name ?? "-",
            From: admission.from,
            Method: admission.payment.method ?? "UNKNOWN",
            ...(isAdmin && {
              Paid: formatter.format(admission.total / 100),
              "Discount Amt.": formatter.format(
                admission.amounts.discount / 100
              ),
              "Total Amt.": formatter.format(
                (admission.total + admission.amounts.discount) / 100
              ),
              "Refund Amt.": formatter.format(admission.amounts.refunded / 100),
              "Payment Ref.": admission.payment?.ref ?? "-",
            }),
            Status: admission.status,
            Student: admission.student?.name ?? "-",
            Email: admission.user.email ?? "-",
            Phone: admission.user.phone
              ? formatMobile(admission.user.phone)
              : "-",
            Sessions: admission.rosterCount,
            Attended: admission.rosterCountAttended,
            status: admission.status,
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Unable to get admissions list for download!");
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getAdmissionsToDownload({
      variables: {
        name: !!debouncedName ? debouncedName : null,
        from: !!from ? from : null,
        to: !!to ? to : null,
        status: ["DRAFT", "APPROVED", "COMPLETED", "REFUNDED"],
        page: 0,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title={`Admissions List`}>
      <SectionHead heading="Admissions List">
        <button
          className="btn btn-outline"
          onClick={() => navigate("/admission/draft")}
        >
          Draft Admission
        </button>
      </SectionHead>
      <div className="flex flex-col gap-4 py-2">
        {/* table */}
        <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
          {/* table header */}
          <div className="flex w-full px-4 py-4 border-b-2">
            <div className="flex flex-row justify-between items-center w-full gap-4">
              {/* filters */}
              <div className="basis-3/4 flex flex-row gap-4">
                {/* name search */}
                <div className="w-1/3">
                  <label className="label sr-only">
                    <span className="label-text font-semibold">Name</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Search by name"
                    className="input input-bordered w-full bg-white"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                {/* date from */}
                <div className="w-1/3">
                  <label className="label sr-only">
                    <span className="label-text font-semibold">Date From</span>
                  </label>
                  <input
                    type="date"
                    className="input input-bordered w-full bg-white"
                    onChange={(e) => {
                      setFrom(e.target.value);
                      refetch();
                    }}
                    value={from}
                    max={to}
                  />
                </div>
                {/* date to */}
                <div className="w-1/3">
                  <label className="label sr-only">
                    <span className="label-text font-semibold">Date To</span>
                  </label>
                  <input
                    type="date"
                    className="input input-bordered w-full bg-white"
                    onChange={(e) => {
                      setTo(e.target.value);
                      refetch();
                    }}
                    value={to}
                    min={from}
                  />
                </div>
              </div>
              {/* buttons */}
              <div className="flex flex-row gap-2">
                {(isAdmin || isManager) && (
                  <>
                    {/* download csv */}
                    {download &&
                    download?.list?.admissions.length === data.length ? (
                      <CSVLink
                        className="btn btn-ghost"
                        data={data}
                        filename={`admissions.csv`}
                        onClick={(e) => {
                          setData([]);
                        }}
                      >
                        Download CSV
                      </CSVLink>
                    ) : (
                      <button
                        className={clsx(
                          "btn btn-ghost",
                          downloading && "loading"
                        )}
                        onClick={getDownloadData}
                      >
                        Generate CSV
                      </button>
                    )}
                  </>
                )}
                {/* reset button */}
                <button
                  className="btn btn-ghost"
                  onClick={() => {
                    setName("");
                    setFrom(dateMonthAgo);
                    setTo(dateTomorrow);
                    setData([]);
                    refetch();
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          {/* table content */}
          {!loading ? (
            <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
              {/* head */}
              <thead className="text-left">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>From</th>
                  <th>Method</th>
                  <th>Paid</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {/* body */}
              {admissions.length > 0 ? (
                <tbody className="divide-y-2">
                  {admissions.map((value, index) => (
                    <tr key={value.id}>
                      <th>{page * limit + index + 1}</th>
                      <td className="flex flex-row gap-4 items-center">
                        <div>
                          <p className="font-bold">{value.name}</p>
                          <p>
                            <span className="font-medium">
                              {value.workshop?.name ?? "-"}
                            </span>
                          </p>
                          <p>
                            {value.discount > 0 && (
                              <span className="text-xs text-gray-600">
                                {" "}
                                / discount:{" "}
                                {formatter.format(value.discount / 100)}
                              </span>
                            )}
                          </p>
                        </div>
                      </td>
                      <td>
                        <span className="font-medium capitalize tracking-wider">
                          {value.from ?? "-"}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                            value.payment.method
                          )}`}
                        >
                          {formatLabel(value.payment.method ?? "UNKNOWN")}
                        </span>
                      </td>
                      <td className="tracking-wider font-mono">
                        {formatter.format(value.total / 100)}
                      </td>
                      <td>
                        <span
                          className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                            value.status
                          )}`}
                        >
                          {value.status}
                        </span>
                      </td>
                      <td>
                        <button
                          className={`btn btn-xs btn-ghost ${
                            value.status === "ACTIVE" ? "hidden" : ""
                          }`}
                          onClick={() => navigate(`/admission/${value.id}`)}
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <div className="flex flex-col items-center justify-center py-8">
                        <p className="text-gray-400 text-lg">
                          No admissions found
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <>
              {/* loading skeleton */}
              <LoadingLayout type="list" />
            </>
          )}
          {/* table footer */}
          <div className="flex w-full px-4 py-4 border-t-2">
            <div className="flex flex-row justify-between items-center w-full">
              <div>
                <p>
                  Showing <span className="font-bold">{page * limit + 1}</span>{" "}
                  -{" "}
                  <span className="font-bold">
                    {page * limit + admissions.length}
                  </span>{" "}
                  of <span className="font-bold">{total}</span> results
                </p>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline btn-sm"
                  disabled={page === 0 || maxPage <= 1}
                  onClick={() => prevPage()}
                >
                  Prev
                </button>
                <button
                  className="btn btn-outline btn-sm"
                  disabled={page === maxPage - 1 || maxPage <= 1}
                  onClick={() => nextPage()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}

function AdmissionDetails(props) {
  const { id, isAdmin } = props;

  const navigate = useNavigate();

  const { data } = useQuery(ADMISSION_INFO, {
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get admission details!");
      navigate(-1);
    },
    variables: { admissionId: id },
    fetchPolicy: "network-only",
  });

  const [cancelEntity, { loading: cancelling }] = useMutation(CANCEL_ENTITY, {
    onError: (error) => {
      console.error(error.message);
      toast.error("Unable to cancel the draft.");
    },
    onCompleted: () => {
      toast.success("Cancelled successfully.");
      navigate(-1);
    },
  });

  const handleCancel = () => {
    window.confirm("Are you sure you want to cancel this admission?") &&
      cancelEntity({
        variables: {
          type: "ADMISSION",
          entityId: id,
        },
      });
  };

  return (
    <UserLayout title={`Admission Details`}>
      <SectionHead heading="Admission Details">
        {/* cancel - if draft */}
        {data && data.admission && data.admission.status === "DRAFT" && (
          <button
            className={clsx(
              "btn btn-outline btn-error",
              cancelling && "loading"
            )}
            onClick={handleCancel}
          >
            Cancel
          </button>
        )}
        {/* Back to Passes */}
        <button className="btn btn-outline" onClick={() => navigate(-1)}>
          Back
        </button>
      </SectionHead>
      {/* overview section */}
      {data && data.admission && <OverviewSection data={data.admission} />}
      {/* rosters section */}
      {data && data.admission && data.admission.rosters && (
        <RostersList admissionId={id} />
      )}
      {/* transaction section */}
      {data && data.admission && data.admission.status !== "DRAFT" && (
        <TransactionSection
          id={id}
          paymentRefundAllowed={data.admission.status === "APPROVED" && isAdmin}
        />
      )}
    </UserLayout>
  );
}
