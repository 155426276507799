import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_STUDENT } from "../../utils/mutations";
import { STUDENT_INFO, LIST_STUDENTS } from "../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import moment from "moment";
import { StudentPicture } from "./StudentPicture";

export const UpdateStudentForm = (props) => {
  const { register, setValue, handleSubmit } = useForm();

  const studentId = props.student;
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState({});
  const [id, setId] = useState(null);

  const notifyError = () => toast.error("Unable to update student!");

  const formatter = (value) => moment.unix(value / 1000).format("YYYY-MM-DD");

  const [getInfo] = useLazyQuery(STUDENT_INFO, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLoader(false);
      if (data.student) {
        const studentData = data.student;
        setInfo({
          ...studentData,
          dob: formatter(studentData.dob),
        });
      }
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
  });

  const [updateStudent] = useMutation(UPDATE_STUDENT, {
    refetchQueries: [
      {
        query: LIST_STUDENTS,
        fetchPolicy: "network-only",
      },
      { query: STUDENT_INFO, variables: { id: studentId } },
    ],
    onCompleted: () => {
      setLoader(false);
      setSuccess(true);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
  });

  const onSubmit = (data) => {
    const { firstName, middleName, lastName, dob, gender, notes } = data.update;
    updateStudent({
      variables: {
        input: {
          id: id,
          firstName,
          middleName,
          lastName,
          dob,
          gender,
          notes,
        },
      },
    });
  };

  const updatePicture = (picture) => {
    setInfo({ ...info, picture });
    updateStudent({
      variables: {
        input: {
          id: id,
          picture,
        },
        refetchQueries: [STUDENT_INFO],
      },
    });
  };

  const doneAction = () => {
    setSuccess(false);
    setLoader(false);
  };

  useEffect(() => {
    if (studentId) {
      getInfo({ variables: { id: studentId } });
      setId(studentId);
    }
  }, [studentId, getInfo]);

  useEffect(() => {
    setValue("update", info);
  }, [info, setValue]);

  return (
    <div>
      {success ? (
        <div className="flex flex-col items-center gap-6">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 text-success"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h3 className="text-3xl font-normal">Student updated</h3>
          <div className="w-full mt-8">
            <label
              htmlFor="student-modal"
              className="btn w-full btn-outline"
              onClick={() => doneAction()}
            >
              Done
            </label>
          </div>
        </div>
      ) : (
        <>
          <StudentPicture
            studentId={id}
            picture={info.picture}
            update={updatePicture}
          />
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
            {/* name */}
            <div className="flex flex-col justify-between space-y-3">
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">First name</span>
                </label>
                <input
                  type="text"
                  placeholder="John"
                  className="input input-bordered w-full"
                  {...register("update.firstName")}
                  required
                />
              </div>
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">Middle name</span>
                </label>
                <input
                  type="text"
                  placeholder="Fred"
                  className="input input-bordered w-full"
                  {...register("update.middleName")}
                />
              </div>
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">Last name</span>
                </label>
                <input
                  type="text"
                  placeholder="Doe"
                  className="input input-bordered w-full"
                  {...register("update.lastName")}
                  required
                />
              </div>
            </div>
            {/* dob */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Date of birth</span>
              </label>
              <input
                type="date"
                placeholder="Type here"
                className="input input-bordered w-full"
                {...register("update.dob")}
                required
              />
            </div>
            {/* gender */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Gender</span>
              </label>
              <select
                defaultValue="Select"
                className="select select-bordered  w-full"
                {...register("update.gender")}
                required
              >
                <option disabled>Select</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
              </select>
            </div>
            {/* notes */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">
                  Allergies or medical conditions
                </span>
              </label>
              <textarea
                className="textarea textarea-bordered w-full"
                placeholder="Please provide information on allergies, conditions, etc."
                {...register("update.notes")}
              ></textarea>
            </div>
            <div className="flex flex-col pt-4 gap-2">
              <button
                type="submit"
                className={
                  loader
                    ? "btn btn-active w-full loading"
                    : "btn btn-active w-full"
                }
              >
                Update Student
              </button>
              <label
                htmlFor="student-modal-update"
                className="btn w-full btn-outline"
              >
                Cancel
              </label>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
