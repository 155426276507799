import { useQuery } from "@apollo/client";
import { TEACHER_WORKSHEET } from "../../utils/queries";
import toast from "react-hot-toast";
import { LoadingLayout } from "../../layout/Loading";

export const WorksheetTable = (props) => {
  const { id } = props;

  const { data, loading } = useQuery(TEACHER_WORKSHEET, {
    variables: { teacherId: id },
    onError: (err) => {
      console.error(err);
      toast.error("Unable to fetch teacher worksheet");
    },
  });

  if (loading) return <LoadingLayout type="list" />;

  return (
    <>
      {data?.worksheet.length > 0 && (
        <div>
          <label className="label">
            <span className="label-text font-semibold">
              Sessions completed (last 6 months)
            </span>
          </label>
          <div className="overflow-x-auto rounded-xl shadow-md">
            <table className="table-normal bg-white rounded-xl w-full">
              {/* head */}
              <thead className="text-left border-b">
                <tr>
                  <th></th>
                  <th>Month</th>
                  <th>Teaching</th>
                  <th>Assisting</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {data?.worksheet.map((data, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td className="font-bold">{data.label}</td>
                    <td>
                      {data.teaching
                        ? `${(data.teaching / 60).toFixed(2)} hrs`
                        : "-"}
                    </td>
                    <td>
                      {data.assisting
                        ? `${(data.assisting / 60).toFixed(2)} hrs`
                        : "-"}
                    </td>
                    <td>{`${((data.teaching + data.assisting) / 60).toFixed(
                      2
                    )} hrs`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
