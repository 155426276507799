import { Helmet } from "react-helmet-async";
import { useContext, useId } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import logo from "../logo.png";
import {
  ChartBarIcon,
  UserCircleIcon,
  UsersIcon,
  TicketIcon,
  QueueListIcon,
  CalendarIcon,
  UserGroupIcon,
  AdjustmentsVerticalIcon,
  ClipboardDocumentCheckIcon,
  SwatchIcon,
  ShoppingBagIcon,
  HandRaisedIcon,
  MapPinIcon,
  KeyIcon,
  PresentationChartLineIcon,
  AcademicCapIcon,
  ClockIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";

const navigationGroups = [
  {
    title: "",
    access: ["ADMIN", "RECEPTION", "MANAGER"],
  },
  {
    title: "Finder",
    access: ["ADMIN", "RECEPTION", "MANAGER"],
  },
  {
    title: "Manage",
    access: ["ADMIN", "RECEPTION", "MANAGER"],
  },
  {
    title: "Configure",
    access: ["ADMIN", "RECEPTION", "MANAGER"],
  },
  {
    title: "",
    access: ["ADMIN", "MANAGER"],
  },
];

const navigationLinks = [
  [
    // Dashboard
    {
      label: "Dashboard",
      link: "/",
      icon: <ChartBarIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
  ],
  // Find
  [
    {
      label: "Finder",
      link: "/finder",
      icon: <QueueListIcon className="h-6 w-6" />,
      access: [],
    },
    {
      label: "Users",
      link: "/users",
      icon: <UsersIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Students",
      link: "/students",
      icon: <UserCircleIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Vouchers",
      link: "/vouchers",
      icon: <TicketIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Transactions",
      link: "/transactions",
      icon: <BanknotesIcon className="h-6 w-6" />,
      access: ["ADMIN"],
    },
    {
      label: "Timesheets",
      link: "/timesheets",
      icon: <ClockIcon className="h-6 w-6" />,
      access: ["ADMIN"],
    },
  ],
  // Manage
  [
    {
      label: "Enrolments",
      link: "/enrolment",
      icon: <ClipboardDocumentCheckIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Passes",
      link: "/pass",
      icon: <SwatchIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Orders",
      link: "/orders",
      icon: <ShoppingBagIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Trainings",
      link: "/training",
      icon: <HandRaisedIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Performances",
      link: "/performance",
      icon: <MapPinIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Hires",
      link: "/hire",
      icon: <KeyIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Admissions",
      link: "/admission",
      icon: <AcademicCapIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
  ],
  // Configure
  [
    {
      label: "Schedule",
      link: "/schedule",
      icon: <CalendarIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Teachers",
      link: "/teachers",
      icon: <UserGroupIcon className="h-6 w-6" />,
      access: ["ADMIN", "RECEPTION", "MANAGER"],
    },
    {
      label: "Additional",
      link: "/config",
      icon: <AdjustmentsVerticalIcon className="h-6 w-6" />,
      access: ["ADMIN", "MANAGER"],
    },
  ],
  // Analytics
  [
    {
      label: "Analytics",
      link: "/analytics",
      icon: <PresentationChartLineIcon className="h-6 w-6" />,
      access: ["ADMIN", "MANAGER"],
    },
  ],
];

export const UserLayout = (props) => {
  const { logout, role } = useContext(AuthContext);

  const year = new Date().getFullYear();

  const baseId = useId();

  const getId = () => {
    return Math.floor(Math.random() * 1000);
  };

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <div>
        <div>
          <div className="drawer drawer-mobile">
            <input
              id="app-menu-drawer"
              type="checkbox"
              className="drawer-toggle"
            />
            <div className="drawer-content w-full flex flex-col items-center">
              {/* page content */}
              <header className="w-full">
                <div className="navbar bg-neutral py-4 px-4">
                  <div className="w-full flex justify-between mx-auto">
                    <div className="flex gap-2 justify-center items-center">
                      <div className="flex-none lg:hidden">
                        <label
                          htmlFor="app-menu-drawer"
                          className="btn btn-ghost text-secondary btn-secondary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="inline-block w-6 h-6 stroke-current"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                          </svg>
                        </label>
                      </div>
                      <Link className="lg:hidden" to="/">
                        <img src={logo} alt="App logo" className="w-48" />
                      </Link>
                    </div>
                    <div>
                      <div className="flex gap-2">
                        <div className="dropdown dropdown-end">
                          <label
                            tabIndex="0"
                            className="btn btn-secondary btn-outline"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              className="inline-block w-5 h-5 stroke-current"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              ></path>
                            </svg>
                          </label>
                          <ul
                            tabIndex="0"
                            className="dropdown-content menu p-2 shadow bg-white rounded-box w-52 mt-6"
                          >
                            <li>
                              <Link
                                className="uppercase text-secondary font-semibold"
                                to="/profile"
                              >
                                My Profile
                              </Link>
                            </li>
                            <li>
                              <div
                                className="uppercase text-secondary font-semibold"
                                onClick={() => logout()}
                              >
                                Logout
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <main className="w-full max-w-7xl mx-auto py-8 px-4">
                {props.children}
              </main>
              <footer className="w-full max-w-7xl mx-auto pt-8 px-4">
                <div className="footer footer-center p-4 text-base-content border-t-2">
                  <div>
                    <p>
                      Copyright © {year} - All right reserved by{" "}
                      <a
                        href="https://swastikdance.com.au"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Swastik Institute of Dance
                      </a>{" "}
                      - Powered by{" "}
                      <a
                        href="https://logicsync.com.au"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LogicSync
                      </a>
                    </p>
                  </div>
                </div>
              </footer>
            </div>
            <div className="drawer-side">
              <label
                htmlFor="app-menu-drawer"
                className="drawer-overlay"
              ></label>
              <div className="menu p-4 overflow-y-scroll hide-scroll-bar w-80 bg-neutral text-neutral-content uppercase tracking-wider text-sm flex-nowrap">
                <div className="flex gap-2 justify-start items-center">
                  <Link className="hidden lg:block pb-6" to="/">
                    <img src={logo} alt="App logo" className="w-48" />
                  </Link>
                </div>
                {/* sidebar content */}
                <div className="divide-y divide-white/20">
                  {navigationLinks.map((group, index) => {
                    const allowedLinks = group.filter((item) =>
                      item.access.includes(role)
                    );

                    return (
                      <div key={`${baseId}-${index}`}>
                        {navigationGroups[index]?.access?.includes(role) && (
                          <ul key={`${baseId}-${index}`} className="py-3">
                            {navigationGroups[index]?.title && (
                              <div
                                className="menu-title pb-2"
                                key={`${baseId}-${getId()}-1`}
                              >
                                {navigationGroups[index].title}
                              </div>
                            )}
                            <div key={`${baseId}-${getId()}-2`}>
                              {allowedLinks.map((item, index) => (
                                <li key={`${baseId}-${getId()}-${index}`}>
                                  <NavLink
                                    to={item.link}
                                    className="flex items-center gap-2"
                                    activeclassname="active"
                                  >
                                    {item.icon}
                                    <span>{item.label}</span>{" "}
                                  </NavLink>
                                </li>
                              ))}
                            </div>
                          </ul>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
