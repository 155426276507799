import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import clsx from "clsx";
import moment from "moment";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  CREATE_TIMESHEET,
  UPDATE_TIMESHEET,
  REMOVE_TIMESHEET,
} from "../../utils/mutations";
import {
  LIST_TEACHERS,
  TIMESHEET_DETAILS,
  LIST_TIMESHEETS,
} from "../../utils/queries";

export const TimesheetForm = (props) => {
  const { id, clear } = props;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  const { data: list } = useQuery(LIST_TEACHERS, {
    onError: (error) => {
      console.error(error);
      toast.error("Failed to load teachers");
    },
  });

  const [prefill] = useLazyQuery(TIMESHEET_DETAILS, {
    onCompleted: (data) => {
      if (data.timesheet) {
        const { teacher, start, end } = data.timesheet;
        setValue("timesheet.teacher", teacher.id);
        setValue("timesheet.start", moment(start).format("YYYY-MM-DDTHH:mm"));
        if (end)
          setValue("timesheet.end", moment(end).format("YYYY-MM-DDTHH:mm"));
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to load timesheet details");
    },
  });

  const [createTimesheet, { loading: creating }] = useMutation(
    CREATE_TIMESHEET,
    {
      onCompleted: (data) => {
        if (data.created) {
          reset();
          toast.success("Timesheet created successfully");
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to create timesheet");
      },
      refetchQueries: [{ query: LIST_TIMESHEETS }],
    }
  );

  const [updateTimesheet, { loading: updating }] = useMutation(
    UPDATE_TIMESHEET,
    {
      onCompleted: (data) => {
        if (data.updated) {
          reset();
          toast.success("Timesheet updated successfully");
          clear();
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to update timesheet");
      },
      refetchQueries: [{ query: LIST_TIMESHEETS }],
    }
  );

  const [removeTimesheet, { loading: removing }] = useMutation(
    REMOVE_TIMESHEET,
    {
      onCompleted: (data) => {
        if (data.removed) {
          reset();
          toast.success("Timesheet removed successfully");
          clear();
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to remove timesheet");
      },
      refetchQueries: [{ query: LIST_TIMESHEETS }],
    }
  );

  const onSubmit = (data) => {
    const { timesheet } = data;

    if (id) {
      const input = {
        id,
        start: timesheet.start,
        end: timesheet.end,
      };

      updateTimesheet({ variables: { input } });
    } else {
      const input = {
        teacherId: timesheet.teacher,
        start: timesheet.start,
        end: timesheet.end,
      };

      createTimesheet({ variables: { input } });
    }
  };

  const handleRemove = () => {
    window.confirm("Are you sure you want to remove this entry?") &&
      removeTimesheet({ variables: { timesheetId: id } });
  };

  const startTime = watch("timesheet.start");

  useEffect(() => {
    if (id) {
      reset();
      prefill({ variables: { timesheetId: id } });
    }
  }, [id, prefill, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        {/* select teacher */}
        <label className="label">
          <span className="label-text font-semibold">Teacher</span>
        </label>
        <select
          className={clsx(
            "select select-bordered w-full bg-white",
            errors?.timesheet?.teacher && "input-error"
          )}
          {...register("timesheet.teacher", { required: true })}
          disabled={id}
        >
          <option value="">Select Teacher</option>
          {list?.teachers.map((teacher) => (
            <option key={teacher.id} value={teacher.id}>
              {teacher.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        {/* start datetime */}
        <label className="label">
          <span className="label-text font-semibold">Start</span>
        </label>
        <input
          type="datetime-local"
          className={clsx(
            "input input-bordered w-full bg-white",
            errors?.timesheet?.start && "input-error"
          )}
          {...register("timesheet.start", { required: true })}
        />
      </div>
      <div>
        {/* end datetime */}
        <label className="label">
          <span className="label-text font-semibold">End</span>
        </label>
        <input
          type="datetime-local"
          min={startTime}
          className={clsx(
            "input input-bordered w-full bg-white",
            errors?.timesheet?.end && "input-error"
          )}
          {...register("timesheet.end", {
            required: true,
            validate: (value) => {
              // make sure end is greater than start and the date is same as start
              const start = new Date(startTime);
              const end = new Date(value);

              if (end <= start) {
                return "End time must be greater than start time";
              }

              return true;
            },
          })}
        />
      </div>
      <div className="pt-4 space-x-2">
        {/* submit */}
        <button
          type="submit"
          className={clsx(
            "btn btn-primary",
            (creating || updating) && "loading"
          )}
          disabled={!isValid}
        >
          {id ? "Update Entry" : "Create Entry"}
        </button>
        {id && (
          <button
            className={clsx("btn btn-outline btn-error", removing && "loading")}
            onClick={handleRemove}
            disabled={removing}
          >
            Remove Entry
          </button>
        )}
      </div>
    </form>
  );
};
