import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_ASSESSMENTS } from "../../utils/queries";
import { convertUnixTimestamp } from "../../utils/helpers";

export const AssessmentsList = (props) => {
  const { entity, id } = props;

  const { data } = useQuery(LIST_ASSESSMENTS, {
    variables: { entity, ref: id },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get assessments!");
    },
  });

  if (data?.list.length === 0) return <></>;

  return (
    <div className="bg-white px-4 rounded-lg mb-6 shadow-md divide-y">
      {data?.list?.map((assessment) => {
        return (
          <div
            className="flex flex-col md:flex-row gap-2 items-start py-4"
            key={assessment.id}
          >
            <div className="basis-full">
              <h4 className="font-semibold">
                Assessment from {assessment.from.name}
              </h4>
              <p className="whitespace-pre-line">{assessment.text}</p>
              <small>{convertUnixTimestamp(assessment.updated)}</small>
            </div>
          </div>
        );
      })}
    </div>
  );
};
