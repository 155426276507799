import { SESSIONS_LIST } from "../../utils/queries";
import { useQuery, useMutation } from "@apollo/client";
import { REMOVE_SESSION } from "../../utils/mutations";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  ChatBubbleLeftEllipsisIcon as ChatAltIcon,
  ExclamationTriangleIcon as ExclamationIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";

export const SessionsTable = (props) => {
  const { classId } = props;

  const notifyError = () => toast.error("Something went wrong!");

  const { data, refetch } = useQuery(SESSIONS_LIST, {
    variables: {
      classId,
    },
    onError: () => {
      notifyError();
    },
  });

  const [deleteSession] = useMutation(REMOVE_SESSION, {
    onCompleted: (data) => {
      if (data.removed) {
        toast.success("Session deleted!");
        refetch();
      }
    },
    onError: () => {
      notifyError();
    },
  });

  const handleRemove = (id) => {
    if (window.confirm(`Are you sure you want to delete this session?`)) {
      deleteSession({ variables: { sessionId: id } });
    }
  };

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Date</th>
              <th>Hall</th>
              <th>Teacher</th>
              <th className="text-center">Students</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {data &&
              data.list?.sessions &&
              data.list?.sessions?.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>
                  <td className="flex flex-row items-center gap-4">
                    <div>
                      <p className="font-bold">
                        {new Date(value.date).toLocaleDateString("en-AU", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </p>
                      <small>{value.time}</small>
                    </div>
                    {/* comments count badge */}
                    <div className="bg-gray-100 rounded-full px-2">
                      <p className="text-gray-600 text-sm flex items-center gap-1">
                        <ChatAltIcon className="h-4 w-4" />{" "}
                        <span className="font-semibold font-mono">
                          {value.commentsCount ? value.commentsCount : 0}
                        </span>
                      </p>
                    </div>
                  </td>
                  <td
                    className={clsx(
                      value.conflict && "text-error animate-pulse"
                    )}
                  >
                    {value.hall?.name ?? "-"}
                  </td>
                  <td>
                    <div className="flex flex-row gap-2 justify-center items-center">
                      {value.teacher?.name ?? "-"}
                      {value.status === "COMPLETE" &&
                        value.teacher &&
                        value.assessmentsCount === 0 && (
                          <ExclamationIcon className="h-5 w-5 text-warning" />
                        )}
                    </div>
                  </td>
                  <td className="text-center">{value.rosterCount}</td>
                  <td>
                    <span
                      className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                        value.status
                      )}`}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <Link
                        to={`/session/${value.id}`}
                        className="btn btn-xs btn-ghost"
                      >
                        details
                      </Link>
                      {value.rosterCount === 0 && (
                        <button
                          className="btn btn-xs btn-outline btn-error"
                          onClick={() => handleRemove(value.id)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function statusTagColor(str) {
  switch (str) {
    case "UPCOMING":
      return `bg-warning`;
    case "ONGOING":
      return `bg-info`;
    default:
      return `bg-base-300`;
  }
}
