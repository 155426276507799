import { useState } from "react";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_LOCATIONS } from "../../utils/queries";

export const LocationSelector = (props) => {
  const { label, selection, setSelection } = props;

  const [locations, setLocations] = useState([]);

  useQuery(LIST_LOCATIONS, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (data) => {
      if (data.locations && data.locations.length > 0) {
        setLocations(data.locations);
      }
    },
  });

  return (
    <div className="border-b-2 pb-8">
      {locations && locations.length > 0 && (
        <div className="w-full">
          <label className="label">
            <span className="label-text font-semibold">{label}</span>
          </label>
          <select
            className="select select-bordered w-full bg-white"
            value={selection ? selection.id : ""}
            onChange={(e) =>
              setSelection(
                locations.find((location) => location.id === e.target.value)
              )
            }
          >
            <option value="" disabled>
              Select a location
            </option>
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
