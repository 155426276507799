import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { LIST_TEACHERS, TEACHER_CALENDAR } from "../../utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { getMonthGrid, getCalendarMonthRange } from "../../utils/helpers";
import { ClockIcon } from "@heroicons/react/24/solid";

export const TeachersCalendar = (props) => {
  const [months /*, setMonths*/] = useState(getCalendarMonthRange());

  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(
    months.findIndex((month) => month.isCurrent)
  );
  const [selectedDay, setSelectedDay] = useState(null);

  const [days, setDays] = useState([]);
  const [events, setEvents] = useState([]);

  useQuery(LIST_TEACHERS, {
    onCompleted: (data) => {
      setTeachers(data.teachers);
    },
    onError: () => {
      toast.error("Something went wrong!");
    },
  });

  const [getTeacherCalendar] = useLazyQuery(TEACHER_CALENDAR, {
    onCompleted: (data) => {
      setEvents(data.events);
    },
    onError: () => {
      toast.error("Something went wrong!");
    },
    fetchPolicy: "network-only",
  });

  const formatDate = (date) => {
    // change date from yyyy-mm-dd to dd-mm-yyyy
    return date.split("-").reverse().join("-");
  };

  const eventsForTheDay = (date) => {
    return events.filter((event) => formatDate(event.date) === date);
  };

  useEffect(() => {
    const month = months[selectedMonth].month;
    const year = months[selectedMonth].year;

    setDays(getMonthGrid(month, year));
  }, [selectedMonth, months]);

  useEffect(() => {
    if (selectedTeacher) {
      getTeacherCalendar({
        variables: {
          teacherId: selectedTeacher,
          month: months[selectedMonth].month * 1,
          year: months[selectedMonth].year * 1,
        },
      });
    }
  }, [selectedTeacher, selectedMonth, months, getTeacherCalendar]);

  return (
    <div className="p-4 rounded-xl shadow-md bg-white flex flex-col gap-6">
      {/* header */}
      <div className="flex flex-col md:flex-row items-center justify-between">
        {/* select month */}
        <div className="flex flex-row items-center space-x-2 py-4 order-2 md:order-1">
          {
            <button
              className="btn btn-xs btn-outline"
              onClick={() => setSelectedMonth(selectedMonth - 1)}
              disabled={selectedMonth === 0}
            >
              &lsaquo;
            </button>
          }
          <span className="font-bold text-lg px-2">
            {months[selectedMonth].label}
          </span>
          {
            <button
              className="btn btn-xs btn-outline"
              onClick={() => setSelectedMonth(selectedMonth + 1)}
              disabled={selectedMonth === months.length - 1}
            >
              &rsaquo;
            </button>
          }
        </div>
        {/* select teacher */}
        <div className="w-full md:w-fit order-1 md:order-2">
          <select
            className="select select-bordered w-full bg-white"
            onChange={(e) => {
              setSelectedTeacher(e.target.value);
            }}
          >
            <option value="">Select teacher</option>
            {teachers.map((teacher) => (
              <option key={teacher.id} value={teacher.id}>
                {teacher.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* calendar */}
      <div className="shadow border lg:flex lg:flex-auto lg:flex-col rounded-lg overflow-clip">
        {/* weekdays labels */}
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
        </div>

        {days.length > 0 && (
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="isolate grid w-full grid-cols-7 gap-px">
              {days.map((day) => (
                <button
                  key={day.date}
                  type="button"
                  className={clsx(
                    day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                    (day.date === selectedDay || day.isToday) &&
                      "font-semibold",
                    day.date === selectedDay && "text-white",
                    !day.date === selectedDay && day.isToday && "text-primary",
                    !day.date === selectedDay &&
                      day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-900",
                    !day.date === selectedDay &&
                      !day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-500",
                    "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
                  )}
                  disabled={!day.isCurrentMonth}
                  onClick={() => setSelectedDay(day.date)}
                >
                  <time
                    dateTime={day.date}
                    className={clsx(
                      day.date === selectedDay &&
                        "flex h-6 w-6 items-center justify-center rounded-full",
                      day.date === selectedDay && day.isToday && "bg-primary",
                      day.date === selectedDay && !day.isToday && "bg-gray-900",
                      "ml-auto"
                    )}
                  >
                    {day.date.split("-")[0]}
                  </time>
                  <span className="sr-only">
                    {eventsForTheDay(day.date).length} events
                  </span>
                  {eventsForTheDay(day.date).length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {eventsForTheDay(day.date).map((event) => (
                        <span
                          key={event.id}
                          className={clsx(
                            "mx-0.5 mb-1 h-1.5 w-1.5 rounded-full",
                            event.teaching ? "bg-gray-800" : "bg-gray-400"
                          )}
                        />
                      ))}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {selectedDay && eventsForTheDay(selectedDay).length > 0 && (
        <div className="px-4 py-10 sm:px-6">
          <small className="block pb-2">
            {eventsForTheDay(selectedDay).length} sessions on {selectedDay}
          </small>
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {eventsForTheDay(selectedDay).map((event) => (
              <li
                key={event.id}
                className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50 flex-col md:flex-row justify-start md:justify-between items-start md:items-center gap-2"
              >
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">
                    {event.title ?? "-"} ({event.name})
                  </p>
                  <time
                    dateTime={event.datetime}
                    className="mt-2 flex items-center text-gray-700"
                  >
                    <ClockIcon
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {event.time} ({event.teaching ? "Teaching" : "Assisting"})
                  </time>
                </div>
                <Link
                  to={`/session/${event.id}`}
                  className="flex-none rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                >
                  View session
                </Link>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};
