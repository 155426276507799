import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_CLASS_SCHEDULE } from "../../utils/mutations";
import { CLASS_INFO, SESSIONS_LIST } from "../../utils/queries";

export const ClassScheduleForm = (props) => {
  const { id, title } = props;

  const [message, setMessage] = useState("");
  const [submission, setSubmission] = useState(null);

  const { register, handleSubmit, reset } = useForm();

  const [updateClassSchedule, { loading }] = useMutation(
    UPDATE_CLASS_SCHEDULE,
    {
      refetchQueries: [
        { query: CLASS_INFO, variables: { classId: id } },
        { query: SESSIONS_LIST, variables: { classId: id } },
      ],
      onCompleted: (info) => {
        if (info.updated) {
          setMessage("Class schedule updated successfully!");
          setSubmission(info);
        }
      },
      onError: (err) => {
        setMessage("Something went wrong!");
        setSubmission(err);
      },
    }
  );

  const onSubmit = (data) => {
    const { day, from, to } = data.classSchedule;
    updateClassSchedule({
      variables: {
        input: {
          id,
          day,
          from,
          to,
        },
      },
    });
  };

  return (
    <div>
      {!submission ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p className="font-bold text-xl pb-2 border-b">{title}</p>
          </div>
          {/* day */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Day</span>
            </label>
            <select
              name="day"
              defaultValue="Select"
              className="select select-bordered w-full bg-white"
              {...register("classSchedule.day")}
              required
            >
              <option disabled>Select</option>
              <option value="Sunday">Sunday</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
            </select>
          </div>
          {/* from - to */}
          <div className="flex justify-between space-x-3 w-full">
            <div className="w-full">
              <div>
                <label className="label">
                  <span className="label-text font-semibold">From</span>
                </label>
                <input
                  type="time"
                  step="900"
                  name="from"
                  placeholder="Start time"
                  className="input input-bordered w-full max-w-md bg-white"
                  {...register("classSchedule.from")}
                  required
                />
              </div>
            </div>
            <div className="w-full">
              <div>
                <label className="label">
                  <span className="label-text font-semibold">To</span>
                </label>
                <input
                  type="time"
                  step="900"
                  name="to"
                  placeholder="Start time"
                  className="input input-bordered w-full max-w-md bg-white"
                  {...register("classSchedule.to")}
                  required
                />
              </div>
            </div>
          </div>
          {/* submit */}
          <div className="pt-4 space-x-4">
            <button type="submit" className={loading ? "btn loading" : "btn"}>
              Update Schedule
            </button>
          </div>
        </form>
      ) : (
        <>
          <p
            className={`font-bold text-xl mb-6 ${
              submission === "error" ? "text-error" : ""
            } ${submission === "success" ? "text-success" : ""}`}
          >
            {message}
          </p>
          <label
            htmlFor="update-modal"
            className="btn btn-outline"
            onClick={() => {
              setSubmission(null);
              setMessage("");
              reset();
            }}
          >
            Close
          </label>
        </>
      )}
    </div>
  );
};
