import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  LIST_GROUPS,
  LIST_LOCATIONS,
  LIST_WORKSHOPS,
  WORKSHOP_INFO,
} from "../../utils/queries";
import { CREATE_WORKSHOP, UPDATE_WORKSHOP } from "../../utils/mutations";
import toast from "react-hot-toast";
import clsx from "clsx";

export const WorkshopForm = (props) => {
  const { id } = props;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const fromValue = useWatch({
    control,
    name: "workshop.from",
  });

  const { data: groupsData } = useQuery(LIST_GROUPS);
  const { data: locationsData } = useQuery(LIST_LOCATIONS);

  const [prefillData] = useLazyQuery(WORKSHOP_INFO, {
    variables: { workshopId: id },
    onCompleted: (data) => {
      if (data.workshop) {
        const { name, description, price, from, to, groups, location } =
          data.workshop;

        setValue("workshop.name", name);
        setValue("workshop.description", description);
        setValue("workshop.price", price / 100);
        setValue("workshop.from", from);
        if (to) setValue("workshop.to", to);
        if (groupsData?.groups?.length > 0) {
          setValue(
            "workshop.groups",
            groups.map((group) => group.id)
          );
        }
        if (locationsData?.locations?.length > 0) {
          setValue("workshop.location", location.id);
        }
      }
    },
    onError: () => {
      notifyError();
    },
  });

  const [createWorkshop, { loading: creating }] = useMutation(CREATE_WORKSHOP, {
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/schedule/workshops`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refetchQueries: [{ query: LIST_WORKSHOPS }],
  });

  const [updateWorkshop, { loading: updating }] = useMutation(UPDATE_WORKSHOP, {
    onCompleted: () => {
      setLoader(false);
      notifySuccess();
      navigate(`/schedule/workshops`);
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refetchQueries: [
      { query: LIST_WORKSHOPS },
      { query: WORKSHOP_INFO, variables: { workshopId: id } },
    ],
  });

  const onSubmit = (data) => {
    const { name, description, price, from, to, groups, location } =
      data.workshop;

    if (id) {
      // update
      updateWorkshop({
        variables: {
          input: {
            id,
            name,
            description,
            price: parseInt(price * 100),
            from,
            to,
            groups,
            location,
          },
        },
      });
    } else {
      // create
      createWorkshop({
        variables: {
          input: {
            name,
            description,
            price: parseInt(price * 100),
            from,
            to,
            groups,
            location,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id && groupsData && locationsData) prefillData();
  }, [id, prefillData, groupsData, locationsData]);

  useEffect(() => {
    if (creating || updating) setLoader(true);
  }, [creating, updating]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Name */}
        <div className="max-w-md">
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Workshop Name"
            className={clsx(
              "input input-bordered w-full bg-white",
              errors?.workshop?.name && "input-error"
            )}
            {...register("workshop.name", { required: true })}
          />
        </div>
        {/* description */}
        <div className="max-w-md">
          <label htmlFor="description" className="label">
            <span className="label-text font-semibold">Description</span>
          </label>
          <textarea
            name="description"
            placeholder="Workshop Description"
            className={clsx(
              "textarea textarea-bordered w-full bg-white",
              errors?.workshop?.description && "textarea-error"
            )}
            {...register("workshop.description", { required: true })}
          />
        </div>
        {/* price */}
        <div className="max-w-md">
          <label htmlFor="price" className="label">
            <span className="label-text font-semibold">Price (in $)</span>
          </label>
          <input
            type="number"
            name="price"
            placeholder="Workshop Session Price"
            inputMode="numeric"
            step="0.01"
            className={clsx(
              "input input-bordered w-full bg-white",
              errors?.workshop?.price && "input-error"
            )}
            {...register("workshop.price", { required: true })}
          />
        </div>
        <div className="flex gap-4 max-w-md">
          {/* from (date) */}
          <div className="basis-1/2">
            <label htmlFor="from" className="label">
              <span className="label-text font-semibold">From</span>
            </label>
            <input
              type="date"
              name="from"
              className={clsx(
                "input input-bordered w-full bg-white",
                errors?.workshop?.from && "input-error"
              )}
              {...register("workshop.from", { required: true })}
            />
          </div>
          {/* to (date) */}
          <div className="basis-1/2">
            <label htmlFor="to" className="label">
              <span className="label-text font-semibold">To</span>
            </label>
            <input
              type="date"
              name="to"
              min={fromValue}
              disabled={!fromValue}
              className={clsx(
                "input input-bordered w-full bg-white",
                errors?.workshop?.to && "input-error"
              )}
              {...register("workshop.to")}
            />
          </div>
        </div>
        {/* groups (multi-select) */}
        <div className="max-w-md">
          <label htmlFor="groups" className="label">
            <span className="label-text font-semibold">Groups</span>
          </label>
          <select
            name="groups"
            multiple={true}
            className={clsx(
              "select select-bordered w-full bg-white",
              errors?.workshop?.groups && "select-error"
            )}
            {...register("workshop.groups", { required: true })}
          >
            {groupsData?.groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
        {/* location */}
        <div className="max-w-md">
          <label htmlFor="location" className="label">
            <span className="label-text font-semibold">Location</span>
          </label>
          <select
            name="location"
            className={clsx(
              "select select-bordered w-full bg-white",
              errors?.workshop?.location && "select-error"
            )}
            {...register("workshop.location", { required: true })}
          >
            <option value="">Select Location</option>
            {locationsData?.locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>

        {/* submit */}
        <div className="pt-4 space-x-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </>
  );
};
