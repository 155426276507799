import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CREATE_PRODUCT, UPDATE_PRODUCT } from "../../utils/mutations";
import {
  LIST_PRODUCTS,
  PRODUCT_INFO,
  LIST_GROUPS,
  LIST_PROGRAMS,
} from "../../utils/queries";
import clsx from "clsx";
import toast from "react-hot-toast";
import { ProductThumbnail } from "./ProductThumbnail";

export const ProductForm = (props) => {
  const { id } = props;

  const [groups, setGroups] = useState([]);
  const [programs, setPrograms] = useState([]);

  const navigate = useNavigate();

  const { register, setValue, handleSubmit, getValues } = useForm({
    mode: "onTouched",
  });

  useQuery(LIST_GROUPS, {
    onCompleted: (data) => {
      setGroups(data.groups);
    },
    onError: (error) => {
      toast.error("Error fetching groups!");
    },
  });

  useQuery(LIST_PROGRAMS, {
    onCompleted: (data) => {
      setPrograms(data.programs);
    },
    onError: (error) => {
      toast.error("Error fetching programs!");
    },
  });

  const [prefill] = useLazyQuery(PRODUCT_INFO, {
    variables: { productId: id },
    onCompleted: (data) => {
      setValue("product.name", data.product.name);
      setValue("product.thumbnail", data.product.thumbnail);
      setValue("product.description", data.product.description);
      setValue("product.cost", data.product.cost / 100);
      setValue("product.price", data.product.price / 100);
      setValue(
        "product.groups",
        data.product.groups.map((g) => g.id)
      );
      setValue(
        "product.programs",
        data.product.programs.map((p) => p.id)
      );
      setValue("product.status", data.product.status);
    },
    onError: (error) => {
      toast.error("Error fetching product info!");
    },
  });

  const [createProduct, { loading: creating }] = useMutation(CREATE_PRODUCT, {
    onCompleted: (data) => {
      if (data.created) {
        toast.success("Product created successfully!");
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error("Error creating product!");
    },
    refetchQueries: [{ query: LIST_PRODUCTS }],
  });

  const [updateProduct, { loading: updating }] = useMutation(UPDATE_PRODUCT, {
    onCompleted: (data) => {
      if (data.updated) {
        toast.success("Product updated successfully!");
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error("Error updating product!");
    },
    refetchQueries: [{ query: LIST_PRODUCTS }],
  });

  const onSubmit = (data) => {
    const { name, description, cost, price, groups, programs, status } =
      data.product;

    if (id) {
      updateProduct({
        variables: {
          input: {
            id: id,
            name,
            description,
            cost: cost * 100,
            price: price * 100,
            groups,
            programs,
            status,
          },
        },
      });
    } else {
      createProduct({
        variables: {
          input: {
            name,
            description,
            cost: cost * 100,
            price: price * 100,
            groups,
            programs,
            status,
          },
        },
      });
    }
  };

  const updateThumbnail = (thumbnail) => {
    setValue("product.thumbnail", thumbnail);

    updateProduct({
      variables: {
        input: {
          id: id,
          thumbnail,
        },
      },
    });
  };

  useEffect(() => {
    if (id) prefill();
  }, [id, prefill]);

  return (
    <div className="flex flex-col md:flex-row gap-6 md:gap-8">
      {id && (
        <div className="order-1 md:order-2 md:basis-1/2">
          <ProductThumbnail
            productId={id}
            thumbnail={getValues("product.thumbnail")}
            update={updateThumbnail}
          />
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="order-2 md:order-1 max-w-md w-full md:basis-1/2"
      >
        {/* name */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Product name"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("product.name")}
            required
          />
        </div>
        {/* description (text area) */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Description</span>
          </label>
          <textarea
            name="description"
            placeholder="Product description"
            className="textarea textarea-bordered w-full max-w-md bg-white"
            {...register("product.description")}
            required
          />
        </div>
        <div className="flex flex-row max-w-md gap-2">
          {/* sale price */}
          <div className="pt-4">
            <label className="label">
              <span className="label-text font-semibold">Sale Price ($)</span>
            </label>
            <input
              type="number"
              name="price"
              placeholder="Price"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("product.price")}
              required
              min={0.01}
              step={0.01}
            />
          </div>
          {/* cost price */}
          <div className="pt-4">
            <label className="label">
              <span className="label-text font-semibold">Cost Price ($)</span>
            </label>
            <input
              type="number"
              name="cost"
              placeholder="Cost"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("product.cost")}
              required
              min={0.01}
              step={0.01}
            />
          </div>
        </div>
        {/* groups (multi-select) */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Groups</span>
          </label>
          <select
            name="groups"
            multiple
            className="select select-bordered w-full max-w-md bg-white"
            {...register("product.groups")}
            required
          >
            {groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
        {/* programs (multi-select) */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Programs</span>
          </label>
          <select
            name="programs"
            multiple
            className="select select-bordered w-full max-w-md bg-white"
            {...register("product.programs")}
          >
            {programs.map((program) => (
              <option key={program.id} value={program.id}>
                {program.name}
              </option>
            ))}
          </select>
        </div>
        {/* status (select) */}
        <div className="pt-4">
          <label className="label">
            <span className="label-text font-semibold">Status</span>
          </label>
          <select
            name="status"
            className="select select-bordered w-full max-w-md bg-white"
            {...register("product.status")}
          >
            <option value="AVAILABLE">Available</option>
            <option value="UNAVAILABLE">Unavailable</option>
          </select>
        </div>
        {/* submit button */}
        <div className="pt-4">
          <button
            type="submit"
            className={clsx(creating || updating ? "btn loading" : "btn")}
          >
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
