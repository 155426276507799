import { useState, useEffect } from "react";
import clsx from "clsx";
import {
  formatMobile,
  convertUnixTimestamp,
  statusTagColor,
} from "../../utils/helpers";

export const OverviewSection = (props) => {
  const { data } = props;

  const [details, setDetails] = useState(null);
  const [addLine, setAddLine] = useState(false);

  useEffect(() => {
    if (data) {
      const props = {};

      const name = data?.name ?? null;
      const number = data?.number ? `#${data.number}` : null;

      props.id = data.id;
      props.label = name ?? number ?? "-";
      props.status = data.status;
      props.email = data.user?.email ?? undefined;
      props.phone = data.user?.phone
        ? formatMobile(data.user?.phone)
        : undefined;
      props.student = data.student?.name ?? undefined;
      props.term = data.term?.name ?? undefined;
      props.class = data.class?.name ?? undefined;
      props.workshop = data.workshop?.name ?? undefined;
      props.location =
        data.location?.name ?? data.class?.location?.name ?? undefined;
      props.hall = data.hall?.name ?? undefined;
      props.category = data.category ?? undefined;
      props.type = data.type ?? undefined;
      props.pack = data.pack ?? undefined;
      props.datetime = data.date ? convertUnixTimestamp(data.date) : undefined;
      props.date = data.date ?? undefined;
      props.time = data.time ?? undefined;
      props.from = data.from ?? undefined;
      props.venue = data.venue ?? undefined;
      props.purpose = data.purpose ?? undefined;
      props.dancers = data.dancers ?? undefined;
      props.requirements = data.requirements ?? undefined;
      props.experience = data.experience ?? undefined;
      props.availability = data.availability ?? undefined;
      props.notes = data.notes ?? undefined;

      if (
        props.purpose ||
        props.requirements ||
        props.experience ||
        props.availability ||
        props.notes
      )
        setAddLine(true);
      else setAddLine(false);

      setDetails(props);
    }
  }, [data]);

  return (
    <section>
      {details && (
        <div className="mt-4">
          <div className="label">
            <span className="label-text font-semibold">Overview</span>
          </div>
          <div className="relative bg-white p-6 shadow-sm rounded-xl">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-2">
              {/* label */}
              <div>
                <dt className="p-1 pb-0">
                  <span className="label-text font-semibold">Name</span>
                </dt>
                <dd className="p-1">
                  <span className="mt-1 text-md">{details.label}</span>
                </dd>
              </div>
              {/* status */}
              <div>
                <dt className="p-1 pb-0">
                  <span className="label-text font-semibold">Status</span>
                </dt>
                <dd className="p-1">
                  <span
                    className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                      details.status
                    )}`}
                  >
                    {details.status}
                  </span>
                </dd>
              </div>
              {/* email */}
              {details.email && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Email</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.email}</span>
                  </dd>
                </div>
              )}
              {/* phone */}
              {details.phone && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Phone</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.phone}</span>
                  </dd>
                </div>
              )}
              {/* student */}
              {details.student && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Student</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.student}</span>
                  </dd>
                </div>
              )}
              {/* term */}
              {details.term && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Term</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.term}</span>
                  </dd>
                </div>
              )}
              {/* class */}
              {details.class && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Class</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.class}</span>
                  </dd>
                </div>
              )}
              {/* workshop */}
              {details.workshop && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Workshop</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.workshop}</span>
                  </dd>
                </div>
              )}
              {/* location */}
              {details.location && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Location</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.location}</span>
                  </dd>
                </div>
              )}
              {/* hall */}
              {details.hall && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Hall</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.hall}</span>
                  </dd>
                </div>
              )}
              {/* category */}
              {details.category && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Category</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.category}</span>
                  </dd>
                </div>
              )}
              {/* type */}
              {details.type && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Type</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.type}</span>
                  </dd>
                </div>
              )}
              {/* pack */}
              {details.pack && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Pack</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.pack}</span>
                  </dd>
                </div>
              )}
              {/* dancers */}
              {details.dancers && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Dancers</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.dancers}</span>
                  </dd>
                </div>
              )}
              {/* datetime */}
              {details.datetime && details.datetime !== "Invalid date" && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">
                      Date & Time
                    </span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.datetime}</span>
                  </dd>
                </div>
              )}
              {/* date */}
              {details.date && details.datetime === "Invalid date" && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Date</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.date}</span>
                  </dd>
                </div>
              )}
              {/* time */}
              {details.time && details.datetime === "Invalid date" && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Time</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.time}</span>
                  </dd>
                </div>
              )}
              {/* from */}
              {details.from && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">From</span>
                  </dt>
                  <dd className="p-1">
                    <span className="mt-1 text-md">{details.from}</span>
                  </dd>
                </div>
              )}
              {/* venue */}
              {details.venue && (
                <div>
                  <dt className="p-1 pb-0">
                    <span className="label-text font-semibold">Venue</span>
                  </dt>
                  <dd className="p-1">
                    {details.venue.name && (
                      <span className="mt-1 text-md">{details.venue.name}</span>
                    )}
                    {details.venue.address && (
                      <span className="mt-1 text-md">
                        {" "}
                        {details.venue.address}
                      </span>
                    )}
                    {details.venue.suburb && (
                      <span className="mt-1 text-md">
                        {" "}
                        {details.venue.suburb}
                      </span>
                    )}
                    {details.venue.state && (
                      <span className="mt-1 text-md">
                        {" "}
                        {details.venue.state}
                      </span>
                    )}
                    {details.venue.postcode && (
                      <span className="mt-1 text-md">
                        {" "}
                        {details.venue.postcode}
                      </span>
                    )}
                  </dd>
                </div>
              )}
              {/* full width */}
              <div
                className={clsx(
                  "col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 grid grid-cols-2 md:grid-cols-4",
                  addLine && "border-t pt-2"
                )}
              >
                {/* purpose: wider */}
                {details.purpose && (
                  <div className="col-span-2">
                    <dt className="p-1 pb-0">
                      <span className="label-text font-semibold">Purpose</span>
                    </dt>
                    <dd className="p-1">
                      <span className="mt-1 text-md">{details.purpose}</span>
                    </dd>
                  </div>
                )}
                {/* requirements: wider */}
                {details.requirements && (
                  <div className="col-span-2">
                    <dt className="p-1 pb-0">
                      <span className="label-text font-semibold">
                        Requirements
                      </span>
                    </dt>
                    <dd className="p-1">
                      <span className="mt-1 text-md">
                        {details.requirements}
                      </span>
                    </dd>
                  </div>
                )}
                {/* experience: wider */}
                {details.experience && (
                  <div className="col-span-2">
                    <dt className="p-1 pb-0">
                      <span className="label-text font-semibold">
                        Experience
                      </span>
                    </dt>
                    <dd className="p-1">
                      <span className="mt-1 text-md">{details.experience}</span>
                    </dd>
                  </div>
                )}
                {/* availability: wider */}
                {details.availability && (
                  <div className="col-span-2">
                    <dt className="p-1 pb-0">
                      <span className="label-text font-semibold">
                        Availability
                      </span>
                    </dt>
                    <dd className="p-1">
                      <span className="mt-1 text-md">
                        {details.availability}
                      </span>
                    </dd>
                  </div>
                )}
                {/* notes: wider */}
                {details.notes && (
                  <div className="col-span-2">
                    <dt className="p-1 pb-0">
                      <span className="label-text font-semibold">Notes</span>
                    </dt>
                    <dd className="p-1">
                      <span className="mt-1 text-md">{details.notes}</span>
                    </dd>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
