import { useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useDebounce } from "@uidotdev/usehooks";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { OverviewSection } from "../library/section/Overview";
import { TransactionSection } from "../library/section/Transaction";
import { AcceptForm } from "../library/form/Accept";
import { LoadingLayout } from "../layout/Loading";
import {
  formatter,
  paymentTagColor,
  statusTagColor,
  convertUnixTimestamp,
} from "../utils/helpers";
import { PERFORMANCE_INFO, PERFORMANCES_LIST } from "../utils/queries";
import { DRAFT_ENTITY, REJECT_ENTITY } from "../utils/mutations";
import { CustomerSelector } from "../library/selector/Customer";

export default function Performances(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    if (param === "draft") return <PerformanceDraft />;
    return (
      <PerformanceDetail id={param} isAdmin={isAdmin} isManager={isManager} />
    );
  } else {
    return <PerformancesList isAdmin={isAdmin} isManager={isManager} />;
  }
}

function PerformanceDraft(props) {
  const [customer, setCustomer] = useState(null);

  const navigate = useNavigate();

  return (
    <UserLayout title={`Draft Performance`}>
      <SectionHead heading="Draft Performance">
        <button
          className="btn btn-outline"
          onClick={() => navigate("/performance")}
        >
          List Performances
        </button>
      </SectionHead>
      <section className="flex flex-col gap-4 py-2">
        {/* customer */}
        <CustomerSelector selection={customer} setSelection={setCustomer} />
        {/* form */}
        {customer && (
          <PerformanceForm
            customer={customer}
            clear={() => {
              setCustomer(null);
            }}
          />
        )}
      </section>
    </UserLayout>
  );
}

function PerformanceForm(props) {
  const { customer, clear } = props;

  const [amount, setAmount] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onTouched",
  });

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        toast.success("Training added successfully");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    refetchQueries: [{ query: PERFORMANCES_LIST }],
  });

  const onSubmit = (data) => {
    const { performance } = data;

    const input = {
      type: "PERFORMANCE",
      performance: {
        userId: customer.id,
        requirements: performance.requirements,
        dancers: parseInt(performance.dancers),
        date: performance.date,
        time: performance.time,
        venue: {
          name: performance.venue.name,
          address: performance.venue.address,
          suburb: performance.venue.suburb,
          postcode: performance.venue.postcode,
          state: performance.venue.state,
        },
        notes: performance.notes,
        method: performance.method,
        ref: performance.ref,
        total: parseInt(amount),
      },
    };

    draftEntity({ variables: { ...input } });
  };

  // watch input value for changes
  const showReference = watch("performance.method") === "MANUAL";

  return (
    <div className="relative w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-4"
      >
        <div className="flex flex-col gap-4">
          {/* text purpose */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">
                Performance Requirements
              </span>
            </label>
            <textarea
              className="textarea h-24 textarea-bordered bg-white w-full"
              placeholder="Enter requirements for the performance (dancers, props, etc.)"
              {...register("performance.requirements", {
                required: true,
              })}
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            {/* number of dancers */}
            <div className="basis-1/3">
              <label className="label">
                <span className="label-text font-semibold">
                  Number of Dancers
                </span>
              </label>
              <input
                type="number"
                className="input input-bordered bg-white w-full"
                placeholder="Enter the number of dancers required for the performance"
                defaultValue={2}
                min={2}
                {...register("performance.dancers", {
                  required: true,
                  min: 2,
                })}
              />
            </div>
            {/* date */}
            <div className="basis-1/3">
              <label className="label">
                <span className="label-text font-semibold">Event Date</span>
              </label>
              <input
                type="date"
                className="input input-bordered bg-white w-full"
                {...register("performance.date", {
                  required: true,
                })}
              />
            </div>
            {/* time */}
            <div className="basis-1/3">
              <label className="label">
                <span className="label-text font-semibold">Event Time</span>
              </label>
              <input
                type="time"
                className="input input-bordered bg-white w-full"
                {...register("performance.time", {
                  required: true,
                })}
              />
            </div>
          </div>
          {/* vennue inputs */}
          <div>
            {/* input venue name */}
            <label className="label">
              <span className="label-text font-semibold">Venue Name</span>
            </label>
            <input
              type="text"
              className="input input-bordered bg-white w-full"
              placeholder="Enter the name of the venue for the event (optional)"
              {...register("performance.venue.name")}
            />
            {/* venue street address */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">
                  Venue Street Address
                </span>
              </label>
              <input
                type="text"
                className="input input-bordered bg-white w-full"
                placeholder="Enter the address of the event"
                {...register("performance.venue.address", {
                  required: true,
                })}
              />
            </div>
            <div className="flex gap-4">
              {/* venue suburb */}
              <div className="basis-1/2">
                <label className="label">
                  <span className="label-text font-semibold">Venue Suburb</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered bg-white w-full"
                  placeholder="Enter the suburb of the event"
                  {...register("performance.venue.suburb", {
                    required: true,
                  })}
                />
              </div>
              {/* venue postcode */}
              <div className="basis-1/2">
                <label className="label">
                  <span className="label-text font-semibold">
                    Venue Postcode
                  </span>
                </label>
                <input
                  type="text"
                  className="input input-bordered bg-white w-full"
                  placeholder="Enter the postcode of the event"
                  minLength={4}
                  maxLength={4}
                  {...register("performance.venue.postcode", {
                    required: true,
                  })}
                />
              </div>
            </div>
            {/* vennue state (AU States Only) */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Venue State</span>
              </label>
              <select
                className="select select-bordered bg-white w-full"
                defaultValue={"NSW"}
                {...register("performance.venue.state", {
                  required: true,
                })}
              >
                <option value="">Select State</option>
                <option value="ACT" disabled>
                  Australian Capital Territory
                </option>
                <option value="NSW">New South Wales</option>
                <option value="NT" disabled>
                  Northern Territory
                </option>
                <option value="QLD" disabled>
                  Queensland
                </option>
                <option value="SA" disabled>
                  South Australia
                </option>
                <option value="TAS" disabled>
                  Tasmania
                </option>
                <option value="VIC" disabled>
                  Victoria
                </option>
                <option value="WA" disabled>
                  Western Australia
                </option>
              </select>
            </div>
          </div>
          {/* text notes */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Additional Notes</span>
            </label>
            <textarea
              className="textarea h-24 textarea-bordered bg-white w-full"
              placeholder="Enter any additional notes or contact info for the performance"
              {...register("performance.notes")}
            ></textarea>
          </div>
          {/* payment info */}
          <div className="flex flex-col sm:flex-row gap-4">
            {/* amount */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">Amount ($)</span>
              </label>
              <input
                type="number"
                name="amount"
                placeholder="Amount"
                className="input input-bordered w-full bg-white"
                {...register("performance.amount", {
                  required: true,
                  min: 0,
                  validate: (value) => {
                    return !!value;
                  },
                })}
                onChange={(e) => setAmount(parseInt(e.target.value * 100))}
                defaultValue={0}
                min={0}
                step={1}
              />
            </div>
            {/* method (select: MANUAL or STRIPE) */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Payment Method</span>
              </label>
              <select
                className="select select-bordered w-full bg-white"
                {...register("performance.method", {
                  required: true,
                  validate: (value) => {
                    return !!value;
                  },
                })}
                defaultValue={""}
              >
                <option value="">Select a payment method</option>
                <option value="MANUAL">
                  Manual / Bank Transfer / EFTPOS / Other
                </option>
                <option value="STRIPE" disabled={amount <= 0}>
                  Online (customer will see this under pending and pay online)
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {/* ref (input) */}
            {showReference && (
              <div>
                <label className="label">
                  <span className="label-text font-semibold">
                    Payment Reference
                  </span>
                </label>
                <input
                  type="text"
                  name="ref"
                  placeholder="Reference Number"
                  className="input input-bordered w-full bg-white"
                  {...register("performance.ref", {
                    required: true,
                  })}
                />
              </div>
            )}
          </div>
        </div>
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary", loading && "loading")}
          disabled={!isValid || loading}
        >
          Add Performance {formatter.format(amount / 100)}
        </button>
      </form>
    </div>
  );
}

function PerformancesList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [name, setName] = useState("");
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [performances, setPerformances] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(PERFORMANCES_LIST, {
    onCompleted: (data) => {
      setPerformances(data.list.performances);
      setTotal(data.list.total);
      // based on the total and limit, calculate the max page
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      from: !!from ? from : null,
      to: !!to ? to : null,
      status: [
        "REQUESTED",
        "DRAFT",
        "APPROVED",
        "DECLINED",
        "REFUNDED",
        "COMPLETED",
      ],
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getPerformancesToDownload, { data: download, loading: downloading }] =
    useLazyQuery(PERFORMANCES_LIST, {
      onCompleted: (res) => {
        if (res.list.performances.length > 0) {
          const formatted = res.list.performances.map((performance, i) => ({
            "No.": i + 1,
            Name: performance.name,
            Date: convertUnixTimestamp(performance.date),
            Requirements: performance.requirements ?? "-",
            Dancers: performance.dancers ?? 0,
            "Vennue Name": performance.venue?.name ?? "-",
            Street: performance.venue?.address ?? "-",
            Suburb: performance.venue?.suburb ?? "-",
            Postcode: performance.venue?.postcode ?? "-",
            State: performance.venue?.state ?? "-",
            Notes: performance.notes ?? "-",
            Email: performance.user?.email,
            Phone: performance.user?.phone ?? "-",
            Method: performance.payment.method ?? "UNKNOWN",
            ...(isAdmin && {
              Paid: formatter.format(performance.total / 100),
              Discount: formatter.format(
                performance.amounts?.discount / 100 ?? 0
              ),
              Refunded: formatter.format(
                performance.amounts?.refunded / 100 ?? 0
              ),
              "Payment Ref.": performance.payment?.reference ?? "-",
            }),
            Status: performance.status,
            Created: convertUnixTimestamp(performance.created),
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getPerformancesToDownload({
      variables: {
        name: !!debouncedName ? debouncedName : null,
        from: !!from ? from : null,
        to: !!to ? to : null,
        status: [
          "REQUESTED",
          "DRAFT",
          "APPROVED",
          "DECLINED",
          "REFUNDED",
          "COMPLETED",
        ],
        page: 0,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title="Performance List">
      <section>
        <SectionHead heading="Performance List">
          {/* Back to Orders */}
          <button
            className="btn btn-outline"
            onClick={() => navigate("/performance/draft")}
          >
            Draft Performance
          </button>
        </SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* table */}
          <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
            {/* table header */}
            <div className="flex w-full px-4 py-4 border-b-2">
              <div className="flex flex-row justify-between items-center w-full gap-4">
                {/* filters */}
                <div className="basis-3/4 flex flex-row gap-4">
                  {/* name search */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  {/* date from */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">
                        Date From
                      </span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setFrom(e.target.value);
                        refetch();
                      }}
                      value={from}
                      max={to}
                    />
                  </div>
                  {/* date to */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Date To</span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setTo(e.target.value);
                        refetch();
                      }}
                      value={to}
                      min={from}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2">
                  {(isAdmin || isManager) && (
                    <>
                      {/* download csv */}
                      {download &&
                      download?.list?.performances.length === data.length ? (
                        <CSVLink
                          className="btn btn-ghost"
                          data={data}
                          filename={`performances.csv`}
                          onClick={(e) => {
                            setData([]);
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      ) : (
                        <button
                          className={clsx(
                            "btn btn-ghost",
                            downloading && "loading"
                          )}
                          onClick={getDownloadData}
                        >
                          Generate CSV
                        </button>
                      )}
                    </>
                  )}
                  {/* reset button */}
                  <button
                    className="btn btn-ghost"
                    onClick={() => {
                      setName("");
                      setFrom(dateMonthAgo);
                      setTo(dateTomorrow);
                      setData([]);
                      refetch();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table content */}
            {!loading ? (
              <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
                {/* head */}
                <thead className="text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Method</th>
                    <th>Paid</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {/* body */}
                {performances.length > 0 ? (
                  <tbody className="divide-y-2">
                    {performances.map((value, index) => (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex flex-row gap-4 items-center">
                          <div>
                            <p className="font-bold">{value.name}</p>
                            <p>
                              <span className="font-medium">
                                {value.venue?.suburb ?? "-"},{" "}
                                {value.venue?.postcode ?? "-"}
                              </span>
                            </p>
                            <p>
                              <span className="text-sm text-gray-600">
                                {convertUnixTimestamp(value.created)}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>{convertUnixTimestamp(value.date)}</td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                              value.payment.method
                            )}`}
                          >
                            {formatLabel(value.payment.method ?? "UNKNOWN")}
                          </span>
                        </td>
                        <td className="tracking-wider font-mono">
                          {formatter.format(value.total / 100)}
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/performance/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="flex flex-col items-center justify-center py-8">
                          <p className="text-gray-400 text-lg">
                            No performances found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <>
                {/* loading skeleton */}
                <LoadingLayout type="list" />
              </>
            )}
            {/* table footer */}
            <div className="flex w-full px-4 py-4 border-t-2">
              <div className="flex flex-row justify-between items-center w-full">
                <div>
                  <p>
                    Showing{" "}
                    <span className="font-bold">{page * limit + 1}</span> -{" "}
                    <span className="font-bold">
                      {page * limit + performances.length}
                    </span>{" "}
                    of <span className="font-bold">{total}</span> results
                  </p>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === 0 || maxPage <= 1}
                    onClick={() => prevPage()}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === maxPage - 1 || maxPage <= 1}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}

function PerformanceDetail(props) {
  const { id, isAdmin } = props;

  const [canDecide, setCanDecide] = useState(false);

  const navigate = useNavigate();

  const { data, refetch } = useQuery(PERFORMANCE_INFO, {
    variables: { performanceId: id },
    onCompleted: (information) => {
      if (information.performance.status === "REQUESTED") {
        setCanDecide(true);
      } else {
        setCanDecide(false);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get details!");
    },
    fetchPolicy: "network-only",
  });

  const [rejectRequest, { loading: updating }] = useMutation(REJECT_ENTITY, {
    onCompleted: () => {
      toast.success("Request rejected successfully!");
      refetch();
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
  });

  const handleRejectRequest = () => {
    window.confirm("Are you sure you want to reject this request?") &&
      rejectRequest({
        variables: {
          type: "PERFORMANCE",
          entityId: id,
        },
      });
  };

  return (
    <UserLayout title={`Performance Details`}>
      <section>
        <SectionHead heading="Performance Details">
          {/* reject request */}
          {canDecide && (
            <button
              className={clsx(
                "btn btn-outline btn-error",
                updating && "loading"
              )}
              onClick={handleRejectRequest}
            >
              Reject Request
            </button>
          )}
          {/* Back to Passes */}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </section>
      {/* overview section */}
      {data && data.performance && <OverviewSection data={data.performance} />}
      {/* accept form */}
      {canDecide && <AcceptForm id={id} type="PERFORMANCE" refetch={refetch} />}
      {/* transaction section */}
      {data && data.performance && data.performance.status !== "REQUESTED" && (
        <TransactionSection
          id={id}
          paymentRefundAllowed={
            data.performance.status === "APPROVED" && isAdmin
          }
        />
      )}
    </UserLayout>
  );
}
