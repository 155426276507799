import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_USER, UPDATE_USER } from "../../utils/mutations";
import { ADMIN_USER_INFO, LIST_ADMIN_USERS } from "../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const AdminUserForm = (props) => {
  const { id } = props;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState(null);
  const { register, setValue, handleSubmit } = useForm();

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [prefill, { data: prefillData, error: prefillError }] = useLazyQuery(
    ADMIN_USER_INFO,
    { variables: { userId: id } }
  );
  const [createUser, { loading, data, error }] = useMutation(CREATE_USER, {
    refetchQueries: [{ query: LIST_ADMIN_USERS }],
  });
  const [
    updateUser,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: ADMIN_USER_INFO, variables: { userId: id } }],
  });

  const onSubmit = (data) => {
    const { email, role } = data.admin;
    if (id) {
      updateUser({ variables: { id, email, role } });
    } else {
      createUser({ variables: { email, role } });
    }
  };

  useEffect(() => {
    if (id) {
      prefill();
    }
  }, [id, prefill]);

  useEffect(() => {
    if (prefillData) {
      setInfo({
        email: prefillData.user.email,
        role: prefillData.user.role,
      });
    }
  }, [prefillData]);

  useEffect(() => {
    if (prefillError) notifyError();
  }, [prefillError]);

  useEffect(() => {
    if (info) {
      setValue("admin", info);
    }
  }, [info, setValue]);

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      navigate(`/config/users`);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  useEffect(() => {
    if (updateLoading) setLoader(true);
  }, [updateLoading]);

  useEffect(() => {
    if (updateData) {
      setLoader(false);
      notifySuccess();
    }
  }, [updateData]);

  useEffect(() => {
    if (updateError) {
      setLoader(false);
      notifyError();
    }
  }, [updateError]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* email */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Email</span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="User email"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("admin.email")}
              required
            />
          </div>
        </div>
        {/* role */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Role</span>
          </label>
          <select
            name="role"
            defaultValue="Select"
            className="select select-bordered w-full max-w-md bg-white"
            {...register("admin.role")}
            required
          >
            <option disabled>Select</option>
            <option value="ADMIN">Admin</option>
            <option value="MANAGER">Manager</option>
            <option value="RECEPTION">Reception</option>
          </select>
        </div>
        {/* submit */}
        <div className="pt-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            {id ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
