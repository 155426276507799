import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useDebounce } from "@uidotdev/usehooks";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { OverviewSection } from "../library/section/Overview";
import { CustomerSelector } from "../library/selector/Customer";
import { LocationSelector } from "../library/selector/Location";
import { HallSelector } from "../library/selector/Hall";
import { ScheduleSelector } from "../library/selector/Schedule";
import { ScheduleList } from "../library/list/Schedule";
import { SessionsList } from "../library/list/Sessions";
import { TransactionSection } from "../library/section/Transaction";
import { AcceptForm } from "../library/form/Accept";
import { LoadingLayout } from "../layout/Loading";
import {
  formatter,
  paymentTagColor,
  statusTagColor,
  convertUnixTimestamp,
} from "../utils/helpers";
import { TRAINING_INFO, TRAININGS_LIST } from "../utils/queries";
import { DRAFT_ENTITY, REJECT_ENTITY } from "../utils/mutations";

export default function Trainings(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    if (param === "draft") return <TrainingDraft />;
    return (
      <TrainingDetail id={param} isAdmin={isAdmin} isManager={isManager} />
    );
  } else {
    return <TrainingsList isAdmin={isAdmin} isManager={isManager} />;
  }
}

function TrainingDraft(props) {
  const [customer, setCustomer] = useState(null);
  const [location, setLocation] = useState(null);
  const [hall, setHall] = useState(null);
  const [schedule, setSchedule] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!customer) {
      setLocation(null);
      setHall(null);
      setSchedule([]);
    }
  }, [customer]);

  return (
    <UserLayout title={`Draft Training`}>
      <SectionHead heading="Draft Training">
        <button
          className="btn btn-outline"
          onClick={() => navigate("/training")}
        >
          Back
        </button>
      </SectionHead>
      <section className="flex flex-col gap-4 py-2">
        {/* customer */}
        <CustomerSelector selection={customer} setSelection={setCustomer} />
        {/* location */}
        {customer && (
          <LocationSelector
            label="Select preferred location"
            selection={location}
            setSelection={setLocation}
          />
        )}
        {/* hall */}
        {location && (
          <HallSelector
            label="Select a hall"
            location={location}
            selection={hall}
            setSelection={setHall}
          />
        )}
        {/* schedule */}
        {location && hall && (
          <ScheduleSelector
            module="training"
            location={location}
            hall={hall}
            selection={schedule}
            setSelection={setSchedule}
          />
        )}
        {/* form */}
        {customer && location && schedule.length > 0 && (
          <TrainingForm
            customer={customer}
            location={location}
            hall={hall}
            schedule={schedule}
            clear={() => {
              setCustomer(null);
              setLocation(null);
              setHall(null);
              setSchedule([]);
            }}
          />
        )}
      </section>
    </UserLayout>
  );
}

function TrainingForm(props) {
  const { customer, location, hall, schedule, clear } = props;

  const [amount, setAmount] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onTouched",
  });

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        toast.success("Training added successfully");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    refetchQueries: [{ query: TRAININGS_LIST }],
  });

  const onSubmit = (data) => {
    const { training } = data;

    const input = {
      type: "TRAINING",
      training: {
        userId: customer.id,
        locationId: location.id,
        hallId: hall.id,
        schedule,
        type: training.type,
        pack: parseInt(training.packSize),
        purpose: training.purpose,
        experience: training.experience,
        availability: training.availability,
        method: training.method,
        ref: training.ref,
        total: parseInt(amount),
      },
    };

    draftEntity({ variables: { ...input } });
  };

  // watch input value for discount
  const showReference = watch("training.method") === "MANUAL";
  const trainingType = watch("training.type");

  return (
    <div className="relative w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-4"
      >
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            {/* select type */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">Select Type</span>
              </label>
              <select
                className="select select-bordered bg-white w-full"
                {...register("training.type", {
                  required: true,
                })}
              >
                <option value="">Select type</option>
                <option value="SINGLE">Individual</option>
                <option value="GROUP">Group</option>
              </select>
            </div>
            {/* if type == "group", enter pack size */}
            <div className="basis-1/2 mb-2">
              <label className="label">
                <span className="label-text font-semibold">
                  Pack Size (no. of people)
                </span>
              </label>
              <input
                type="number"
                className="input input-bordered bg-white w-full"
                placeholder="Enter pack size"
                defaultValue={1}
                min={trainingType === "GROUP" ? 2 : 1}
                disabled={trainingType === "SINGLE"}
                {...register("training.packSize", {
                  required: true,
                  min: trainingType === "GROUP" ? 2 : 1,
                  validate: (value) => {
                    if (trainingType === "GROUP" && value < 2) {
                      return "Pack size must be at least 2 for group training";
                    }
                    if (trainingType === "SINGLE" && value > 1) {
                      return "Pack size must be 1 for individual training";
                    }
                  },
                })}
              />
            </div>
          </div>
          {/* text purpose */}
          <div>
            <label className="label">
              <span className="label-text font-semibold">Purpose</span>
            </label>
            <textarea
              className="textarea h-20 textarea-bordered bg-white w-full"
              placeholder="Enter purpose and preferences for the training"
              {...register("training.purpose", {
                required: true,
              })}
            ></textarea>
          </div>
          <div className="flex gap-4">
            {/* text experience */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">Experience</span>
              </label>
              <textarea
                className="textarea h-20 textarea-bordered bg-white w-full"
                placeholder="Enter experience level of the student"
                {...register("training.experience", {
                  required: true,
                })}
              ></textarea>
            </div>
            {/* input availability */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">Availability</span>
              </label>
              <textarea
                type="text"
                className="textarea h-20 textarea-bordered bg-white w-full"
                placeholder="Explain your availability (in terms of days, time, duration, until which date, etc.)"
                {...register("training.availability", {
                  required: true,
                })}
              ></textarea>
            </div>
          </div>
          {/* payment info */}
          <div className="flex flex-col sm:flex-row gap-4">
            {/* amount */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">Amount ($)</span>
              </label>
              <input
                type="number"
                name="amount"
                placeholder="Amount"
                className="input input-bordered w-full bg-white"
                {...register("training.amount", {
                  required: true,
                  min: 0,
                  validate: (value) => {
                    return !!value;
                  },
                })}
                onChange={(e) => setAmount(parseInt(e.target.value * 100))}
                defaultValue={0}
                min={0}
                step={1}
              />
            </div>
            {/* method (select: MANUAL or STRIPE) */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Payment Method</span>
              </label>
              <select
                className="select select-bordered w-full bg-white"
                {...register("training.method", {
                  required: true,
                  validate: (value) => {
                    return !!value;
                  },
                })}
                defaultValue={""}
              >
                <option value="">Select a payment method</option>
                <option value="MANUAL">
                  Manual / Bank Transfer / EFTPOS / Other
                </option>
                <option value="STRIPE" disabled={amount <= 0}>
                  Online (customer will see this under pending and pay online)
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {/* ref (input) */}
            {showReference && (
              <div>
                <label className="label">
                  <span className="label-text font-semibold">
                    Payment Reference
                  </span>
                </label>
                <input
                  type="text"
                  name="ref"
                  placeholder="Reference Number"
                  className="input input-bordered w-full bg-white"
                  {...register("training.ref", {
                    required: true,
                  })}
                />
              </div>
            )}
          </div>
        </div>
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary", loading && "loading")}
          disabled={!isValid || loading}
        >
          Add Order {formatter.format(amount / 100)}
        </button>
      </form>
    </div>
  );
}

function TrainingsList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [name, setName] = useState("");
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [trainings, setTrainings] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(TRAININGS_LIST, {
    onCompleted: (data) => {
      setTrainings(data.list.trainings);
      setTotal(data.list.total);
      // based on the total and limit, calculate the max page
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      from: !!from ? from : null,
      to: !!to ? to : null,
      status: [
        "REQUESTED",
        "DRAFT",
        "APPROVED",
        "DECLINED",
        "REFUNDED",
        "COMPLETED",
      ],
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getTrainingsToDownload, { data: download, loading: downloading }] =
    useLazyQuery(TRAININGS_LIST, {
      onCompleted: (res) => {
        if (res.list.trainings.length > 0) {
          const formatted = res.list.trainings.map((training, i) => ({
            "No.": i + 1,
            Name: training.name,
            Location: training.location?.name ?? "-",
            Hall: training.hall?.name ?? "-",
            Type: training.type,
            Pack: training.pack,
            Purpose: training.purpose ?? "-",
            Experience: training.experience ?? "-",
            Availability: training.availability ?? "-",
            Sessions: training.sessions?.length ?? 0,
            Email: training.user?.email,
            Phone: training.user?.phone ?? "-",
            Method: training.payment.method ?? "UNKNOWN",
            ...(isAdmin && {
              Paid: formatter.format(training.total / 100),
              Discount: formatter.format(training.amounts?.discount / 100 ?? 0),
              Refunded: formatter.format(training.amounts?.refunded / 100 ?? 0),
              "Payment Ref.": training.payment?.reference ?? "-",
            }),
            Status: training.status,
            Created: convertUnixTimestamp(training.created),
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getTrainingsToDownload({
      variables: {
        name: !!debouncedName ? debouncedName : null,
        from: !!from ? from : null,
        to: !!to ? to : null,
        status: [
          "REQUESTED",
          "DRAFT",
          "APPROVED",
          "DECLINED",
          "REFUNDED",
          "COMPLETED",
        ],
        page: 0,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title="Training List">
      <section>
        <SectionHead heading="Training List">
          {/* Back to Orders */}
          <button
            className="btn btn-outline"
            onClick={() => navigate("/training/draft")}
          >
            Draft Training
          </button>
        </SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* table */}
          <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
            {/* table header */}
            <div className="flex w-full px-4 py-4 border-b-2">
              <div className="flex flex-row justify-between items-center w-full gap-4">
                {/* filters */}
                <div className="basis-3/4 flex flex-row gap-4">
                  {/* name search */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  {/* date from */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">
                        Date From
                      </span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setFrom(e.target.value);
                        refetch();
                      }}
                      value={from}
                      max={to}
                    />
                  </div>
                  {/* date to */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Date To</span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setTo(e.target.value);
                        refetch();
                      }}
                      value={to}
                      min={from}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2">
                  {(isAdmin || isManager) && (
                    <>
                      {/* download csv */}
                      {download &&
                      download?.list?.trainings.length === data.length ? (
                        <CSVLink
                          className="btn btn-ghost"
                          data={data}
                          filename={`trainings.csv`}
                          onClick={(e) => {
                            setData([]);
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      ) : (
                        <button
                          className={clsx(
                            "btn btn-ghost",
                            downloading && "loading"
                          )}
                          onClick={getDownloadData}
                        >
                          Generate CSV
                        </button>
                      )}
                    </>
                  )}
                  {/* reset button */}
                  <button
                    className="btn btn-ghost"
                    onClick={() => {
                      setName("");
                      setFrom(dateMonthAgo);
                      setTo(dateTomorrow);
                      setData([]);
                      refetch();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table content */}
            {!loading ? (
              <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
                {/* head */}
                <thead className="text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Method</th>
                    <th>Paid</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {/* body */}
                {trainings.length > 0 ? (
                  <tbody className="divide-y-2">
                    {trainings.map((value, index) => (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex flex-row gap-4 items-center">
                          <div>
                            <p className="font-bold">{value.name}</p>
                            <p>
                              <span className="font-medium">
                                {value.type}
                                {value.pack > 1 ? ` - ${value.pack}` : ""}
                              </span>
                            </p>
                            <p>
                              <span className="text-sm text-gray-600">
                                {convertUnixTimestamp(value.created)}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>{value.location?.name ?? "-"}</td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                              value.payment.method
                            )}`}
                          >
                            {formatLabel(value.payment.method ?? "UNKNOWN")}
                          </span>
                        </td>
                        <td className="tracking-wider font-mono">
                          {formatter.format(value.total / 100)}
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/training/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="flex flex-col items-center justify-center py-8">
                          <p className="text-gray-400 text-lg">
                            No trainings found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <>
                {/* loading skeleton */}
                <LoadingLayout type="list" />
              </>
            )}
            {/* table footer */}
            <div className="flex w-full px-4 py-4 border-t-2">
              <div className="flex flex-row justify-between items-center w-full">
                <div>
                  <p>
                    Showing{" "}
                    <span className="font-bold">{page * limit + 1}</span> -{" "}
                    <span className="font-bold">
                      {page * limit + trainings.length}
                    </span>{" "}
                    of <span className="font-bold">{total}</span> results
                  </p>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === 0 || maxPage <= 1}
                    onClick={() => prevPage()}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === maxPage - 1 || maxPage <= 1}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}

function TrainingDetail(props) {
  const { id, isAdmin } = props;

  const [canDecide, setCanDecide] = useState(false);

  const navigate = useNavigate();

  const { data, refetch } = useQuery(TRAINING_INFO, {
    variables: { trainingId: id },
    onCompleted: (information) => {
      if (information.training.status === "REQUESTED") {
        setCanDecide(true);
      } else {
        setCanDecide(false);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get details!");
    },
    fetchPolicy: "network-only",
  });

  const [rejectRequest, { loading: updating }] = useMutation(REJECT_ENTITY, {
    onCompleted: () => {
      toast.success("Request rejected successfully!");
      refetch();
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
  });

  const handleRejectRequest = () => {
    window.confirm("Are you sure you want to reject this request?") &&
      rejectRequest({
        variables: {
          type: "TRAINING",
          entityId: id,
        },
      });
  };

  return (
    <UserLayout title={`Training Details`}>
      <section>
        <SectionHead heading="Training Details">
          {/* reject request */}
          {canDecide && (
            <button
              className={clsx(
                "btn btn-outline btn-error",
                updating && "loading"
              )}
              onClick={handleRejectRequest}
            >
              Reject Request
            </button>
          )}
          {/* Back to Passes */}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </section>
      {/* overview section */}
      {data && data.training && <OverviewSection data={data.training} />}
      {/* schedule list */}
      {data &&
        data.training &&
        data.training.schedule &&
        data.training.status === "DRAFT" && (
          <ScheduleList schedule={data.training.schedule} />
        )}
      {/* sessions list */}
      {data &&
        data.training &&
        data.training.sessions &&
        data.training.status !== "REQUESTED" && (
          <SessionsList sessions={data.training.sessions} />
        )}
      {/* accept form */}
      {canDecide && <AcceptForm id={id} type="TRAINING" refetch={refetch} />}
      {/* transaction section */}
      {data && data.training && data.training.status !== "REQUESTED" && (
        <TransactionSection
          id={id}
          paymentRefundAllowed={data.training.status === "APPROVED" && isAdmin}
        />
      )}
    </UserLayout>
  );
}
