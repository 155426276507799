import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  ASSIGN_CLASS_TEACHERS,
  ASSIGN_WORKSHOP_TEACHERS,
  ASSIGN_SESSION_TEACHERS,
} from "../../utils/mutations";
import {
  LIST_TEACHERS,
  CLASS_INFO,
  WORKSHOP_INFO,
  SESSION_DETAILS,
  SESSIONS_LIST,
} from "../../utils/queries";
import toast from "react-hot-toast";

export const AllocateForm = (props) => {
  const { id, type } = props;

  const [teachers, setTeachers] = useState(null);
  const [assistants, setAssistants] = useState(null);
  const [selection, setSelection] = useState({
    teacher: null,
    assistant: null,
  });
  const [isComplete, setIsComplete] = useState(false);

  const [loader, setLoader] = useState(false);
  const { register, watch, handleSubmit, reset } = useForm();

  const notifySuccess = () => toast.success("Information updated!");
  const notifyError = () => toast.error("Something went wrong!");

  const { data, error } = useQuery(LIST_TEACHERS, {
    onError: () => {
      notifyError();
      console.error(error);
    },
  });

  const [assignClassTeacher] = useMutation(ASSIGN_CLASS_TEACHERS, {
    refetchQueries: [
      { query: CLASS_INFO, variables: { classId: id } },
      {
        query: SESSIONS_LIST,
        variables: { classId: id },
      },
    ],
    onCompleted() {
      setIsComplete(true);
      reset();
      notifySuccess();
      setLoader(false);
    },
    onError: (err) => {
      notifyError();
      setLoader(false);
      console.error(err);
    },
  });

  const [assignWorkshopTeacher] = useMutation(ASSIGN_WORKSHOP_TEACHERS, {
    refetchQueries: [
      { query: WORKSHOP_INFO, variables: { workshopId: id } },
      {
        query: SESSIONS_LIST,
        variables: { workshopId: id },
      },
    ],
    onCompleted() {
      setIsComplete(true);
      reset();
      notifySuccess();
      setLoader(false);
    },
    onError: (err) => {
      notifyError();
      setLoader(false);
      console.error(err);
    },
  });

  const [assignSessionTeacher] = useMutation(ASSIGN_SESSION_TEACHERS, {
    refetchQueries: [{ query: SESSION_DETAILS, variables: { sessionId: id } }],
    onCompleted() {
      setIsComplete(true);
      reset();
      notifySuccess();
      setLoader(false);
    },
    onError: (err) => {
      notifyError();
      setLoader(false);
      console.error(err);
    },
  });

  const selectedTeacher = watch("allocate.teacher");
  const selectedAssistant = watch("allocate.assistant");

  const onSubmit = (data) => {
    setLoader(true);

    switch (type) {
      case "class":
        assignClassTeacher({
          variables: {
            input: {
              classId: id,
              teacherId: selection.teacher,
              assistantId: selection.assistant,
            },
          },
        });
        clearValues();
        break;
      case "workshop":
        assignWorkshopTeacher({
          variables: {
            input: {
              workshopId: id,
              teacherId: selection.teacher,
              assistantId: selection.assistant,
            },
          },
        });
        clearValues();
        break;
      case "session":
        assignSessionTeacher({
          variables: {
            input: {
              sessionId: id,
              teacherId: selection.teacher,
              assistantId: selection.assistant,
            },
          },
        });
        clearValues();
        break;
      default:
        clearValues();
        return;
    }
  };

  const clearValues = () => {
    reset();
    setSelection({ teacher: null, assistant: null });
    setLoader(false);
  };

  useEffect(() => {
    if (data) {
      setTeachers(
        data.teachers
          ? data.teachers.filter((data) => data.status === "ACTIVE")
          : []
      );
      setAssistants(
        data.teachers
          ? data.teachers.filter((data) => data.status === "ACTIVE")
          : []
      );
    }
  }, [data]);

  useEffect(() => {
    if (selectedTeacher !== "Select") {
      setSelection((data) => ({ ...data, teacher: selectedTeacher }));
      if (data)
        setAssistants(
          data.teachers.filter(
            (doc) => doc.id !== selectedTeacher && doc.status === "ACTIVE"
          )
        );
    }
    if (selectedTeacher === "Select" || !selectedTeacher) {
      setSelection((data) => ({ ...data, teacher: null }));
      if (data)
        setAssistants(
          data.teachers
            ? data.teachers.filter((data) => data.status === "ACTIVE")
            : []
        );
    }
  }, [selectedTeacher, data]);

  useEffect(() => {
    if (selectedAssistant !== "Select")
      setSelection((data) => ({ ...data, assistant: selectedAssistant }));
    if (selectedAssistant === "Select")
      setSelection((data) => ({ ...data, assistant: null }));
  }, [selectedAssistant, data]);

  useEffect(() => {
    if (id) setIsComplete(false);
  }, [id]);

  useEffect(() => {
    setIsComplete(false);
  }, []);

  return (
    <>
      {!isComplete && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* for teacher */}
          {teachers && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">Teacher</span>
              </label>
              <select
                defaultValue="Select"
                className="select select-bordered w-full max-w-md bg-white"
                {...register("allocate.teacher")}
                required
              >
                <option disabled>Select</option>
                {teachers.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {/* for assistant */}
          {teachers && selection.teacher && (
            <div>
              <label className="label">
                <span className="label-text font-semibold">Assitant</span>
              </label>
              <select
                defaultValue="Select"
                className="select select-bordered w-full max-w-md bg-white"
                {...register("allocate.assistant")}
              >
                <option disabled>Select</option>
                {assistants.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {/* submit */}
          <div className="pt-4 space-x-2">
            {selection.teacher && (
              <button type="submit" className={loader ? "btn loading" : "btn"}>
                Allocate
              </button>
            )}
          </div>
        </form>
      )}
      {isComplete && (
        <>
          <p className="font-bold text-xl mb-6">Team assigned successfully!</p>
          <label
            htmlFor="allocate-modal"
            className="btn btn-outline"
            onClick={() => {
              clearValues();
              setIsComplete(false);
            }}
          >
            Close
          </label>
        </>
      )}
    </>
  );
};
