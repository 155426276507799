import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useDebounce } from "@uidotdev/usehooks";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import clsx from "clsx";
import { UserLayout } from "../layout/User";
import { LoadingLayout } from "../layout/Loading";
import { SectionHead } from "../library/section/Head";
import { OverviewSection } from "../library/section/Overview";
import { RostersList } from "../library/list/Rosters";
import { TransactionSection } from "../library/section/Transaction";
import { CustomerSelector } from "../library/selector/Customer";
import { StudentSelector } from "../library/selector/Student";
import { TermSelector } from "../library/selector/Term";
import { ClassSelector } from "../library/selector/Class";
import { VoucherSelector } from "../library/selector/Voucher";
import { ChangeEnrolmentModal } from "../library/modal/ChangeEnrolment";
import { ChangeEnrolmentForm } from "../library/form/ChangeEnrolment";
import {
  formatter,
  formatMobile,
  paymentTagColor,
  statusTagColor,
} from "../utils/helpers";
import { DRAFT_ENTITY, CANCEL_ENTITY } from "../utils/mutations";
import {
  APPLICABLE_SESSIONS,
  APPLICABLE_CLASSES,
  ENROLMENTS_LIST,
  ENROLMENT_INFO,
} from "../utils/queries";

export default function Enrolment(props) {
  const { param } = useParams();

  const { isAdmin, isManager } = useContext(AuthContext);

  if (param) {
    if (param === "draft") return <EnrolmentDraft />;
    return (
      <EnrolmentDetails id={param} isAdmin={isAdmin} isManager={isManager} />
    );
  } else {
    return <EnrolmentsList isAdmin={isAdmin} isManager={isManager} />;
  }
}

function EnrolmentDraft() {
  const [customer, setCustomer] = useState(null);
  const [student, setStudent] = useState(null);
  const [term, setTerm] = useState(null);
  const [classInfo, setClassInfo] = useState(null);

  const navigate = useNavigate();

  const getGroupIds = (groups) => groups.map((group) => group.id);

  useEffect(() => {
    if (!customer) {
      setStudent(null);
      setTerm(null);
      setClassInfo(null);
    }
  }, [customer]);

  return (
    <UserLayout title="Class Enrolment">
      <section>
        <SectionHead heading="Draft Enrolment">
          <button
            className="btn btn-outline"
            onClick={() => navigate("/enrolment")}
          >
            List Enrolments
          </button>
        </SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* customer */}
          <CustomerSelector selection={customer} setSelection={setCustomer} />
          {/* students */}
          {customer && (
            <StudentSelector
              customerId={customer?.id}
              section={student}
              setSelection={setStudent}
              type="enrolment"
            />
          )}
          {/* terms */}
          {student && (
            <TermSelector
              selection={term}
              setSelection={setTerm}
              type="enrolment"
            />
          )}
          {/* classes */}
          {student && term && (
            <ClassSelector
              groupsId={getGroupIds(student.groups)}
              student={student}
              term={term}
              setTerm={setTerm}
              selection={classInfo}
              setSelection={setClassInfo}
              type="enrolment"
            />
          )}
          {/* form */}
          {customer && student && term && classInfo && (
            <EnrolmentForm
              customer={customer}
              student={student}
              term={term}
              classInfo={classInfo}
              clear={() => {
                setCustomer(null);
              }}
            />
          )}
        </div>
      </section>
    </UserLayout>
  );
}

function EnrolmentForm(props) {
  const { customer, student, term, classInfo, clear } = props;

  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [totalSessions, setTotalSessions] = useState(0);
  const [voucher, setVoucher] = useState(null);
  const [charge, setCharge] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const { refetch } = useQuery(APPLICABLE_SESSIONS, {
    variables: {
      classId: classInfo.id,
      studentId: student.id,
      status: ["COMPLETE", "ONGOING", "UPCOMING"],
    },
    onCompleted: (data) => {
      setSessions(data.sessions);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        toast.success("Enrolment added successfully!");
        refetch();
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    refetchQueries: [
      {
        query: ENROLMENTS_LIST,
        variables: {
          status: ["AWAITING", "APPROVED", "CANCELLED", "REFUNDED"],
          page: 0,
          limit: 10,
        },
      },
      {
        query: APPLICABLE_CLASSES,
        variables: {
          studentId: student.id,
          termId: term.id,
        },
      },
    ],
  });

  const onSubmit = (data) => {
    const { discount, method, ref } = data.enrolment;

    const input = {
      type: "ENROLMENT",
      enrolment: {
        userId: customer.id,
        studentId: student.id,
        termId: term.id,
        classId: classInfo.id,
        from,
        method,
        ref,
        total: parseInt(charge),
        voucher: voucher ? parseInt(voucher.amount) : 0,
        discount: parseInt(discount * 100),
        vouchers: voucher ? [voucher.id] : [],
      },
    };

    draftEntity({ variables: { ...input } });
  };

  // watch input value for discount and voucher
  const session = watch("enrolment.session");
  const discount = watch("enrolment.discount");
  const useVoucher = watch("enrolment.useVoucher");
  const showReference = watch("enrolment.method") === "MANUAL";

  // reset values when data changes
  useEffect(() => {
    if (!!customer && !!student && !!term && !!classInfo && !!sessions) {
      setValue("enrolment.session", "");
      setTotalSessions(0);
      setValue("enrolment.discount", 0);
      setTotal(0);
      setVoucher(null);
    }
  }, [customer, student, term, classInfo, sessions, setValue]);

  // calculate total sessions to charge based on selected session
  useEffect(() => {
    if (!!session && !!sessions) {
      const getIndex = sessions.findIndex((s) => s.id === session);
      // manage from
      const selectedSession = sessions[getIndex];
      setFrom(selectedSession ? selectedSession?.date : null);
      const noOfSessions = getIndex >= 0 ? sessions.length - getIndex : 0;
      setTotalSessions(noOfSessions);
    } else setTotalSessions(0);
  }, [session, sessions]);

  // calculate total amount
  useEffect(() => {
    if (session && session !== "") {
      let amount = classInfo.price * totalSessions;
      // manage discount
      if (discount) amount -= discount * 100;
      setTotal(amount < 0 ? 0 : amount);
    }
  }, [session, totalSessions, discount, classInfo]);

  // can only use voucher if total is greater than 0
  useEffect(() => {
    if (total === 0 && useVoucher === true) {
      setValue("enrolment.useVoucher", false);
    }
  }, [total, useVoucher, setValue]);

  // calculate charge (based on total and voucher.amount)
  useEffect(() => {
    if (voucher && voucher.amount && voucher.amount > 0) {
      const amount = total - voucher.amount;
      setCharge(amount < 0 ? 0 : amount);
      if (amount <= 0) setValue("enrolment.method", "MANUAL");
    } else setCharge(total);
  }, [total, voucher, setValue]);

  return (
    <div className="relative w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-4"
      >
        {sessions && sessions.length > 0 && (
          <div>
            <label className="label">
              <span className="label-text font-semibold">Select Session</span>
            </label>
            <select
              className="select select-bordered bg-white w-full"
              {...register("enrolment.session", {
                required: true,
                validate: (value) => {
                  // if "" then not valid
                  if (!value) return false;
                  // if value is in sessions then valid
                  return !!sessions.find((session) => session.id === value);
                },
              })}
              defaultValue={""}
              disabled={!!useVoucher}
            >
              <option value="">Select from session</option>
              {sessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.name} (
                  {new Date(session.date).toLocaleDateString("en-AU")})
                </option>
              ))}
            </select>
          </div>
        )}
        {/* payment info */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row sm:flex-wrap">
            {/* discount */}
            <div className="basis-1/2 sm:pr-6">
              <label className="label">
                <span className="label-text font-semibold">
                  Discount Amount ($)
                </span>
              </label>
              <input
                type="number"
                name="discount"
                placeholder="Discount"
                className="input input-bordered w-full bg-white"
                {...register("enrolment.discount")}
                defaultValue={0}
                min={0}
                step={1}
                disabled={!!useVoucher}
              />
            </div>
            {/* method (select: MANUAL or STRIPE) */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">Payment Method</span>
              </label>
              <select
                className="select select-bordered w-full bg-white"
                {...register("enrolment.method", {
                  required: true,
                  validate: (value) => {
                    return !!value;
                  },
                })}
                defaultValue={""}
              >
                <option value="">Select a payment method</option>
                <option value="MANUAL">
                  Manual / Bank Transfer / EFTPOS / Other
                </option>
                <option value="STRIPE" disabled={total <= 0 || charge <= 0}>
                  Online (customer will see this under pending and pay online)
                </option>
              </select>
            </div>
            {/* ref (input) */}
            {showReference && (
              <div className="basis-full sm:pt-2">
                <label className="label">
                  <span className="label-text font-semibold">
                    Payment Reference
                  </span>
                </label>
                <input
                  type="text"
                  name="ref"
                  placeholder="Reference Number"
                  className="input input-bordered w-full bg-white"
                  {...register("enrolment.ref", {
                    required: true,
                  })}
                />
              </div>
            )}
          </div>
        </div>
        {/* voucher info */}
        {student?.age >= 4 && student?.age <= 18 && (
          <div>
            {/* checkbox for useVoucher */}
            <div className="flex gap-4">
              <label className="cursor-pointer label">
                <input
                  type="checkbox"
                  className="checkbox"
                  {...register("enrolment.useVoucher")}
                  disabled={total <= 0 || voucher}
                />
                <span className="label-text font-semibold pl-3">
                  Customer would like to redeem an NSW Active Kids Creative Kids
                  voucher
                </span>
              </label>
            </div>
            {useVoucher && (
              <VoucherSelector
                userId={customer.id}
                studentId={student.id}
                type={"Enrolment"}
                category={"AKCK"}
                total={total}
                voucher={voucher}
                setVoucher={setVoucher}
                location={classInfo.location}
              />
            )}
          </div>
        )}
        {/* submit */}
        {sessions && sessions.length > 0 && (
          <button
            type="submit"
            className={clsx("btn btn-primary", loading && "loading")}
            disabled={
              !isValid || !session || loading || (useVoucher && !voucher)
            }
          >
            Add Enrolment {formatter.format(charge / 100)}
          </button>
        )}
      </form>
    </div>
  );
}

function EnrolmentsList(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  // get tomorrow's date yyyy-mm-dd
  const dateTomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];
  // get 1 month ago yyyy-mm-dd
  const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .split("T")[0];

  const [name, setName] = useState("");
  const [from, setFrom] = useState(dateMonthAgo);
  const [to, setTo] = useState(dateTomorrow);
  const [enrolments, setEnrolments] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);

  const debouncedName = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(ENROLMENTS_LIST, {
    onCompleted: (data) => {
      setEnrolments(data.list.enrolments);
      setTotal(data.list.total);
      // based on the total and limit, calculate the max page
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get enrolments list!");
    },
    variables: {
      name: !!debouncedName ? debouncedName : null,
      from: !!from ? from : null,
      to: !!to ? to : null,
      status: ["DRAFT", "APPROVED", "COMPLETED", "REFUNDED"],
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getEnrolmentsToDownload, { data: download, loading: downloading }] =
    useLazyQuery(ENROLMENTS_LIST, {
      onCompleted: (res) => {
        if (res.list.enrolments.length > 0) {
          const formatted = res.list.enrolments.map((enrolment, i) => ({
            "No.": i + 1,
            Name: enrolment.name,
            Class: enrolment.class?.name ?? "-",
            Location: enrolment.class?.location?.name ?? "-",
            Program: enrolment.class?.program?.name ?? "-",
            Group: enrolment.class?.group?.name ?? "-",
            From: enrolment.from,
            Method: enrolment.method ?? "UNKNOWN",
            ...(isAdmin && {
              Paid: formatter.format(enrolment.total / 100),
              "Voucher Amt.": formatter.format(enrolment.voucher / 100),
              "Discount Amt.": formatter.format(enrolment.discount / 100),
              "Total Amt.": formatter.format(
                (enrolment.total + enrolment.voucher + enrolment.discount) / 100
              ),
              "Refund Amt.": formatter.format(enrolment.refunded / 100),
              "Payment Ref.": enrolment.ref,
            }),
            Status: enrolment.status,
            Student: enrolment.student?.name ?? "-",
            Email: enrolment.user.email ?? "-",
            Phone: enrolment.user.phone
              ? formatMobile(enrolment.user.phone)
              : "-",
            Term: enrolment.term.name,
            Sessions: enrolment.rosterCount,
            Attended: enrolment.rosterCountAttended,
            status: enrolment.status,
          }));
          setData(formatted);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Unable to get enrolments list for download!");
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getEnrolmentsToDownload({
      variables: {
        name: !!debouncedName ? debouncedName : null,
        from: !!from ? from : null,
        to: !!to ? to : null,
        status: ["AWAITING", "APPROVED", "COMPLETED", "CANCELLED", "REFUNDED"],
        page,
        limit: total,
      },
    });
  };

  const formatLabel = (str) => str.replace(/_/g, " ");

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <UserLayout title="Enrolments List">
      <section>
        <SectionHead heading="Enrolments List">
          {/* Back to Enrolments */}
          <button
            className="btn btn-outline"
            onClick={() => navigate("/enrolment/draft")}
          >
            Draft Enrolment
          </button>
        </SectionHead>
        <div className="flex flex-col gap-4 py-2">
          {/* table */}
          <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
            {/* table header */}
            <div className="flex w-full px-4 py-4 border-b-2">
              <div className="flex flex-row justify-between items-center w-full gap-4">
                {/* filters */}
                <div className="basis-3/4 flex flex-row gap-4">
                  {/* name search */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  {/* date from */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">
                        Date From
                      </span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setFrom(e.target.value);
                        refetch();
                      }}
                      value={from}
                      max={to}
                    />
                  </div>
                  {/* date to */}
                  <div className="w-1/3">
                    <label className="label sr-only">
                      <span className="label-text font-semibold">Date To</span>
                    </label>
                    <input
                      type="date"
                      className="input input-bordered w-full bg-white"
                      onChange={(e) => {
                        setTo(e.target.value);
                        refetch();
                      }}
                      value={to}
                      min={from}
                    />
                  </div>
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2">
                  {(isAdmin || isManager) && (
                    <>
                      {/* download csv */}
                      {download &&
                      download?.list?.enrolments.length === data.length ? (
                        <CSVLink
                          className="btn btn-ghost"
                          data={data}
                          filename={`enrolments.csv`}
                          onClick={(e) => {
                            setData([]);
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      ) : (
                        <button
                          className={clsx(
                            "btn btn-ghost",
                            downloading && "loading"
                          )}
                          onClick={getDownloadData}
                        >
                          Generate CSV
                        </button>
                      )}
                    </>
                  )}
                  {/* reset button */}
                  <button
                    className="btn btn-ghost"
                    onClick={() => {
                      setName("");
                      setFrom(dateMonthAgo);
                      setTo(dateTomorrow);
                      setData([]);
                      refetch();
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            {/* table content */}
            {!loading ? (
              <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
                {/* head */}
                <thead className="text-left">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>From</th>
                    <th>Method</th>
                    <th>Paid</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {/* body */}
                {enrolments.length > 0 ? (
                  <tbody className="divide-y-2">
                    {enrolments.map((value, index) => (
                      <tr key={value.id}>
                        <th>{page * limit + index + 1}</th>
                        <td className="flex flex-row gap-4 items-center">
                          <div>
                            <p className="font-bold">{value.name}</p>
                            <p>
                              <span className="font-medium">
                                {value.class?.name ?? "-"}
                              </span>
                            </p>
                            <p>
                              <span className="text-sm text-gray-600">
                                {value.term.name}
                              </span>
                              {value.voucher > 0 && (
                                <span className="text-xs text-gray-600">
                                  {" "}
                                  / voucher:{" "}
                                  {formatter.format(value.voucher / 100)}
                                </span>
                              )}
                              {value.discount > 0 && (
                                <span className="text-xs text-gray-600">
                                  {" "}
                                  / discount:{" "}
                                  {formatter.format(value.discount / 100)}
                                </span>
                              )}
                            </p>
                          </div>
                        </td>
                        <td>
                          <span className="font-medium capitalize tracking-wider">
                            {value.from ?? "-"}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${paymentTagColor(
                              value.method
                            )}`}
                          >
                            {formatLabel(value.method ?? "UNKNOWN")}
                          </span>
                        </td>
                        <td className="tracking-wider font-mono">
                          {formatter.format(value.total / 100)}
                        </td>
                        <td>
                          <span
                            className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                              value.status
                            )}`}
                          >
                            {value.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`btn btn-xs btn-ghost ${
                              value.status === "ACTIVE" ? "hidden" : ""
                            }`}
                            onClick={() => navigate(`/enrolment/${value.id}`)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="flex flex-col items-center justify-center py-8">
                          <p className="text-gray-400 text-lg">
                            No enrolments found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <>
                {/* loading skeleton */}
                <LoadingLayout type="list" />
              </>
            )}
            {/* table footer */}
            <div className="flex w-full px-4 py-4 border-t-2">
              <div className="flex flex-row justify-between items-center w-full">
                <div>
                  <p>
                    Showing{" "}
                    <span className="font-bold">{page * limit + 1}</span> -{" "}
                    <span className="font-bold">
                      {page * limit + enrolments.length}
                    </span>{" "}
                    of <span className="font-bold">{total}</span> results
                  </p>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === 0 || maxPage <= 1}
                    onClick={() => prevPage()}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-outline btn-sm"
                    disabled={page === maxPage - 1 || maxPage <= 1}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}

function EnrolmentDetails(props) {
  const { id, isAdmin } = props;

  const navigate = useNavigate();

  const { data, refetch } = useQuery(ENROLMENT_INFO, {
    onError: (error) => {
      toast.error(error.message);
      navigate(-1);
    },
    variables: {
      enrolmentId: id,
    },
    fetchPolicy: "network-only",
  });

  const [cancelEntity, { loading: cancelling }] = useMutation(CANCEL_ENTITY, {
    onError: (error) => {
      console.error(error.message);
      toast.error("Unable to cancel the draft.");
    },
    onCompleted: () => {
      toast.success("Cancelled successfully.");
      navigate(-1);
    },
  });

  const handleCancel = () => {
    window.confirm("Are you sure you want to cancel this enrolment?") &&
      cancelEntity({
        variables: {
          type: "ENROLMENT",
          entityId: id,
        },
      });
  };

  return (
    <UserLayout title={`Enrolment Details`}>
      <section>
        <SectionHead heading="Enrolment Details">
          {/* cancel - if draft */}
          {data && data.enrolment && data.enrolment.status === "DRAFT" && (
            <button
              className={clsx(
                "btn btn-outline btn-error",
                cancelling && "loading"
              )}
              onClick={handleCancel}
            >
              Cancel
            </button>
          )}
          {/* change - if used and type is DROPIN */}
          {data && data.enrolment && data.enrolment.status === "APPROVED" && (
            <ChangeEnrolmentModal
              label="Change Enrolment Class"
              title="Change Enrolment"
              content="Update the enrolment class to a different class."
            >
              <ChangeEnrolmentForm id={id} refetch={refetch} />
            </ChangeEnrolmentModal>
          )}
          {/* Back to Passes */}
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
      </section>
      {/* overview section */}
      {data && data.enrolment && <OverviewSection data={data.enrolment} />}
      {/* rosters section */}
      {data && data.enrolment && data.enrolment.rosters && (
        <RostersList enrolmentId={id} />
      )}
      {/* transaction section */}
      {data && data.enrolment && data.enrolment.status !== "DRAFT" && (
        <TransactionSection
          id={id}
          paymentRefundAllowed={data.enrolment.status === "APPROVED" && isAdmin}
        />
      )}
    </UserLayout>
  );
}
